import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { PublishTypes } from 'app/shared/enums';
import { IGatewayResponse, IGateway } from 'app/shared/interfaces';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class GatewaysService {

  readonly baseUrl = environment.api_host;
  readonly url = `${this.baseUrl}/apis/forms/v1/gateways`;

  constructor(
    private http: HttpClient,
  ) { }

  getGateways(): Observable<IGatewayResponse> {
    return this.http.get<IGatewayResponse>(`${this.url}`);
  }

  publishGateways(type: PublishTypes, id?: string): Observable<string> {
    const payload = { sync_type: type };
    if (id) Object.assign(payload, { gateway_id: id });
    return this.http.post<{ message: string }>(`${this.url}/publish_gateways`, payload).pipe(
      map(response => response.message),
    )
  }

  createGateway(data: IGateway): Observable<IGateway> {
    delete data._id;
    return this.http.post<IGateway>(`${this.url}`, { gateway: data });
  }

  updateGateway(data: IGateway): Observable<IGateway> {
    delete data._id;
    return this.http.patch<IGateway>(`${this.url}/${data.gateway_code}`, { gateway: data });
  }

  findName(data: string): Observable<string> {
    return this.http.get<{ organization_name: string }>(`${this.baseUrl}/apis/forms/v1/gateways/find_by_org_id/${data}`).pipe(
      map(response => response?.organization_name || ''),
    );
  }

}