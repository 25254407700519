<span>ACH or Wire Transfer:</span>
<hr>
<span>Account Name: Allium US Holding LLC</span><br/>
<span>Tax ID#: 92-2207859</span><br/>
<span>Bank Name: JPMorgan Chase</span><br/>
<span>Bank Address: JPMorgan Chase New York, NY 10017</span><br/>
<span>Bank Phone: 866-954-3718</span><br/>
<span class="underline">ACH:</span><br/>
<span>Account #: 581606103</span><br/>
<span>Routing #: 124001545</span><br/>
<span class="underline">Wire Transfers:</span><br/>
<span>Account #: 581606103</span><br/>
<span>Routing #: 021000021</span><br/>
<span>SWIFT Code: CHASUS33</span>
