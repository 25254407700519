/* src/app/accounts/validated-address/validated-address.component.scss */
.multiadd_card_width {
  width: 800px;
}
.radio_button {
  margin-top: -25%;
  margin-right: 3%;
}
.align_center {
  text-align: center;
}
.address_container {
  width: 100%;
  display: flow-root;
}
.left_container {
  float: left;
  width: 48%;
}
.left_container p {
  padding-left: 5%;
}
.right_container {
  float: right;
  width: 48%;
}
.right_container p {
  padding-left: 5%;
}
/*# sourceMappingURL=validated-address.component.css.map */
