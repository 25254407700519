import { ICoupon, ISearchResults } from 'app/shared/interfaces';
import { Action, State, StateContext } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { AlertService, CouponsService, ErrorHandlerService } from '../services';
import * as actions from './action-namespaces/coupons';
import { catchError, tap } from 'rxjs/operators';
import { HTTP_MESSAGES } from 'app/shared/constants';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { append, patch, removeItem, updateItem } from '@ngxs/store/operators';
import { SearchType } from 'app/shared/enums';

export const SEARCH_PLACEHOLDER: ISearchResults = {
  [SearchType.Gateway]: null,
  [SearchType.Manufacturer]: null,
  [SearchType.Person]: null,
  [SearchType.Product]: null,
  [SearchType.ProductDetail]: null,
  [SearchType.Vendor]: null,
}

export interface CouponsState {
  loading: boolean;
  coupons: ICoupon[];
  search: ISearchResults,
}

@State<CouponsState>({
  name: 'coupons',
  defaults: {
    loading: false,
    coupons: [],
    search: SEARCH_PLACEHOLDER,
  }
})
@Injectable()
export class CouponsStore {
  constructor(
    private readonly couponsService: CouponsService,
    private readonly errorHandlerService: ErrorHandlerService,
    private readonly alertService: AlertService,
    private readonly dialog: MatDialog,
  ) { }

  @Action(actions.Coupons.GetAll)
  getCoupons(ctx: StateContext<CouponsState>) {
    ctx.patchState({ coupons: [], loading: true });
    return this.couponsService.getCoupons().pipe(
      tap(coupons => ctx.patchState({ coupons, loading: false })),
      catchError(e => this.errorHandlerService.handleError(HTTP_MESSAGES.generalError, e, ctx)),
    );
  };

  @Action(actions.Coupons.Find)
  findCoupons(ctx: StateContext<CouponsState>, action: actions.Coupons.Find) {
    ctx.patchState({ coupons: [], loading: true });
    return this.couponsService.findCoupons(action.payload).pipe(
      tap(coupons => ctx.patchState({ coupons, loading: false })),
      catchError(e => this.errorHandlerService.handleError(HTTP_MESSAGES.generalError, e, ctx)),
    );
  };

  @Action(actions.Coupons.Delete)
  deleteCoupon(ctx: StateContext<CouponsState>, action: actions.Coupons.Delete) {
    return this.couponsService.deleteCoupon(action.payload).pipe(
      tap(id => {
        this.dialog.closeAll();
        this.alertService.showAlert({ message: HTTP_MESSAGES.deleteSuccess, type: 'SUCCESS' });
        return ctx.setState(patch({ coupons: removeItem<ICoupon>(c => c.id === id) }));
      }),
      catchError(e => this.errorHandlerService.handleError(HTTP_MESSAGES.deleteFail, e, ctx)),
    );
  };

  @Action(actions.GeneralPurpose.FindName)
  findName(ctx: StateContext<CouponsState>, action: actions.GeneralPurpose.FindName) {
    const { type, data } = action.payload;
    const currentResults = ctx.getState().search;
    return this.couponsService.findName(type, data).pipe(
      tap(result => ctx.patchState({ search: { ...currentResults, [type]: result } })),
      catchError(e => {
        ctx.patchState({ search: { ...currentResults, [type]: null } });
        return this.errorHandlerService.handleError(HTTP_MESSAGES.generalError, e, ctx);
      }),
    );
  };

  @Action(actions.GeneralPurpose.ResetSearch)
  resetSearch(ctx: StateContext<CouponsState>) {
    ctx.patchState({ search: SEARCH_PLACEHOLDER });
  }

  @Action(actions.Coupons.Create)
  createCoupon(ctx: StateContext<CouponsState>, action: actions.Coupons.Create) {
    return this.couponsService.createCoupon(action.payload).pipe(
      tap(coupon => {
        this.dialog.closeAll();
        this.alertService.showAlert({ message: HTTP_MESSAGES.createSuccess, type: 'SUCCESS' });
        return ctx.setState(patch({ coupons: append<ICoupon>([coupon]) }));
      }),
      catchError(e => this.errorHandlerService.handleError(HTTP_MESSAGES.createFail, e, ctx)),
    );
  };

  @Action(actions.Coupons.Update)
  updateCoupon(ctx: StateContext<CouponsState>, action: actions.Coupons.Update) {
    return this.couponsService.updateCoupon(action.payload).pipe(
      tap(coupon => {
        this.dialog.closeAll();
        this.alertService.showAlert({ message: HTTP_MESSAGES.updateSuccess, type: 'SUCCESS' });
        return ctx.setState(patch({ coupons: updateItem<ICoupon>(c => c.id === coupon.id, coupon) }));
      }),
      catchError(e => this.errorHandlerService.handleError(HTTP_MESSAGES.updateFail, e, ctx)),
    );
  };

}
