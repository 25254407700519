import { Mappable } from './mappable';
import { LineItem } from './line_item';
import { Order } from './order';

export class Invoice extends Mappable {
  status: string;
  id: number = 0;
  date: Date;
  due_date: Date;
  ship_date: Date;
  tax: number;
  ship_charge: number;
  total: number;
  tracking_number: string;
  line_items: LineItem[];
  coupon_amount: number = 0;
  order: Order;
  balance_amount: number;
  tax_breakdown: any[];
  subtotal: number;
  tax_xml: string;

  constructor() { super() };

  wire_transfer_item() {
    const wtf = this.line_items.find( (li) => li.item === 'MISC WIRE TRANSFER');
    return wtf ? wtf : null;
  }

  shipping_item() {
    const wtf = this.line_items.find( (li) => li.item === 'MISC SPECIAL SHIPPING');
    return wtf ? wtf : null;
  }

  get shipped_line_items(): LineItem[] {
    var items = this.line_items
    if (this.wire_transfer_item() !== null) {
      items = items.filter((li) => li.id !== this.wire_transfer_item().id);
    }
    if (this.shipping_item() !== null) {
      items = items.filter((li) => li.id !== this.shipping_item().id);
    }
    return items
  }

  isAUD(){
    return this.order.currency === 'AUD';
  }

  isUSD(){
    return this.order.currency === 'USD';
  }

}
