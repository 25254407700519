<form class="plain" [formGroup]="resetAssigneeForm" (ngSubmit)="save()" novalidate>
  <h4>Reset Assignee</h4>
  <mat-form-field>
    <input size="60" matInput
           required
           formControlName="assignee_email"
           [maxLength]="100"
           placeholder="Enter New User's Email"
           [(ngModel)]="assignee_email" />
    <mat-error *ngIf="resetAssigneeForm.controls.assignee_email.hasError('required')">
      {{ null | required_errormsg }}
    </mat-error>
    <mat-error *ngIf="!resetAssigneeForm.controls.assignee_email.hasError('required') && resetAssigneeForm.controls.assignee_email.hasError('email')">
      {{ null | email_errormsg }}
    </mat-error>
  </mat-form-field>
  <div>
    <button type="submit" mat-raised-button color="primary" [disabled]="!resetAssigneeForm.valid" >Assign</button>
  </div>
</form>
