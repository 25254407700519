/* src/app/shared/components/no-data/no-data.component.scss */
.nothing-found {
  width: 100%;
  height: 100%;
  min-height: 12rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.nothing-found .search-glass {
  height: unset;
  width: unset;
  font-size: 42px;
  color: #673ab7;
}
/*# sourceMappingURL=no-data.component.css.map */
