import { IContact } from "../interfaces";

export class MContact {

  data: IContact = null;

  constructor(data: IContact) {
    this.data = data;
  }

  get fullName(): string {
    return `${this.data.first_name ? this.data.first_name + ' ' : ''}${this.data.last_name ? this.data.last_name : ''}`;
  }

  get fullAddress(): string {
    return `
      ${this.data.street_address ? this.data.street_address + ' ' : ''}
      ${this.data.city ? this.data.city + ' ' : ''}
      ${this.data.state_province ? this.data.state_province + ' ' : ''}
      ${this.data.zip_code ? this.data.zip_code + ' ' : ''}
      ${this.data.country_code ? this.data.country_code : ''}
    `;
  }

}