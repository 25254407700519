import { Mappable } from './mappable';

export class EmailAddress extends Mappable {
  id: number = 0;
  email: string;
  person_id: number;
  billing_order_id: number;
  shipping_order_id: number;
  reward_order_id: number;
  type: string;
  is_default: boolean = false;

  toString(): string {
    return ['email']
      .reduce((sum, n) => sum + `${this[n]}`, '');
  }

  equals(a: EmailAddress): boolean {
    return !!a && (this.toString() === a.toString());
  }

  constructor() { super() }
}
