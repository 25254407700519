import { PublishTypes } from 'app/shared/enums';
import { IGateway, IResetFieldPayload } from 'app/shared/interfaces';
import { GatewaysState } from 'app/store';

export namespace Gateway {
  export class GetAll {
    static readonly type = '[Gateway] Get Gateways';
  }
  
  export class Update {
    static readonly type = '[Gateway] Update Gateway';
    constructor(public payload: IGateway) { }
  }

  export class Create {
    static readonly type = '[Gateway] Create Gateway';
    constructor(public payload: IGateway) { }
  }

  export class Publish {
    static readonly type = '[Gateway] Publish Gateways';
    constructor(public payload: { type: PublishTypes; id?: string, refetchGateways?: boolean }) { }
  }
}

export namespace GeneralPurpose {
  export class ResetGatewaysField {
    static readonly type = '[Gateway] Reset Field';
    constructor(public payload: IResetFieldPayload<GatewaysState>) { }
  }

  export class FindName {
    static readonly type = '[Gateway] Find Name';
    constructor(public payload: string) { }
  }
}
