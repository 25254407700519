import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';
import { append, patch } from '@ngxs/store/operators';
import { HTTP_MESSAGES } from 'app/shared/constants';
import { INote } from 'app/shared/interfaces';
import { catchError, tap } from 'rxjs/operators';
import { AlertService, ErrorHandlerService, NotesService } from '../services';
import * as actions from './action-namespaces/notes';

export interface NotesState {
  loading: boolean;
  notes: INote[];
}

@State<NotesState>({
  name: 'notes',
  defaults: {
    loading: false,
    notes: [],
  }
})
@Injectable()
export class NotesStore {
  constructor(
    private readonly notesService: NotesService,
    private readonly errorHandlerService: ErrorHandlerService,
    private readonly alertService: AlertService,
  ) { }

  @Action(actions.Notes.GetAll)
  getNotes(ctx: StateContext<NotesState>, action: actions.Notes.GetAll) {
    ctx.patchState({ notes: [], loading: true });
    return this.notesService.getNotes(action.payload.noteType, action.payload.id).pipe(
      tap(notes => ctx.patchState({ notes, loading: false })),
      catchError(e => this.errorHandlerService.handleError(HTTP_MESSAGES.generalError, e, ctx)),
    );
  }

  @Action(actions.Notes.Create)
  createNote(ctx: StateContext<NotesState>, action: actions.Notes.Create) {
    ctx.patchState({ loading: true });
    return this.notesService.createNote(action.payload).pipe(
      tap(note => {
        ctx.setState(patch({ notes: append([note]) }));
        this.alertService.showAlert({
          message: HTTP_MESSAGES.createSuccess,
          type: 'SUCCESS',
        });
      }),
      catchError(e => this.errorHandlerService.handleError(HTTP_MESSAGES.createFail, e, ctx)),
    );
  }

}
