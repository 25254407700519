import { IOrdersOnHoldRequest, IUpdateOrderHoldStatusPayload } from 'app/shared/interfaces';

export namespace OrdersOnHold {
  export class GetAll {
    static readonly type = '[OrdersOnHold] GetOrdersOnHold';
    constructor(public payload: IOrdersOnHoldRequest) {}
  }
  export class GetOne {
    static readonly type = '[OrdersOnHold] GetOrderOnHold';
    constructor(public payload: string) {}
  }
  export class Reset {
    static readonly type = '[OrdersOnHold] ResetOrdersOnHold';
  }
}

export namespace OrderHold {
  export class UpdateStatus {
    static readonly type = '[OrdersOnHold] UpdateStatus';
    constructor(public payload: IUpdateOrderHoldStatusPayload) {}
  }
}
