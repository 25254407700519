import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { PublishTypes } from 'app/shared/enums';
import {
  IContact,
  IMultiUserException,
  IMultiUserPricing,
  IPriceCode,
  IProductSearchItem,
  IRestriction,
  ISubVendor,
  IZipCodeDetails,
  IVendor,
  VendorCreatePayload,
  VendorResponse,
} from 'app/shared/interfaces';
import { MContact } from 'app/shared/models';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class VendorsService {

  readonly baseUrl = environment.api_host;
  readonly url = `${this.baseUrl}/apis/forms/v1/vendors`;

  constructor(
    private http: HttpClient,
  ) { }

  getVendors(): Observable<VendorResponse> {
    return this.http.get<VendorResponse>(this.url).pipe(
      map(response => {
        if (response.organizations?.length) {
          return {
            ...response,
            organizations: response.organizations.map(v => ({ ...v, vendor_id: v.vendor_id.toString() }))
          };
        }
        return { ...response };
      }),
    );
  }

  publishVendors(type: PublishTypes, id?: string): Observable<string> {
    const payload = { sync_type: type };
    if (id) Object.assign(payload, { vendor_id: id });
    return this.http.post<{ message: string }>(`${this.url}/publish_vendors`, payload).pipe(
      map(response => response.message),
    )
  }

  createVendor(payload: VendorCreatePayload): Observable<string> {
    return this.http.post<{ vendor_id: string }>(this.url, payload).pipe(
      map(response => response.vendor_id),
    );
  }

  getVendor(id: string): Observable<IVendor> {
    return this.http.get<IVendor>(`${this.url}/${id}/edit`)
  }

  updateVendor(data: IVendor): Observable<IVendor> {
    const formData: FormData = new FormData();
    if ((data.logo as File)?.name) {
      formData.append('file', data.logo as Blob, (data.logo as File).name);
    } else {
      formData.append('file', null);
    }
    formData.append('params', JSON.stringify(data))
    return this.http.patch<IVendor>(`${this.url}/${data.vendor_id}`, formData);
  }

  displayRestrict(vendorId: string): Observable<{ product_ids: string[] }> {
    return this.http.post<{ product_ids: string[] }>(`${this.url}/do_not_show/${vendorId}`, {});
  }

  getContacts(vendorId: string): Observable<MContact[]> {
    return this.http.get<IContact[]>(`${this.url}/${vendorId}/contacts`).pipe(
      map(contacts => contacts.map(c => (new MContact({ ...c, contact_id: c.contact_id.toString() })))),
    )
  };

  createContact(vendorId: string, data: IContact): Observable<IContact> {
    return this.http.post<IContact>(`${this.url}/${vendorId}/contacts`, { contact: data });
  }

  updateContact(vendorId: string, data: IContact): Observable<IContact> {
    return this.http.patch<IContact>(`${this.url}/${vendorId}/contacts/${data.contact_id}`, { contact: data });
  }

  getDataFromZipCode(zipCode: string): Observable<IZipCodeDetails> {
    return this.http.get<IZipCodeDetails>(`${this.baseUrl}/apis/country_code/v1/zip_codes/${zipCode}`);
  }

  getSubVendors(vendorId: string): Observable<ISubVendor[]> {
    return this.http.get<ISubVendor[]>(`${this.url}/${vendorId}/subvendors`).pipe(
      map(subVendors => {
        if (subVendors?.length) {
          return subVendors.map(v => ({ ...v, id: v.id.toString() }));
        }
        return [];
      }),
    );
  }

  updateSubVendor(vendorId: string, data: ISubVendor): Observable<ISubVendor> {
    return this.http.patch<ISubVendor>(`${this.url}/${vendorId}/subvendors/${data.id}`, { manufacturer: data });
  }

  createSubVendor(vendorId: string, data: ISubVendor): Observable<ISubVendor> {
    return this.http.post<ISubVendor>(`${this.url}/${vendorId}/subvendors`, { manufacturer: data });
  }

  deleteSubVendor(vendorId: string, id: string): Observable<string> {
    return this.http.delete<string>(`${this.url}/${vendorId}/subvendors/${id}`);
  }

  addExistingSubVendor(vendorId: string, data: string): Observable<ISubVendor> {
    return this.http.post<ISubVendor>(`${this.url}/${vendorId}/subvendors/add_existing_subvendor/${data}`, { });
  }

  getRestrictions(vendorId: string): Observable<IRestriction[]> {
    return this.http.get<IRestriction[]>(`${this.url}/${vendorId}/international_restrictions`).pipe(
      map(restrictions => restrictions.map(r => ({ ...r, id: r.id.toString() }))),
    );
  }

  createRestriction(vendorId: string, data: IRestriction): Observable<IRestriction> {
    return this.http.post<IRestriction>(`${this.url}/${vendorId}/international_restrictions`, { restriction: data });
  }

  updateRestriction(vendorId: string, data: IRestriction): Observable<IRestriction> {
    return this.http.patch<IRestriction>(`${this.url}/${vendorId}/international_restrictions/${data.id}`, { restriction: data });
  }

  deleteRestriction(vendorId: string, id: string): Observable<IRestriction> {
    return this.http.delete<IRestriction>(`${this.url}/${vendorId}/international_restrictions/${id}`);
  }

  getPriceCodes(vendorId: string): Observable<IPriceCode[]> {
    return this.http.get<IPriceCode[]>(`${this.url}/${vendorId}/price_codes`).pipe(
      map(codes => codes.map(c => ({ ...c, id: c.id.toString() }))),
    );
  }

  createPriceCode(vendorId: string, data: IPriceCode): Observable<IPriceCode> {
    return this.http.post<IPriceCode>(`${this.url}/${vendorId}/price_codes`, { price_code: data });
  }

  updatePriceCode(vendorId: string, data: IPriceCode): Observable<IPriceCode> {
    return this.http.patch<IPriceCode>(`${this.url}/${vendorId}/price_codes/${data.id}`, { price_code: data });
  }

  deletePriceCode(vendorId: string, id: string): Observable<IPriceCode> {
    return this.http.delete<IPriceCode>(`${this.url}/${vendorId}/price_codes/${id}`);
  }

  getMultiUserPricings(vendorId: string): Observable<IMultiUserPricing[]> {
    return this.http.get<IMultiUserPricing[]>(`${this.url}/${vendorId}/multi_user_pricings`);
  }

  createMultiUserPricing(vendorId: string, data: IMultiUserPricing): Observable<IMultiUserPricing> {
    return this.http.post<IMultiUserPricing>(`${this.url}/${vendorId}/multi_user_pricings`, { multi_user_pricing: data });
  }

  updateMultiUserPricing(vendorId: string, data: IMultiUserPricing): Observable<IMultiUserPricing> {
    return this.http.patch<IMultiUserPricing>(`${this.url}/${vendorId}/multi_user_pricings/${data.id}`, { multi_user_pricing: data });
  }

  deleteMultiUserPricing(vendorId: string, id: string): Observable<IMultiUserPricing> {
    return this.http.delete<IMultiUserPricing>(`${this.url}/${vendorId}/multi_user_pricings/${id}`);
  }

  getMultiUserExceptions(vendorId: string): Observable<IMultiUserException[]> {
    return this.http.get<IMultiUserException[]>(`${this.url}/${vendorId}/multi_user_exceptions`);
  }

  createMultiUserException(vendorId: string, data: IMultiUserException): Observable<IMultiUserException> {
    return this.http.post<IMultiUserException>(`${this.url}/${vendorId}/multi_user_exceptions`, { multi_user_exception: data });
  }

  updateMultiUserException(vendorId: string, data: IMultiUserException): Observable<IMultiUserException> {
    return this.http.patch<IMultiUserException>(`${this.url}/${vendorId}/multi_user_exceptions/${data.id}`, { multi_user_exception: data });
  }

  deleteMultiUserException(vendorId: string, id: string): Observable<IMultiUserException> {
    return this.http.delete<IMultiUserException>(`${this.url}/${vendorId}/multi_user_exceptions/${id}`);
  }

  findProduct(vendorId: string, id: string): Observable<IProductSearchItem> {
    return this.http.get<IProductSearchItem>(`${this.url}/${vendorId}/multi_user_exceptions/find_by_product_id/${id}`)
  }

}
