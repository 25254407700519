import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ISubGroup } from 'app/shared/interfaces';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class SubGroupsService {

  readonly baseUrl = environment.api_host;
  readonly url = `${this.baseUrl}/apis/forms/v1/subgroups`;

  constructor(private http: HttpClient) {}

  getSubGroups(): Observable<ISubGroup[]> {
    return this.http.get<ISubGroup[]>(this.url);
  }

  createSubGroup(subgroup: ISubGroup): Observable<ISubGroup> {
    return this.http.post<ISubGroup>(`${this.url}`, { subgroup });
  }

  updateSubGroup(subgroup: ISubGroup): Observable<ISubGroup> {
    return this.http.patch<ISubGroup>(`${this.url}/${subgroup.subgroup_id}`, { subgroup });
  }
}
