/* src/app/shared/components/title/title.component.scss */
.section-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
}
.section-title h2 {
  margin-left: 12px;
}
.section-title .actions button + button {
  margin-left: 50px;
}
/*# sourceMappingURL=title.component.css.map */
