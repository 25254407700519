import { Component } from '@angular/core';
import { Observable } from 'rxjs';
import { IChangesHistory } from 'app/shared/interfaces';
import { ChangesHistoryFacade } from 'app/store/facades/changes-history.facade';

@Component({
  selector: 'app-changes-history',
  template: `
    <app-changes-history-dumb
    [history]="history$ | async"
    [loading]="loading$ | async"
    ></app-changes-history-dumb>`,
})
export class ChangesHistoryContainer {

  history$: Observable<IChangesHistory[]>;
  loading$: Observable<boolean>;

  constructor(
    private readonly changesHistoryFacade: ChangesHistoryFacade,
  ) {
    this.history$ = changesHistoryFacade.history$;
    this.loading$ = changesHistoryFacade.loading$;
  }

}
