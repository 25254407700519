import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { Country } from '../_models/country';
import { UsState } from '../_models/us_state';
import { CanadianProvince } from '../_models/canadian_province';
import { DbEnumeration } from '../_models/db_enumeration';
import { IFormatType, IRelationType } from 'app/shared/interfaces';

@Injectable()
export class AppDataService {
  private baseUrl = `${environment.api_host}/apis/forms/v1/application_data`;
  private _countryList: Country[];
  private _countryListGdpr: Country[];
  private _stateList: UsState[];
  private _provinceList: CanadianProvince[];
  private _paymentMethodList: DbEnumeration[];
  private _miscTransactionList: DbEnumeration[];
  private _paymentTermList: DbEnumeration[];
  private _shipMethodList: DbEnumeration[];
  private _orderStatusList: DbEnumeration[];
  private _returnStatusList: DbEnumeration[];
  private _formatTypes: IFormatType[];
  private _fulfillmentMethods: DbEnumeration[];
  private _gateways: DbEnumeration[];
  private _order_origins_types: DbEnumeration[];
  private _transactionTypes: DbEnumeration[];
  private _returnCodes: DbEnumeration[];
  private _couponStatusList: DbEnumeration[];
  private _validCouponCodes: DbEnumeration[];
  private _currencyexchange: DbEnumeration[];
  private _shippingProductId;
  private _wireTransferProductId;
  private _wireTransferProductDetailId;
  private _shippingProductDetailId;
  private _checkInfo;
  private _wireTransferInfo;
  private _federalTaxId;
  private _vats;
  private _otherTaxIds;
  private _productTypes: DbEnumeration[];
  private _product_note_types: DbEnumeration[];
  private _product_relation_types: IRelationType[];
  private _languages: DbEnumeration[];
  private _copyright_noticies: DbEnumeration[];
  private _vendor_order_status: DbEnumeration[];

  constructor(private _client: HttpClient) { }

  load(): Promise<any> {
    this._countryList = [];
    this._countryListGdpr = [];
    return this._client
      .get(this.baseUrl)
      .pipe(map((res: Response) => {
        this._countryListGdpr = res['countries_gdpr'].map( value => new Country().fromJSON(value));
        this._countryList = res['countries'].map( value => new Country().fromJSON(value));
        this._stateList = res['states'].map( value => new UsState().fromJSON(value));
        this._provinceList = res['provinces'].map( value => new CanadianProvince().fromJSON(value));
        this._paymentMethodList = res['payment_methods'].map ( value => new DbEnumeration().fromJSON(value));
        this._miscTransactionList = res['transaction_types'].map ( value => new DbEnumeration().fromJSON(value));
        this._paymentTermList = res['payment_terms'].map ( value => new DbEnumeration().fromJSON(value));
        this._shipMethodList = res['ship_methods'].map ( value => new DbEnumeration().fromJSON(value));
        this._orderStatusList = res['order_status'].map ( value => new DbEnumeration().fromJSON(value));
        this._returnStatusList = res['return_status'].map ( value => new DbEnumeration().fromJSON(value));
        this._formatTypes = res['format_types'].map (value => new DbEnumeration().fromJSON(value));
        this._fulfillmentMethods = res['fulfillment_methods'].map (value => new DbEnumeration().fromJSON(value));
        this._gateways = res['gateways'].map ( value => new DbEnumeration().fromJSON(value));
        this._order_origins_types = res['order_origin_types'].map ( value => new DbEnumeration().fromJSON(value));
        this._transactionTypes = res['transaction_types'].map ( value => new DbEnumeration().fromJSON(value));
        this._returnCodes = res['return_codes'].map ( value => new DbEnumeration().fromJSON(value));
        this._couponStatusList = res['coupon_status'].map ( value => new DbEnumeration().fromJSON(value));
        this._validCouponCodes = res['valid_coupon_codes'].map ( value => new DbEnumeration().fromJSON(value));
        this._currencyexchange = res['currencies'].map ( value => new DbEnumeration().fromJSON(value));
        this._shippingProductId = +res['shipping_product_id'];
        this._wireTransferProductId = +res['wire_transfer_product_id'];
        this._wireTransferProductDetailId = +res['wire_transfer_product_detail_id'];
        this._shippingProductDetailId = +res['shipping_product_detail_id'];
        this._checkInfo = res['check_info'];
        this._wireTransferInfo = res['wire_transfer_info'];
        this._federalTaxId = res['federal_tax_id'];
        this._vats = res['vats'];
        this._otherTaxIds = res['other_tax_ids'];
        this._productTypes = res['product_types'].map ( value => new DbEnumeration().fromJSON(value));
        this._product_note_types = res['product_note_types'].map ( value => new DbEnumeration().fromJSON(value));
        this._product_relation_types = res['product_relation_types'];
        this._languages = res['languages'] ? res['languages'].map( value => new DbEnumeration().fromJSON(value)) : [];
        this._copyright_noticies = res['copyright_noticies'] ? res['copyright_noticies'].map(value => new DbEnumeration().fromJSON(value)) : [];
        this._vendor_order_status = res['vendor_order_status'] ? res['vendor_order_status'].map(value => new DbEnumeration().fromJSON(value)) : [];
      }))
      .toPromise()
      .then(l => {});
  }

  get order_origin_types(): DbEnumeration[] {
    return this._order_origins_types;
  }

  get gateways(): DbEnumeration[] {
    return this._gateways;
  }

  get countryList(): Country[] {
    return this._countryList;
  }

  get countryListGdpr(): Country[] {
    return this._countryListGdpr;
  }

  get stateList(): UsState[] {
    return this._stateList;
  }

  get provinceList(): CanadianProvince[] {
    return this._provinceList;
  }

  get orderStatusList(): DbEnumeration[] {
    return this._orderStatusList;
  }

  get returnStatusList(): DbEnumeration[] {
    return this._returnStatusList;
  }

  get shipMethodList(): DbEnumeration[] {
    return this._shipMethodList;
  }

  get paymentMethodList(): DbEnumeration[] {
    return this._paymentMethodList;
  }

  get miscTransactionList(): DbEnumeration[] {
    return this._miscTransactionList;
  }

  get paymentTermList(): DbEnumeration[] {
    return this._paymentTermList;
  }

  get formatTypes(): IFormatType[] {
    return this._formatTypes ? this._formatTypes.sort((a, b) => a.code > b.code ? 1 : -1) : [];
  }

  get fulfillmentMethods(): DbEnumeration[] {
    return this._fulfillmentMethods;
  }

  get transactionTypes(): DbEnumeration[] {
    return this._transactionTypes;
  }

  get returnCodes(): DbEnumeration[] {
    return this._returnCodes;
  }

  get couponStatusList(): DbEnumeration[] {
    return this._couponStatusList ? this._couponStatusList.sort((a, b) => a.code > b.code ? 1 : -1) : [];
  }
  get currencyExchange(): DbEnumeration[] {
    return this._currencyexchange;
  }
  get validCouponCodes(): DbEnumeration[] {
    return this._validCouponCodes ? this._validCouponCodes.sort((a, b) => a.code > b.code ? 1 : -1) : [];
  }

  get shippingProductId(): number {
    return this._shippingProductId;
  }

  get wireTransferProductId(): number {
    return this._wireTransferProductId;
  }

  get wireTransferProductDetailId(): number {
    return this._wireTransferProductDetailId;
  }

  get shippingProductDetailId(): number {
    return this._shippingProductDetailId;
  }

  get dateFormat(): string {
    return 'dd-MMM-yyyy';
  }

  get checkInfo(): string {
    return this._checkInfo;
  }

  get wireTransferInfo(): string {
    return this._wireTransferInfo;
  }

  get federalTaxId(): Array<string> {
    return this._federalTaxId;
  }

  get vats(): Array<string> {
    return this._vats;
  }

  get otherTaxIds(): Array<string> {
    return this._otherTaxIds;
  }

  get productTypes(): DbEnumeration[] {
    return this._productTypes;
  }

  get productNoteTypes(): DbEnumeration[] {
    return this._product_note_types;
  }
  
  get productRelationTypes(): IRelationType[] {
    return this._product_relation_types;
  }

  get languages(): DbEnumeration[] {
    return this._languages;
  }

  get copyrightNoticies(): DbEnumeration[] {
    return this._copyright_noticies;
  }

  get vendorOrderStatuses(): DbEnumeration[] {
    return this._vendor_order_status;
  }

}
