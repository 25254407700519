import { ISubGroup } from 'app/shared/interfaces';

export namespace SubGroup {
  export class GetAll {
    static readonly type = '[SubGroups] Get SubGroups';
  }

  export class Create {
    static readonly type = '[SubGroups] Create SubGroup';
    constructor(public payload: ISubGroup) { }
  }

  export class Update {
    static readonly type = '[SubGroups] Update SubGroup';
    constructor(public payload: ISubGroup) { }
  }
}
