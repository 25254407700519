import { ICoupon } from '../interfaces';

export const COUPON_PLACEHOLDER: ICoupon = {
  audience: null,
  code: null,
  coupon_amount: null,
  description: null,
  discount_amount: null,
  discount_type: 'percent',
  end_date: null,
  gtwy_code: null,
  id: null,
  issued_by: null,
  manufacturer_id: null,
  min_order_amount: null,
  multi_use: false,
  org_id: null,
  prd_id: null,
  prdd_id: null,
  product_format: null,
  prsn_id: null,
  rule_type: null,
  start_date: null,
  status: null,
  status_date: null,
  waive_shipping: null,
}