import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SearchType } from 'app/shared/enums';
import { ICoupon } from 'app/shared/interfaces';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class CouponsService {

  readonly baseUrl = environment.api_host;
  readonly url = `${this.baseUrl}/apis/forms/v1/data_management/coupons`;

  constructor(
    private http: HttpClient,
  ) { }

  getCoupons(): Observable<ICoupon[]> {
    return this.http.get<ICoupon[]>(`${this.url}`);
  }

  findCoupons(data: string): Observable<ICoupon[]> {
    return this.http.get<ICoupon[]>(`${this.url}/find_by_code/${data}`);
  }

  deleteCoupon(id: number): Observable<number> {
    return this.http.delete<{ id: string }>(`${this.url}/${id}`).pipe(
      map(response => Number(response.id)),
    );
  }

  findName(type: SearchType, data: string): Observable<string> {
    return this.http.get<{ name: string }>(`${this.url}/find_by/${type}/${data}`).pipe(
      map(response => response.name),
    );
  }

  createCoupon(data: ICoupon): Observable<ICoupon> {
    return this.http.post<ICoupon>(`${this.url}`, { coupon: data });
  }

  updateCoupon(data: ICoupon): Observable<ICoupon> {
    return this.http.patch<ICoupon>(`${this.url}/${data.id}`, { coupon: data });
  }

}
