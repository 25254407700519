import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { SourceTypes } from "app/shared/enums";
import { IChangesHistory } from "app/shared/interfaces";
import { environment } from "environments/environment";
import { Observable } from "rxjs";

@Injectable({ providedIn: 'root' })
export class ChangesHistoryService {

  readonly url = `${environment.api_host}/apis/forms/v1/data_modifications`;

  constructor(
    private http: HttpClient,
  ) { }

  getChangesHistory(sourceType: SourceTypes, id: string): Observable<IChangesHistory[]> {
    return this.http.get<IChangesHistory[]>(`${this.url}/${sourceType}/${id}`);
  }

}
