import { Component } from '@angular/core';
import { AlertService } from '../_services/alert.service';

@Component({
    selector: 'alert',
    templateUrl: 'alert.component.html'
})

export class AlertComponent {
    message: any;

    constructor(private alertService: AlertService) { }

    ngOnInit() {
        this.alertService.getMessage().subscribe(message => this.handleMessage(message));
    }

    handleMessage(message) {
        this.message = message;
    }
}
