import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Entitlement } from '../../_models/entitlement';
import { BehaviorSubject ,  Observable, from, of } from 'rxjs';
import { EntitlementContent } from '../../_models/entitlement_content';
import { toArray, switchMap } from 'rxjs/operators';

@Injectable()
export class EntitlementService {
  private baseUrl = `${environment.api_host}/apis/forms/`;
  dataChange: BehaviorSubject<Entitlement[]> = new BehaviorSubject<Entitlement[]>([]);

  constructor(
    private _client: HttpClient
  ) {}

  get data(): Entitlement[] { return this.dataChange.value; }

  replaceContent(content: EntitlementContent): Observable<EntitlementContent> {
    const index = this.dataChange.value.findIndex((item) => item.contents.length !== 0 && item.contents[0].id === content.id);
    this.dataChange.value[index].contents[0] = content;
    return of(content);
  }

  replaceEntitlement(entitlement: Entitlement): Observable<Entitlement> {
    const replace: Observable<EntitlementContent>[] = entitlement.contents.map((item) => this.replaceContent(item) );
    return from(replace).pipe(toArray(), switchMap(() => of(entitlement)));
  }

  getEntitlements(person_id) {
    this._client.get<Entitlement[]>(this.baseUrl + `v1/orders/${person_id}/entitlements`)
      .subscribe( success => {
        const data: Entitlement[] = [];
        // Create flat array of each separate document considering each entitlement can have multiple documents.
        success.forEach(function(item) {
          data.push(new Entitlement().fromJSON(Object.assign({}, item)));
          if(item.contents.length > 1) {
            const index: number = data.length - 1;
            item.contents.forEach(function(entitlement) {
              const file: any = Object.assign({}, item);
              file.id = 0;
              file.contents = [];
              file.contents.push(entitlement);
              data.push(new Entitlement().fromJSON(file));
            });
            data[index].contents = [];
          }
        }, this);
        this.dataChange.next(data);
      });
  }
}
