import { Component } from '@angular/core';

@Component({
  selector: 'retired-invoice-sa-cc-info',
  template: `
    <span>Credit Card</span>
    <hr>
    <span>Contact Customer Care for Payment</span><br/>
    <span>Phone: 1800 845 140 | +61 2 9161 7799</span><br/>
    <span>Email: store&#64;techstreet.com</span><br/>
    <span>Currency: AUD</span>
  `,
})
export class RetiredInvoiceSaCreditCardComponent {}
