import { Injectable, NgZone } from "@angular/core";
import { MatLegacySnackBar as MatSnackBar } from "@angular/material/legacy-snack-bar";
import { Alert } from "app/shared/interfaces";

@Injectable({ providedIn: 'root' })
export class AlertService {

  constructor(
    private readonly snackBar: MatSnackBar,
    private zone: NgZone,
  ) { }

  showAlert(alert: Alert) {
    this.zone.run(() => {
      this.snackBar.open(
        alert.message,
        alert.type,
        {
          duration: 6000,
          horizontalPosition: 'center',
          verticalPosition: 'top',
          panelClass: [`alert-${alert.type.toLowerCase()}`],
        },
      );
    });
  }

}