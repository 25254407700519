import { Injectable } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { Action, State, StateContext } from '@ngxs/store';
import { ISubGroup } from 'app/shared/interfaces';
import { catchError, tap } from 'rxjs/operators';
import { AlertService, ErrorHandlerService, SubGroupsService } from '../services';
import { HTTP_MESSAGES } from 'app/shared/constants';
import * as actions from './action-namespaces/sub-groups';

export interface SubGroupsState {
  subGroups: ISubGroup[];
}

@State<SubGroupsState>({
  name: 'subGroups',
  defaults: {
    subGroups: [],
  },
})
@Injectable()
export class SubGroupsStore {
  constructor(
    private readonly subGroupsService: SubGroupsService,
    private readonly errorHandlerService: ErrorHandlerService,
    private readonly dialog: MatDialog,
    private readonly alertService: AlertService,
  ) { }

  @Action(actions.SubGroup.GetAll)
  getSubGroups(ctx: StateContext<SubGroupsState>) {
    return this.subGroupsService.getSubGroups().pipe(
      tap(subGroups => ctx.patchState({ subGroups })),
      catchError(e => this.errorHandlerService.handleError(HTTP_MESSAGES.generalError, e)),
    );
  }

  @Action(actions.SubGroup.Create)
  createSubGroup(ctx: StateContext<SubGroupsState>, action: actions.SubGroup.Create) {
    return this.subGroupsService.createSubGroup(action.payload).pipe(
      tap(subGroup => {
        this.dialog.closeAll();
        this.alertService.showAlert({ message: HTTP_MESSAGES.createSuccess, type: 'SUCCESS' });
        return ctx.patchState({
          subGroups: [ ...ctx.getState().subGroups, subGroup ],
        });
      }),
      catchError(e => this.errorHandlerService.handleError(HTTP_MESSAGES.createFail, e)),
    );
  };

  @Action(actions.SubGroup.Update)
  updateSubGroup(ctx: StateContext<SubGroupsState>, action: actions.SubGroup.Update) {
    return this.subGroupsService.updateSubGroup(action.payload).pipe(
      tap(subGroup => {
        this.dialog.closeAll();
        this.alertService.showAlert({ message: HTTP_MESSAGES.updateSuccess, type: 'SUCCESS' });
        return ctx.patchState({
          subGroups: ctx.getState().subGroups.map(s => s.subgroup_id == subGroup.subgroup_id ? subGroup : s),
        });
      }),
      catchError(e => this.errorHandlerService.handleError(HTTP_MESSAGES.updateFail, e)),
    );
  };

}
