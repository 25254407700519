/* src/app/shared/ent-content-log/ent-content-log.component.scss */
form {
  display: flex;
  margin-top: 1em;
}
mat-form-field {
  border-left: 1em solid white;
}
form mat-form-field:first-child {
  width: 350px !important;
  border-left: none;
}
tr th:first-child,
tr td:first-child {
  width: 12%;
  flex: none;
}
tr th:nth-child(2),
tr td:nth-child(2) {
  width: 15%;
  flex: none;
}
tr th:nth-child(3),
tr td:nth-child(3) {
  width: 15%;
  flex: none;
}
/*# sourceMappingURL=ent-content-log.component.css.map */
