import { InventoryTypes } from 'app/shared/enums';
import { IInventoryOrder, ReportFilterType } from 'app/shared/interfaces';

export namespace ReportOrders {
  export class GetAll {
    static readonly type = '[Procurement] Get Report Orders';
  }

  export class SetReportFilter {
    static readonly type = '[Procurement] Set Report Filter';
    constructor(public payload: ReportFilterType) { }
  }
}

export namespace InventoryOrders {
  export class GetAll {
    static readonly type = '[Procurement] Get Inventory Orders';
    constructor(public payload: InventoryTypes) { }
  }

  export class Edit {
    static readonly type = '[Procurement] Edit Inventory Order';
    constructor(public payload: { id: number, data: Partial<IInventoryOrder>, tab: InventoryTypes }) { }
  }

  export class BulkEdit {
    static readonly type = '[Procurement] Bulk Edit Inventory Orders';
    constructor(public payload: { ids: number[], data: Partial<IInventoryOrder>, tab: InventoryTypes }) { }
  }

  export class GetAdditionalDocuments {
    static readonly type = '[Procurement] Get Additional Documents';
  }

  export class EditAdditionalDocument {
    static readonly type = '[Procurement] Edit Additional Document';
    constructor(public payload: { id: number, data: number }) { }
  }
}

export namespace GeneralPurpose {
  export class GetDashboardData {
    static readonly type = '[Procurement] Get Dashboard Data';
  }
}

export namespace InvtOrders {
  export class GetAll {
    static readonly type = '[Procurement] Get Invt Orders';
    constructor(public payload: InventoryTypes) { }
  }

  export class GetItems {
    static readonly type = '[Procurement] Get Invt Order Items';
    constructor(public payload: number) { }
  }
}
