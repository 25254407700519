import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  IOrderHold,
  IOrderOnHold,
  IOrdersOnHoldRequest,
  IPaginated,
  IUpdateOrderHoldStatusPayload,
} from 'app/shared/interfaces';
import { payloadToParams } from 'app/shared/utils';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class OrdersOnHoldService {

  readonly url = environment.api_host;

  constructor(private http: HttpClient) {}

  getOrdersOnHold(payload: IOrdersOnHoldRequest): Observable<IPaginated<IOrderOnHold>> {
    return this.http.get<IPaginated<IOrderOnHold>>(
      `${this.url}/apis/store/v2/order_holds/search`,
      { params: payloadToParams(payload) },
    );
  }

  getOrderOnHold(orderId: string): Observable<IOrderOnHold> {
    return this.http.get<IOrderOnHold>(`${this.url}/apis/store/v2/order_holds/search?order_id=${orderId}`);
  }

  updateOrderHoldStatus(payload: IUpdateOrderHoldStatusPayload): Observable<IOrderHold[]> {
    return this.http
      .get<{ order_holds: IOrderHold[] }>(
        `${this.url}/apis/store/v2/order_holds/update_status`,
        { params: { ...payload } },
      )
      .pipe(map(response => response.order_holds));
  }

}
