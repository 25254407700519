import { Action, State, StateContext } from '@ngxs/store';
import { IOrderHold, IOrderOnHold, IPaginated } from 'app/shared/interfaces';
import * as actions from './action-namespaces/orders-on-hold';
import { Injectable } from '@angular/core';
import { AlertService, ErrorHandlerService, OrdersOnHoldService } from '../services';
import { catchError, tap } from 'rxjs/operators';
import { HTTP_MESSAGES, INITIAL_ORDERS_ON_HOLD_REQUEST } from 'app/shared/constants';
import { MatDialog } from '@angular/material/dialog';
import { OrderHoldStatus } from 'app/shared/enums';

export interface OrdersOnHoldState {
  ordersOnHold: IPaginated<IOrderOnHold>;
  ordersOnHoldLoading: boolean;
  orderOnHold: IOrderOnHold | null;
}
export const ORDERS_ON_HOLD_FEATURE_NAME = 'ordersOnHold';
const defaults: OrdersOnHoldState = {
  ordersOnHold: {
    data: [],
    ...INITIAL_ORDERS_ON_HOLD_REQUEST
  },
  ordersOnHoldLoading: false,
  orderOnHold: null,
};

@State<OrdersOnHoldState>({
  name: ORDERS_ON_HOLD_FEATURE_NAME,
  defaults,
})
@Injectable()
export class OrdersOnHoldStore {
  constructor(
    private readonly ordersOnHoldService: OrdersOnHoldService,
    private readonly errorHandlerService: ErrorHandlerService,
    private readonly matDialog: MatDialog,
    private readonly alertService: AlertService,
  ) {}

  @Action(actions.OrdersOnHold.GetAll)
  getOrdersOnHold(ctx: StateContext<OrdersOnHoldState>, action: actions.OrdersOnHold.GetAll) {
    ctx.patchState({ ordersOnHoldLoading: true });
    return this.ordersOnHoldService.getOrdersOnHold(action.payload).pipe(
      tap(ordersOnHold => ctx.patchState(
        {
          ordersOnHold: {
            ...ordersOnHold,
            data: ordersOnHold.data.map(this.filterQuoteHolds),
          },
          ordersOnHoldLoading: false,
        }
      )),
      catchError(e => this.errorHandlerService.handleError(HTTP_MESSAGES.generalError, e, ctx)),
    );
  };

  @Action(actions.OrdersOnHold.GetOne)
  getOrderOnHold(ctx: StateContext<OrdersOnHoldState>, action: actions.OrdersOnHold.GetOne) {
    ctx.patchState({ orderOnHold: null });
    return this.ordersOnHoldService.getOrderOnHold(action.payload).pipe(
      tap(orderOnHold => ctx.patchState({
        orderOnHold: this.filterQuoteHolds(orderOnHold),
      })),
      catchError(e => this.errorHandlerService.handleError(HTTP_MESSAGES.generalError, e, ctx)),
    );
  };

  @Action(actions.OrdersOnHold.Reset)
  resetOrdersOnHold(ctx: StateContext<OrdersOnHoldState>) {
    ctx.patchState({ ordersOnHold: defaults.ordersOnHold });
  };

  @Action(actions.OrderHold.UpdateStatus)
  updateOrderHoldStatus(ctx: StateContext<OrdersOnHoldState>, action: actions.OrderHold.UpdateStatus) {
    return this.ordersOnHoldService.updateOrderHoldStatus(action.payload).pipe(
      tap(orderHolds => {
        ctx.patchState({
          orderOnHold: this.filterQuoteHolds({
            ...ctx.getState().orderOnHold,
            order_holds: orderHolds,
          }),
        });
        this.matDialog.closeAll();
        this.alertService.showAlert({
          message: HTTP_MESSAGES.generalSuccess,
          type: 'SUCCESS',
        });
      }),
      catchError(e => {
        return this.errorHandlerService.handleError(
          e?.error?.errors === 'TOKEN_INVALID' ? 'Order hold has expired' : HTTP_MESSAGES.generalError,
          e,
          ctx,
        );
      }),
    );
  };

  filterQuoteHolds(orderOnHold: IOrderOnHold): IOrderOnHold {
    return ({
      ...orderOnHold,
      order_holds: orderOnHold.order_holds.filter(oh => oh.order_hold_status !== OrderHoldStatus.Quote)
    });
  }

}
