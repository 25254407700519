import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';
import { HTTP_MESSAGES } from 'app/shared/constants';
import { IChangesHistory, IChangesHistoryPayload } from 'app/shared/interfaces';
import { catchError, tap } from 'rxjs/operators';
import { ChangesHistoryService } from '../services/changes-history.service';
import { ErrorHandlerService } from '../services/error-handler.service';

export class GetHistory {
  static readonly type = '[Changes History] Get History';
  constructor(public payload: IChangesHistoryPayload) { }
}

export interface ChangesHistoryState {
  loading: boolean;
  history: IChangesHistory[];
}

@State<ChangesHistoryState>({
  name: 'changesHistory',
  defaults: {
    loading: false,
    history: [],
  }
})
@Injectable()
export class ChangesHistoryStore {
  constructor(
    private readonly changesHistoryService: ChangesHistoryService,
    private readonly errorHandlerService: ErrorHandlerService,
  ) { }

  @Action(GetHistory)
  getChangesHistory(ctx: StateContext<ChangesHistoryState>, action: GetHistory) {
    ctx.patchState({ loading: true, history: [] });
    const { sourceType, id } = action.payload;
    return this.changesHistoryService.getChangesHistory(sourceType, id).pipe(
      tap(history => ctx.patchState({ history, loading: false })),
      catchError(e => this.errorHandlerService.handleError(HTTP_MESSAGES.generalError, e, ctx)),
    );
  }

}
