import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IMissingPDF, IMissingPDFRequest, IPDFError, IPaginated } from 'app/shared/interfaces';
import { payloadToParams } from 'app/shared/utils';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class PDFIssuesService {

  readonly url = environment.api_host;

  constructor(private http: HttpClient) {}

  getPDFErrors(): Observable<IPDFError[]> {
    return this.http.get<{ data: IPDFError[] }>(`${this.url}/apis/forms/v1/errors/overview/file_not_delivered`)
      .pipe(
        map(response => response?.data || []),
      );
  }

  getMissingPDFs(payload: IMissingPDFRequest): Observable<IPaginated<IMissingPDF>> {
    return this.http.get<IPaginated<IMissingPDF>>(
      `${this.url}/apis/base/v1/pdfs/missing`,
      { params: payloadToParams(payload) },
    );
  }

}
