import { IResetFieldPayload, ISSOComment, ISSOIssue, ISSOIssuesRequest, ISubscriptionSettings } from 'app/shared/interfaces';
import { SSOIssuesState } from 'app/store';

export namespace SSOIssue {
  export class GetAll {
    static readonly type = '[SSOIssue] Get Issues';
    constructor(public payload: ISSOIssuesRequest) { }
  }

  export class UpdateStatus {
    static readonly type = '[SSOIssue] Get Issue Update Status';
    constructor(public payload: Partial<ISSOIssue>) { }
  }

  export class AddComment {
    static readonly type = '[SSOIssue] Add Comment';
    constructor(public payload: Partial<ISSOComment>) { }
  }
}

export namespace Subscription {
  export class GetAll {
    static readonly type = '[SSOIssue] Get Subscriptions';
  }

  export class GetOne {
    static readonly type = '[SSOIssue] Get Subscription Settings';
    constructor(public payload: number) { }
  }

  export class Update {
    static readonly type = '[SSOIssue] Update Subscription';
    constructor(public payload: ISubscriptionSettings) { }
  }
}

export namespace GeneralPurpose {
  export class ResetSSOIssuesField {
    static readonly type = '[SSOIssue] Reset Field';
    constructor(public payload: IResetFieldPayload<SSOIssuesState>) { }
  }
}
