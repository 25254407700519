export const environment = {
  production: true,
  envName: 'qa',
  api_host: 'https://janus.qa-techstreet.com',
  tokenization_url: 'https://qa.securepayment.thomsonreuters.com',
  techstreet_store_url: 'store.qa-techstreet.com',
  storeLoginUrl: "//store.qa-techstreet.com/support/users/login",
  neptune_api: 'https://neptune.qa-techstreet.com',
  neptune_token: '',
  launchDarklyClientId: '65a695cef393841093aaab8e',
};
