import { Component } from '@angular/core';

@Component({
  selector: 'retired-sa-invoice-remit-info',
  template: `
    <span>ACH or Bank Transfer:</span>
    <hr>
    <span>Acct Name: Techstreet PTY LTD</span><br/>
    <span>Acct Number: 154146002</span><br/>
    <span>BSB Number: 013381</span><br/>
    <span>SWIFT: ANZBAU3M</span><br/>
    <span>Bank Name: ANZ</span><br/>
    <span>Bank Address: Northland Shopping Centre, shop JO65A, 2-50 Murray Rd, Preston VIC 3072</span><br/>
    <span>Telephone: +61 2 9767 1163</span><br/>
    <span>Currency: AUD</span><br/>
    <br/>
  `,
})
export class RetiredSaInvoiceRemitComponent {}
