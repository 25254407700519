<div class="page grid">
<h3> Order {{ order.id }} </h3>
<div>
  <span><b>Buyer</b></span>
  <span>{{ order.first_name | capitalize }} {{ order.last_name | capitalize }} ( {{order.person_id}})</span>
</div>
<div *ngIf="order.gateway">
  <span><b>Gateway</b></span>
  <span>{{order.gateway}}</span>
</div>
<mat-divider></mat-divider>
<mat-grid-list cols="12" rowHeight="55px" gutterSize="5px">
<mat-grid-tile colspan="6" rowspan="3" *ngIf="order.shipping_address">
<h4>Shipping Address:</h4>
  <span class="spacer"></span>

  <app-address [address]="order.shipping_address" *ngIf="order.shipping_address"></app-address>
</mat-grid-tile>
  <mat-grid-tile colspan="6" rowspan="3" *ngIf="order.billing_address">
  <h4>Billing Address:</h4>
<app-address [address]="order.billing_address" ></app-address>
  </mat-grid-tile>
</mat-grid-list>
  <mat-divider></mat-divider>
  <div *ngIf="order.unshipped_line_items().length > 0">
  <h4>Unshipped Items</h4>
  <table>
    <thead>
    <tr>
      <th>Item</th>
      <th>Format</th>
      <th>Quantity</th>
      <th>Price</th>
    </tr>
    </thead>
    <tbody>
      <tr *ngFor="let line_item of order.sorted_unshipped_line_items()">
        <td>{{line_item.item}}</td>
        <td>{{line_item.format}}</td>
        <td>{{line_item.quantity}}</td>
        <td>{{line_item.price | currency:'USD':'symbol'}}</td>
      </tr>
    </tbody>
    <tfoot>
      <tr *ngIf="order.wire_transfer_item()">
        <td colspan="2"></td>
        <td>Wire Transfer Fee:</td>
        <td>{{order.wire_transfer_item().price | currency:order.currency:true}}</td>
      </tr>
      <tr>
        <td colspan="2"></td>
        <td>Total:</td>
        <td>{{(order.unshipped_item_total() + order.wire_transfer_fee()) | currency:'USD':'symbol'}}</td>
      </tr>
    </tfoot>
  </table>

</div>

<div *ngFor="let invoice of order.invoices">
  <h4>Invoice {{ invoice.id }}</h4>
    <table cellspacing="0" cellpadding="0">
      <thead>
        <tr>
          <th>Item</th>
          <th>Format</th>
          <th>Quantity</th>
          <th>Price</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let line_item of invoice.shipped_line_items">
          <td>{{line_item.item}}</td>
          <td>{{line_item.format}}</td>
          <td>{{line_item.quantity}}</td>
          <td>{{line_item.total_price() | currency:'USD':'symbol' }}</td>
        </tr>
      </tbody>
      <tfoot>
        <tr>
          <td colspan="2"></td>
          <td>Shipping</td>
          <td>{{ invoice.ship_charge | currency:'USD':'symbol' }}</td>
        </tr>
        <tr *ngIf="invoice.wire_transfer_item()">
          <td colspan="2"></td>
          <td>Wire Transfer Fee</td>
          <td>{{ invoice.wire_transfer_item().price | currency:'USD':'symbol' }}</td>
        </tr>
        <tr>
          <td colspan="2"></td>
          <td>Tax</td>
          <td>{{ invoice.tax | currency:'USD':'symbol' }}</td>
        </tr>
        <tr *ngIf="invoice.coupon_amount > 0">
          <td colspan="2"></td>
          <td>Coupon Discount</td>
          <td>{{ (invoice.coupon_amount * -1)| currency:'USD':'symbol' }}</td>
        </tr>
        <tr>
          <td colspan="2"></td>
          <td>Total</td>
          <td>{{ invoice.total | currency:'USD':'symbol' }}</td>
        </tr>
      </tfoot>
    </table>
</div>
</div>
