import { Mappable } from './mappable';

export class Address extends Mappable {
  id: number = 0;
  first_name: string;
  last_name: string;
  company_name: string;
  line_1: string;
  line_2: string;
  line_3: string;
  city: string;
  country_code: string;
  country: string;
  zip_code_extension: string;
  postal_code: string;
  state_province: string;
  person_id: number;
  is_default: boolean = false;
  sync: boolean = false;
  match_id: number = null;
  attention: string;

  toString(): string {
    return ['first_name', 'last_name', 'company_name', 'line_1', 'line_2', 'line_3', 'city', 'state_province', 'postal_code', 'attention']
      .reduce((sum, n) => sum + `${this[n]}`, '');
  }

  equals(a: Address): boolean {
    return !!a && (this.toString() === a.toString());
  }

  constructor() { super() }
}
