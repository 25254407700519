/* src/app/shared/product-pricing/product-pricing.component.css */
.mat-select {
  min-width: 120px;
}
.no-wrap {
  white-space: nowrap;
}
.unpublished-warning {
  display: flex;
  align-items: center;
}
.message {
  color: red;
  margin-left: 1rem;
}
/*# sourceMappingURL=product-pricing.component.css.map */
