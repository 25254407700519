import { Pipe, PipeTransform } from '@angular/core';
@Pipe({ name: "sortBy" })
export class SortPipe implements PipeTransform {
  transform(array: Array<string>, args: string, orderType: string): Array<string> {
    if (array != undefined) {
      const isDate = array.every(item =>
        item[args] === null || (new Date(item[args]).toString() !== 'Invalid Date' && item[args] !== null)
      );
      if(orderType === 'DESC'){
        array.sort((a: any, b: any) => {
          if (isDate) return new Date(a[args]).getTime() > new Date(b[args]).getTime() ? -1 : 1;
          if (a[args] > b[args]) {
            return -1;
          } else if (a[args] < b[args]) {
            return 1;
          } else {
            return 0;
          }
        });

      }else{
        array.sort((a: any, b: any) => {
          if (isDate) return new Date(a[args]).getTime() < new Date(b[args]).getTime() ? -1 : 1;
          if (a[args] < b[args]) {
            return -1;
          } else if (a[args] > b[args]) {
            return 1;
          } else {
            return 0;
          }
        });
      }
      return array;
    }
  }
}
