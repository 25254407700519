import { Component, OnInit ,Input,ViewChild} from '@angular/core';
import { PaymentService } from '../accounts/_services/payment.service';
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';


@Component({
  selector: 'app-payment-transaction',
  templateUrl: './payment-transaction.component.html',
  styleUrls: ['./payment-transaction.component.scss']
})
export class PaymentTransactionComponent implements OnInit{
  payment:any = [];
  invoice:any = [];
  miscDetails:any  =[];
  public _validPaymentTypes = {'CK':'Check', 'CC' : 'Credit Card', 'WTO' :'Wire Transfer Order',
                              'ICTI' :'Intercompany Transaction In', 'OLCC':'Offline Credit Card Payment', 'PTI':'Payment Transfer In'};


  @Input() payment_id: number;
  @Input() dialogRef: MatDialogRef<any>;
  constructor(
    private _paymentService: PaymentService,
  ) { }

  ngOnInit() {
    this._paymentService.getPaymentDetails(this.payment_id).subscribe((result) => {
      this.payment =result;
    });

    this._paymentService.get_applied_invoice_details(this.payment_id).subscribe((result)=>{
      this.invoice= result;
    });

    this._paymentService.get_applied_misc_details(this.payment_id).subscribe((result)=>{
      this.miscDetails =result;
    });

  }
}
