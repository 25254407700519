import { Mappable } from './mappable';
import { ProductItem } from './product_item';

interface PrintPlusPdfBundle {
  print: number;
  pdf: number;
}

export class LineItem extends Mappable {
  id: number = 0;
  availability: string;
  cost: number = 0.0;
  discount: number = 0;
  downloadable: boolean;
  format: string;
  format_code: string;
  fulfillment_code: string;
  item: string;
  item_date: Date;
  list_price: number = 0.0;
  prdd_id: number;
  price: number = 0.0;
  product_id: number;
  quantity: number = 1;
  quantity_back_ordered: number = 1;
  vendor_id: number;
  vendor_payment_method: string;
  weight: number;
  product_item: ProductItem;
  status_date: Date;
  country_restrictions: string[];
  product_title: string;
  plus_bundle: PrintPlusPdfBundle;
  unshipped_quantity: number;
  quantity_on_hand: number;
  language: string;
  return_id: number;

  total_price(p = this.price, d = this.discount, q = this.quantity): number {
    const discount_multiplier = d ? (100 - d) / 100 : 1;
    return p * discount_multiplier * q;
  }

  constructor() { super(); };
}
