import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {AlertComponent} from '../_directives/alert.component';
import {AddressComponent} from './address/address.component';
import {CssInvoiceHeaderComponent} from './invoice/headers/css.component';
import {CssInvoiceRemitComponent} from './invoice/remits/css.component';
import {InvoiceCssCheckComponent} from './invoice/payments/css-check.component';
import {OrderSummaryComponent} from '../order-summary/order-summary.component';
import {ProductPricingComponent} from './product-pricing/product-pricing.component';
import {EntContentLogComponent} from './ent-content-log/ent-content-log.component';
import {DialogService} from '../_services/dialog.service';
import {CommonModule} from '@angular/common';
import {CapitalizePipe} from '../_pipes/capitalize.pipe';
import {SafePipe} from '../_pipes/safe.pipe';
import {PricePipe} from '../_pipes/price.pipe';
import {SortPipe} from '../_pipes/ordersort.pipe';

import {MatDividerModule} from '@angular/material/divider';
import {MatLegacyTooltipModule as MatTooltipModule} from '@angular/material/legacy-tooltip';
import {MatLegacyProgressSpinnerModule as MatProgressSpinnerModule} from '@angular/material/legacy-progress-spinner';
import {MatLegacyAutocompleteModule as MatAutocompleteModule} from '@angular/material/legacy-autocomplete';
import {MatLegacySelectModule as MatSelectModule} from '@angular/material/legacy-select';
import {CdkTableModule} from '@angular/cdk/table';
import {MatLegacyPaginatorModule as MatPaginatorModule} from '@angular/material/legacy-paginator';
import {MatLegacyDialogModule as MatDialogModule} from '@angular/material/legacy-dialog'
import {
  DuplicateErrorMessagePipe,
  EmailErrorMessagePipe,
  InvalidErrorMessagePipe,
  MaxErrorMessagePipe,
  MaxlengthErrorMessagePipe,
  MinErrorMessagePipe,
  RequiredErrorMessagePipe
} from '../_pipes/error_message.pipe';

import {PriceInputComponent} from './price-input/price-input.component';
import {ResetAssigneeComponent} from './reset_assignee/reset-assignee.component';
import {EntitlementService} from 'app/orders/_services/entitlement.service';
import {ValidatedAddressComponent} from 'app/accounts/validated-address/validated-address.component';
import {MatNativeDateModule, MatRippleModule, NativeDateModule} from '@angular/material/core';
import {MatGridListModule} from '@angular/material/grid-list';
import {MatLegacyListModule as MatListModule} from '@angular/material/legacy-list';
import {MatLegacyButtonModule as MatButtonModule} from '@angular/material/legacy-button';
import {MatIconModule} from '@angular/material/icon';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatLegacyMenuModule as MatMenuModule} from '@angular/material/legacy-menu';
import {MatLegacyCardModule as MatCardModule} from '@angular/material/legacy-card';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatLegacyFormFieldModule as MatFormFieldModule} from '@angular/material/legacy-form-field';
import {MatLegacyInputModule as MatInputModule} from '@angular/material/legacy-input';
import {MatLegacyRadioModule as MatRadioModule} from '@angular/material/legacy-radio';
import {MatLegacyTabsModule as MatTabsModule} from '@angular/material/legacy-tabs';
import {MatSidenavModule} from '@angular/material/sidenav';
import {MatLegacyCheckboxModule as MatCheckboxModule} from '@angular/material/legacy-checkbox';
import {MatLegacySlideToggleModule as MatSlideToggleModule} from '@angular/material/legacy-slide-toggle';
import { MatLegacyTableModule as MatTableModule} from '@angular/material/legacy-table';
import {MatLegacySnackBarModule as MatSnackBarModule} from '@angular/material/legacy-snack-bar';
import { MatSortModule } from '@angular/material/sort';
import { ConfirmationDialogComponent } from './components/confirmation-dialog/confirmation-dialog.component';
import { NoDataComponent } from './components/no-data/no-data.component';
import { TitleComponent } from './components/title/title.component';
import { ChangesHistoryComponent } from './components/changes-history/changes-history.component';
import { ChangesHistoryContainer } from './components/changes-history/changes-history.container';
import { VendorSearchComponent } from './components/vendor-search/vendor-search.component';
import { ForbiddenComponent } from './components/forbidden/forbidden.component';
import { RetiredCssInvoiceHeaderComponent } from './invoice/retired/headers/retired-css.component';
import { RetiredInvoiceVatsComponent } from './invoice/retired/retired-vat.component';
import { RetiredSaInvoiceHeaderComponent } from './invoice/retired/headers/retired-sa.component';
import { RetiredCssInvoiceRemitComponent } from './invoice/retired/remits/retired-css.component';
import { RetiredInvoiceCssCheckComponent } from './invoice/retired/payments/retired-css-check.component';
import { RetiredSaInvoiceRemitComponent } from './invoice/retired/remits/retired-sa.component';
import { RetiredAudInvoiceHeaderComponent } from './invoice/retired/headers/retired-aud.component';
import { RetiredInvoiceSaCreditCardComponent } from './invoice/retired/payments/retired-sa-cc.component';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        MatRippleModule,
        MatDividerModule,
        MatGridListModule,
        MatListModule,
        MatButtonModule,
        MatMenuModule,
        MatToolbarModule,
        MatIconModule,
        MatCardModule,
        MatDatepickerModule,
        MatNativeDateModule,
        MatTooltipModule,
        MatFormFieldModule,
        MatInputModule,
        MatRadioModule,
        MatSelectModule,
        MatTabsModule,
        MatSidenavModule,
        MatCheckboxModule,
        MatSlideToggleModule,
        MatTableModule,
        MatPaginatorModule,
        MatDialogModule,
        MatSnackBarModule,
        MatProgressSpinnerModule,
        MatSortModule,
        MatAutocompleteModule,
        CdkTableModule,
        NativeDateModule,
    ],
    declarations: [
        InvoiceCssCheckComponent,
        CssInvoiceRemitComponent,
        CssInvoiceHeaderComponent,
        RetiredCssInvoiceHeaderComponent,
        AddressComponent,
        AlertComponent,
        CapitalizePipe,
        SafePipe,
        PricePipe,
        SortPipe,
        RequiredErrorMessagePipe,
        InvalidErrorMessagePipe,
        DuplicateErrorMessagePipe,
        MaxlengthErrorMessagePipe,
        MinErrorMessagePipe,
        MaxErrorMessagePipe,
        EmailErrorMessagePipe,
        OrderSummaryComponent,
        ProductPricingComponent,
        PriceInputComponent,
        ResetAssigneeComponent,
        EntContentLogComponent,
        ValidatedAddressComponent,
        ConfirmationDialogComponent,
        NoDataComponent,
        TitleComponent,
        ChangesHistoryComponent,
        ChangesHistoryContainer,
        VendorSearchComponent,
        ForbiddenComponent,
        RetiredInvoiceVatsComponent,
        RetiredSaInvoiceHeaderComponent,
        RetiredCssInvoiceRemitComponent,
        RetiredInvoiceCssCheckComponent,
        RetiredSaInvoiceRemitComponent,
        RetiredAudInvoiceHeaderComponent,
        RetiredInvoiceSaCreditCardComponent,
    ],
    exports: [
        InvoiceCssCheckComponent,
        CssInvoiceRemitComponent,
        CssInvoiceHeaderComponent,
        RetiredCssInvoiceHeaderComponent,
        AddressComponent,
        AlertComponent,
        CapitalizePipe,
        SafePipe,
        PricePipe,
        SortPipe,
        RequiredErrorMessagePipe,
        InvalidErrorMessagePipe,
        DuplicateErrorMessagePipe,
        MaxlengthErrorMessagePipe,
        MinErrorMessagePipe,
        MaxErrorMessagePipe,
        EmailErrorMessagePipe,
        MatDatepickerModule,
        PriceInputComponent,
        EntContentLogComponent,
        MatRippleModule,
        MatDividerModule,
        MatGridListModule,
        MatListModule,
        MatButtonModule,
        MatMenuModule,
        MatToolbarModule,
        MatIconModule,
        MatCardModule,
        MatDatepickerModule,
        MatNativeDateModule,
        MatTooltipModule,
        MatFormFieldModule,
        MatInputModule,
        MatRadioModule,
        MatSelectModule,
        MatTabsModule,
        MatSidenavModule,
        MatCheckboxModule,
        MatSlideToggleModule,
        MatTableModule,
        MatPaginatorModule,
        MatDialogModule,
        MatSnackBarModule,
        MatProgressSpinnerModule,
        MatSortModule,
        MatAutocompleteModule,
        CdkTableModule,
        NativeDateModule,
        ValidatedAddressComponent,
        NoDataComponent,
        TitleComponent,
        ChangesHistoryComponent,
        ChangesHistoryContainer,
        VendorSearchComponent,
        ForbiddenComponent,
        RetiredInvoiceVatsComponent,
        RetiredSaInvoiceHeaderComponent,
        RetiredCssInvoiceRemitComponent,
        RetiredInvoiceCssCheckComponent,
        RetiredSaInvoiceRemitComponent,
        RetiredAudInvoiceHeaderComponent,
        RetiredInvoiceSaCreditCardComponent,
    ],
    providers: [
        DialogService,
        EntitlementService
    ]
})
export class SharedModule {
}
