import { Action, State, StateContext } from '@ngxs/store';
import { IMissingPDF, IPDFError, IPaginated } from 'app/shared/interfaces';
import * as actions from './action-namespaces/pdf-issues';
import { Injectable } from '@angular/core';
import { ErrorHandlerService, PDFIssuesService } from '../services';
import { catchError, tap } from 'rxjs/operators';
import { HTTP_MESSAGES, INITIAL_MISSING_PDFS_REQUEST } from 'app/shared/constants';

export interface PDFIssuesState {
  loading: boolean;
  pdfErrors: IPDFError[];
  missingPDFs: IPaginated<IMissingPDF>;
}
export const PDF_ISSUES_FEATURE_NAME = 'pdfIssues';
const defaults: PDFIssuesState = {
  loading: false,
  pdfErrors: [],
  missingPDFs: {
    data: [],
    ...INITIAL_MISSING_PDFS_REQUEST
  },
};

@State<PDFIssuesState>({
  name: PDF_ISSUES_FEATURE_NAME,
  defaults,
})
@Injectable()
export class PDFIssuesStore {
  constructor(
    private readonly pdfIssuesService: PDFIssuesService,
    private readonly errorHandlerService: ErrorHandlerService,
  ) {}

  @Action(actions.PDFError.GetAll)
  getPDFErrors(ctx: StateContext<PDFIssuesState>) {
    ctx.patchState({
      pdfErrors: [],
      loading: true,
    });
    return this.pdfIssuesService.getPDFErrors().pipe(
      tap(pdfErrors => ctx.patchState(
        {
          pdfErrors,
          loading: false
        }
      )),
      catchError(e => this.errorHandlerService.handleError(HTTP_MESSAGES.generalError, e, ctx)),
    );
  };

  @Action(actions.MissingPDF.GetAll)
  getMissingPDFs(ctx: StateContext<PDFIssuesState>, action: actions.MissingPDF.GetAll) {
    ctx.patchState({ loading: true });
    return this.pdfIssuesService.getMissingPDFs(action.payload).pipe(
      tap(missingPDFs => ctx.patchState(
        {
          missingPDFs,
          loading: false
        }
      )),
      catchError(e => this.errorHandlerService.handleError(HTTP_MESSAGES.generalError, e, ctx)),
    );
  };

  @Action(actions.MissingPDF.Reset)
  resetMissingPDFs(ctx: StateContext<PDFIssuesState>) {
    ctx.patchState({ missingPDFs: defaults.missingPDFs });
  };

}
