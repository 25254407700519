import { IGateway } from '../interfaces';

 export const GATEWAY_PLACEHOLDER: IGateway = {
   _id: null,
   id: null,
   description: null,
   display_currency: 'USD',
   gateway_code: null,
   locale: 'en',
   main_page_url: null,
   org_id: null,
   org_name: null,
   status: 'A',
   status_date: null,
   txn_currency: 'USD',
   needs_sync: true,
   last_saved_user: '',
 }
 