import {Injectable} from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AppDataService } from './app_data.service';
import { Product } from '../_models/product';
import { ProductItem } from '../_models/product_item';
import {environment} from '../../environments/environment';
import { Observable ,  of } from 'rxjs';
import { map } from 'rxjs/operators';


interface ProductRepository {
  [index: string]: Product;
}

interface ProductItemByProductDictionary {
  [index: number]: number[];
}

@Injectable()
export class PricingService {

  //these functions do our confusing GST(tax) calculations for AUD/SA orders
  // 
  // amount - order subtotal or invoice line_item total
  //        - essentially the value that we need to extract the GST amount from
  // order_type - order.type, "Subscription Order" or "Retail Order"
  //
  // return either the calculated price without tax, or just the tax
  extractGstPrice(amount, order_type) {
      if(order_type === 'Subscription Order') {
        return amount;
      }
      var price_without_tax = (amount/1.1);
      var tax_from_price = (amount - price_without_tax).toFixed(2);
      return price_without_tax;
  }
  extractGstTax(amount, order_type) {
      if(order_type === 'Subscription Order') {
        return 0;
      }
      var price_without_tax = (amount/1.1);
      var tax_from_price = (amount - price_without_tax).toFixed(2);
      return tax_from_price;
  }
  

  _product_repository: ProductRepository = {};
  private productUrl = `${environment.api_host}/apis/forms/v1/products`;
  private productItemUrl = `${environment.api_host}/apis/forms/v1/product_items`;

  constructor(private http: HttpClient, private appDataService: AppDataService) { }

  get productRepository(): ProductRepository {
    return this._product_repository;
  }

  itemIdentifier(product_id, gateway = 'css', currency = 'USD', membership = false): string {
    const id = `${product_id}_${gateway}_${currency}_${membership}`;
    return id;
  }

  getPricingForProduct(product_id, gateway, currency, membership): Observable<Product> {
    if (this.productRepository[this.itemIdentifier(product_id, gateway, currency, membership)] == null) {
      return this.loadPricingForProduct(product_id, gateway, currency, membership).pipe(map(
        success => {
          console.log("got pricing for product: ",product_id, gateway, currency, membership)
          this.productRepository[this.itemIdentifier(product_id, gateway, currency, membership)] = success;
          return this.productRepository[this.itemIdentifier(product_id, gateway, currency, membership)];
          },
        error => {
          console.log('Could not retrieve data for ', product_id, gateway, currency, membership );
          console.log('Error: ', error);
        }
      ));
    } else {
      return of(this.productRepository[this.itemIdentifier(product_id, gateway, currency, membership)]);
    }
  }

  loadPricingForProduct(product_id, gateway, currency, membership): Observable<Product> {
    return this.http.get(`${this.productUrl}/${product_id}?` +
      `gateway=${gateway}&currency=${currency}&membership=${membership}`).pipe(map(
      success => {
        const p = new Product().fromJSON(success);
        p.items = p.items.map( pi => new ProductItem().fromJSON(pi));
        return p;
      }
    ));
  }

  loadPricingForProductItem(item_id, gateway, currency, membership): Observable<ProductItem> {
    return this.http.get(`${this.productItemUrl}/${item_id}?` +
      `gateway=${gateway}&currency=${currency}&membership=${membership}`).pipe(map(
      success => new ProductItem().fromJSON(success)
    ));
  }


}
