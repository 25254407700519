<div *ngIf="address">
  <div *ngIf="address.first_name && address.last_name">
    <span *ngIf="address.first_name">{{address.first_name | capitalize}}</span> <span *ngIf="address.last_name">{{address.last_name | capitalize}}</span>
  </div>
  <div>{{ address.company_name }}</div>
  <div>{{address.attention}}</div>
  <div>{{ address.line_1 }}</div>
  <div *ngIf="address.line_2">{{ address.line_2 }}</div>
  <div *ngIf="address.line_3">{{address.line_3}}</div>
  <div>{{ address.city }}, {{ address.state_province }} {{address.postal_code}}<span *ngIf="address.zip_code_extension">-{{address.zip_code_extension}}</span></div>
  <div *ngIf="address.country_code != 'US'">{{address.country_code}}</div>
</div>