import { IContact, IMultiUserException, IMultiUserPricing, IPriceCode, IRestriction, ISubVendor } from '../interfaces';

export const CONTACT_PLACEHOLDER: IContact = {
  apartment: null,
  attention: null,
  city: null,
  company: null,
  contact_id: null,
  country_code: 'US',
  default: false,
  first_name: null,
  last_name: null,
  postal_code: null,
  state_province: null,
  street_address: null,
  zip_code: null,
  zip_code_ext: null,
  email_ids: { E: null, BE: null, SE: null },
  phones: { P: null, AP1: null, AP2: null },
};

export const SUBVENDOR_PLACEHOLDER: ISubVendor = {
  display: 'N',
  id: null,
  name: null,
  number_of_items: null,
  publisher_code: null,
  trackable: 'Y',
};

export const RESTRICTION_PLACEHOLDER: IRestriction = {
  id: null,
  country_code: null,
  format: null,
  publisher_code: null,
};

export const PRICECODE_PLACEHOLDER: IPriceCode = {
  cost_in_stock: null,
  cost_no_stock: null,
  currency_code: null,
  description: null,
  exchange_rate: null,
  id: null,
  list_price: null,
  price_code: null,
  price_in_stock: null,
  price_no_stock: null,
  status: 'A',
  status_date: null,
};

export const MULTI_USER_PRICING_PLACEHOLDER: IMultiUserPricing = {
  id: null,
  format: null,
  price_multiplier: null,
  quantity: null,
  round_or_trunc: null,
  rounding_argument: null,
  royalty_multiplier: null,
  org_id_mnfr: null,
  org_id_vnd: null,
}

export const MULTI_USER_EXCEPTION_PLACEHOLDER: IMultiUserException = {
  id: null,
  product_id: null,
  publisher_code: null,
  vendor_number: null,
}
