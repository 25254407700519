import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { InventoryTypes } from 'app/shared/enums';
import { IDashboardData, IInventoryOrder, IInvtOrder, IReportOrder, IOrderItem } from 'app/shared/interfaces';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class ProcurementService {

  readonly baseUrl = environment.api_host;
  readonly url = `${this.baseUrl}/apis/forms/v1/procurements`;

  constructor(
    private http: HttpClient,
  ) { }

  getReportOrders(): Observable<IReportOrder[]> {
    return this.http.get<IReportOrder[]>(`${this.url}/report`);
  }

  getDashboardData(): Observable<IDashboardData> {
    return this.http.get<IDashboardData>(`${this.url}/reports_count`);
  }

  getInventoryOrders(type: InventoryTypes): Observable<IInventoryOrder[]> {
    return this.http.get<IInventoryOrder[]>(`${this.url}/inventories/${type}`);
  }

  getAdditionalDocuments(): Observable<IInventoryOrder[]> {
    return this.http.get<IInventoryOrder[]>(`${this.url}/additional_documents`);
  }

  editAdditionalDocument(id: number, data: number): Observable<IInventoryOrder> {
    return this.http.patch<IInventoryOrder>(`${this.url}/additional_documents/${id}`, { vendor_order: { quantity_received: data } });
  }

  editInventoryOrder(id: number, data: Partial<IInventoryOrder>): Observable<IInventoryOrder> {
    return this.http.patch<IInventoryOrder>(`${this.url}/inventories/${id}`, { vendor_order: data });
  }

  bulkEditInventoryOrders(ids: number[], data: Partial<IInventoryOrder>): Observable<IInventoryOrder[]> {
    const payload = {
      vendor_order: {
        ids,
        ...data,
      },
    };
    return this.http.post<IInventoryOrder[]>(`${this.url}/inventories/bulk_update`, payload);
  }

  getInvtOrders(type: InventoryTypes): Observable<IInvtOrder[]> {
    return this.http.get<IInvtOrder[]>(`${this.url}/inventory_orders/${type}`);
  }

  getInvtOrderItems(id: number): Observable<IOrderItem[]> {
    return this.http.get<IOrderItem[]>(`${this.url}/inventory_orders/order_line_items/${id}`);
  }

}
