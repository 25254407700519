<mat-dialog-content>

  <h2>Tracking History</h2>

  <div [hidden]="loading || !history?.length">
    <table
      mat-table
      [dataSource]="dataSource"
      matSort matSortActive="created_at" matSortDirection="desc" matSortDisableClear
      id="table-tracking-history"
    >
      <ng-container matColumnDef="author">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Author </th>
        <td mat-cell *matCellDef="let rec"> {{ rec.author }} </td>
      </ng-container>

      <ng-container matColumnDef="event">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Event </th>
        <td mat-cell *matCellDef="let rec"> {{ rec.event }} </td>
      </ng-container>

      <ng-container matColumnDef="created_at">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Date & Time </th>
        <td mat-cell *matCellDef="let rec"> {{ rec.created_at | date:'short' }} </td>
      </ng-container>

      <ng-container matColumnDef="object_changes">
        <th class="changes" mat-header-cell *matHeaderCellDef> Changes made </th>
        <td mat-cell *matCellDef="let rec">
          <div *ngFor="let change of getChanges(rec.object_changes)">
            {{ change }}
          </div>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
    <mat-paginator [pageSizeOptions]="[10, 25, 100]" showFirstLastButtons></mat-paginator>
  </div>

  <app-no-data
    *ngIf="!history.length && !loading"  
    [message]="'No history found'"
  ></app-no-data>
  
</mat-dialog-content>
