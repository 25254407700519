import * as i0 from '@angular/core';
import { Component, ViewEncapsulation, ChangeDetectionStrategy, Optional, Inject, NgModule } from '@angular/core';
import * as i2 from '@angular/common';
import { CommonModule } from '@angular/common';
import * as i1 from '@angular/material/core';
import { _MatOptgroupBase, MAT_OPTGROUP, _MatOptionBase, MAT_OPTION_PARENT_COMPONENT, MatRippleModule, MatCommonModule, MatPseudoCheckboxModule } from '@angular/material/core';
export { VERSION as LEGACY_VERSION, AnimationCurves as LegacyAnimationCurves, AnimationDurations as LegacyAnimationDurations, DateAdapter as LegacyDateAdapter, ErrorStateMatcher as LegacyErrorStateMatcher, NativeDateAdapter as LegacyNativeDateAdapter, NativeDateModule as LegacyNativeDateModule, RippleRef as LegacyRippleRef, RippleRenderer as LegacyRippleRenderer, ShowOnDirtyErrorStateMatcher as LegacyShowOnDirtyErrorStateMatcher, MATERIAL_SANITY_CHECKS as MATERIAL_LEGACY_SANITY_CHECKS, MAT_DATE_FORMATS as MAT_LEGACY_DATE_FORMATS, MAT_DATE_LOCALE as MAT_LEGACY_DATE_LOCALE, MAT_DATE_LOCALE_FACTORY as MAT_LEGACY_DATE_LOCALE_FACTORY, MAT_NATIVE_DATE_FORMATS as MAT_LEGACY_NATIVE_DATE_FORMATS, MAT_OPTGROUP as MAT_LEGACY_OPTGROUP, MAT_OPTION_PARENT_COMPONENT as MAT_LEGACY_OPTION_PARENT_COMPONENT, MAT_RIPPLE_GLOBAL_OPTIONS as MAT_LEGACY_RIPPLE_GLOBAL_OPTIONS, MatCommonModule as MatLegacyCommonModule, MatLine as MatLegacyLine, MatLineModule as MatLegacyLineModule, MatNativeDateModule as MatLegacyNativeDateModule, MatOptionSelectionChange as MatLegacyOptionSelectionChange, MatPseudoCheckbox as MatLegacyPseudoCheckbox, MatPseudoCheckboxModule as MatLegacyPseudoCheckboxModule, MatRipple as MatLegacyRipple, MatRippleModule as MatLegacyRippleModule, _MatOptgroupBase as _MatLegacyOptgroupBase, _MatOptionBase as _MatLegacyOptionBase, _countGroupLabelsBeforeOption as _countGroupLabelsBeforeLegacyOption, _getOptionScrollPosition as _getLegacyOptionScrollPosition, defaultRippleAnimationConfig as legacyDefaultRippleAnimationConfig, mixinColor as legacyMixinColor, mixinDisableRipple as legacyMixinDisableRipple, mixinDisabled as legacyMixinDisabled, mixinErrorState as legacyMixinErrorState, mixinInitialized as legacyMixinInitialized, mixinTabIndex as legacyMixinTabIndex, setLines as legacySetLines } from '@angular/material/core';

/**
 * Component that is used to group instances of `mat-option`.
 * @deprecated Use `MatOptgroup` from `@angular/material/core` instead. See https://material.angular.io/guide/mdc-migration for information about migrating.
 * @breaking-change 17.0.0
 */
class MatLegacyOptgroup extends _MatOptgroupBase {
  static {
    this.ɵfac = i0.ɵɵngDeclareFactory({
      minVersion: "12.0.0",
      version: "16.1.1",
      ngImport: i0,
      type: MatLegacyOptgroup,
      deps: null,
      target: i0.ɵɵFactoryTarget.Component
    });
  }
  static {
    this.ɵcmp = i0.ɵɵngDeclareComponent({
      minVersion: "14.0.0",
      version: "16.1.1",
      type: MatLegacyOptgroup,
      selector: "mat-optgroup",
      inputs: {
        disabled: "disabled"
      },
      host: {
        properties: {
          "attr.role": "_inert ? null : \"group\"",
          "attr.aria-disabled": "_inert ? null : disabled.toString()",
          "attr.aria-labelledby": "_inert ? null : _labelId",
          "class.mat-optgroup-disabled": "disabled"
        },
        classAttribute: "mat-optgroup"
      },
      providers: [{
        provide: MAT_OPTGROUP,
        useExisting: MatLegacyOptgroup
      }],
      exportAs: ["matOptgroup"],
      usesInheritance: true,
      ngImport: i0,
      template: "<span class=\"mat-optgroup-label\" role=\"presentation\" [id]=\"_labelId\">{{ label }} <ng-content></ng-content></span>\n<ng-content select=\"mat-option, ng-container\"></ng-content>\n",
      styles: [".mat-optgroup-label{white-space:nowrap;overflow:hidden;text-overflow:ellipsis;display:block;line-height:48px;height:48px;padding:0 16px;text-align:left;text-decoration:none;max-width:100%;-webkit-user-select:none;user-select:none;cursor:default}.mat-optgroup-label[disabled]{cursor:default}[dir=rtl] .mat-optgroup-label{text-align:right}.mat-optgroup-label .mat-icon{margin-right:16px;vertical-align:middle}.mat-optgroup-label .mat-icon svg{vertical-align:top}[dir=rtl] .mat-optgroup-label .mat-icon{margin-left:16px;margin-right:0}"],
      changeDetection: i0.ChangeDetectionStrategy.OnPush,
      encapsulation: i0.ViewEncapsulation.None
    });
  }
}
i0.ɵɵngDeclareClassMetadata({
  minVersion: "12.0.0",
  version: "16.1.1",
  ngImport: i0,
  type: MatLegacyOptgroup,
  decorators: [{
    type: Component,
    args: [{
      selector: 'mat-optgroup',
      exportAs: 'matOptgroup',
      encapsulation: ViewEncapsulation.None,
      changeDetection: ChangeDetectionStrategy.OnPush,
      inputs: ['disabled'],
      host: {
        'class': 'mat-optgroup',
        '[attr.role]': '_inert ? null : "group"',
        '[attr.aria-disabled]': '_inert ? null : disabled.toString()',
        '[attr.aria-labelledby]': '_inert ? null : _labelId',
        '[class.mat-optgroup-disabled]': 'disabled'
      },
      providers: [{
        provide: MAT_OPTGROUP,
        useExisting: MatLegacyOptgroup
      }],
      template: "<span class=\"mat-optgroup-label\" role=\"presentation\" [id]=\"_labelId\">{{ label }} <ng-content></ng-content></span>\n<ng-content select=\"mat-option, ng-container\"></ng-content>\n",
      styles: [".mat-optgroup-label{white-space:nowrap;overflow:hidden;text-overflow:ellipsis;display:block;line-height:48px;height:48px;padding:0 16px;text-align:left;text-decoration:none;max-width:100%;-webkit-user-select:none;user-select:none;cursor:default}.mat-optgroup-label[disabled]{cursor:default}[dir=rtl] .mat-optgroup-label{text-align:right}.mat-optgroup-label .mat-icon{margin-right:16px;vertical-align:middle}.mat-optgroup-label .mat-icon svg{vertical-align:top}[dir=rtl] .mat-optgroup-label .mat-icon{margin-left:16px;margin-right:0}"]
    }]
  }]
});

/**
 * Single option inside of a `<mat-select>` element.
 * @deprecated Use `MatOption` from `@angular/material/core` instead. See https://material.angular.io/guide/mdc-migration for information about migrating.
 * @breaking-change 17.0.0
 */
class MatLegacyOption extends _MatOptionBase {
  constructor(element, changeDetectorRef, parent, group) {
    super(element, changeDetectorRef, parent, group);
  }
  static {
    this.ɵfac = i0.ɵɵngDeclareFactory({
      minVersion: "12.0.0",
      version: "16.1.1",
      ngImport: i0,
      type: MatLegacyOption,
      deps: [{
        token: i0.ElementRef
      }, {
        token: i0.ChangeDetectorRef
      }, {
        token: MAT_OPTION_PARENT_COMPONENT,
        optional: true
      }, {
        token: MAT_OPTGROUP,
        optional: true
      }],
      target: i0.ɵɵFactoryTarget.Component
    });
  }
  static {
    this.ɵcmp = i0.ɵɵngDeclareComponent({
      minVersion: "14.0.0",
      version: "16.1.1",
      type: MatLegacyOption,
      selector: "mat-option",
      host: {
        attributes: {
          "role": "option"
        },
        listeners: {
          "click": "_selectViaInteraction()",
          "keydown": "_handleKeydown($event)"
        },
        properties: {
          "attr.tabindex": "_getTabIndex()",
          "class.mat-selected": "selected",
          "class.mat-option-multiple": "multiple",
          "class.mat-active": "active",
          "id": "id",
          "attr.aria-selected": "selected",
          "attr.aria-disabled": "disabled.toString()",
          "class.mat-option-disabled": "disabled"
        },
        classAttribute: "mat-option mat-focus-indicator"
      },
      exportAs: ["matOption"],
      usesInheritance: true,
      ngImport: i0,
      template: "<mat-pseudo-checkbox *ngIf=\"multiple\" class=\"mat-option-pseudo-checkbox\"\n    [state]=\"selected ? 'checked' : 'unchecked'\" [disabled]=\"disabled\"></mat-pseudo-checkbox>\n\n<span class=\"mat-option-text\" #text><ng-content></ng-content></span>\n\n<!-- See a11y notes inside optgroup.ts for context behind this element. -->\n<span class=\"cdk-visually-hidden\" *ngIf=\"group && group._inert\">({{ group.label }})</span>\n\n<div class=\"mat-option-ripple\" mat-ripple\n     [matRippleTrigger]=\"_getHostElement()\"\n     [matRippleDisabled]=\"disabled || disableRipple\">\n</div>\n",
      styles: [".mat-option{white-space:nowrap;overflow:hidden;text-overflow:ellipsis;display:block;line-height:48px;height:48px;padding:0 16px;text-align:left;text-decoration:none;max-width:100%;position:relative;cursor:pointer;outline:none;display:flex;flex-direction:row;max-width:100%;box-sizing:border-box;align-items:center;-webkit-tap-highlight-color:rgba(0,0,0,0)}.mat-option[disabled]{cursor:default}[dir=rtl] .mat-option{text-align:right}.mat-option .mat-icon{margin-right:16px;vertical-align:middle}.mat-option .mat-icon svg{vertical-align:top}[dir=rtl] .mat-option .mat-icon{margin-left:16px;margin-right:0}.mat-option[aria-disabled=true]{-webkit-user-select:none;user-select:none;cursor:default}.mat-optgroup .mat-option:not(.mat-option-multiple){padding-left:32px}[dir=rtl] .mat-optgroup .mat-option:not(.mat-option-multiple){padding-left:16px;padding-right:32px}.mat-option.mat-active::before{content:\"\"}.cdk-high-contrast-active .mat-option[aria-disabled=true]{opacity:.5}.cdk-high-contrast-active .mat-option.mat-selected:not(.mat-option-multiple)::after{content:\"\";position:absolute;top:50%;right:16px;transform:translateY(-50%);width:10px;height:0;border-bottom:solid 10px;border-radius:10px}[dir=rtl] .cdk-high-contrast-active .mat-option.mat-selected:not(.mat-option-multiple)::after{right:auto;left:16px}.mat-option-text{display:inline-block;flex-grow:1;overflow:hidden;text-overflow:ellipsis}.mat-option .mat-option-ripple{top:0;left:0;right:0;bottom:0;position:absolute;pointer-events:none}.mat-option-pseudo-checkbox{margin-right:8px}[dir=rtl] .mat-option-pseudo-checkbox{margin-left:8px;margin-right:0}"],
      dependencies: [{
        kind: "directive",
        type: i1.MatRipple,
        selector: "[mat-ripple], [matRipple]",
        inputs: ["matRippleColor", "matRippleUnbounded", "matRippleCentered", "matRippleRadius", "matRippleAnimation", "matRippleDisabled", "matRippleTrigger"],
        exportAs: ["matRipple"]
      }, {
        kind: "directive",
        type: i2.NgIf,
        selector: "[ngIf]",
        inputs: ["ngIf", "ngIfThen", "ngIfElse"]
      }, {
        kind: "component",
        type: i1.MatPseudoCheckbox,
        selector: "mat-pseudo-checkbox",
        inputs: ["state", "disabled", "appearance"]
      }],
      changeDetection: i0.ChangeDetectionStrategy.OnPush,
      encapsulation: i0.ViewEncapsulation.None
    });
  }
}
i0.ɵɵngDeclareClassMetadata({
  minVersion: "12.0.0",
  version: "16.1.1",
  ngImport: i0,
  type: MatLegacyOption,
  decorators: [{
    type: Component,
    args: [{
      selector: 'mat-option',
      exportAs: 'matOption',
      host: {
        'role': 'option',
        '[attr.tabindex]': '_getTabIndex()',
        '[class.mat-selected]': 'selected',
        '[class.mat-option-multiple]': 'multiple',
        '[class.mat-active]': 'active',
        '[id]': 'id',
        '[attr.aria-selected]': 'selected',
        '[attr.aria-disabled]': 'disabled.toString()',
        '[class.mat-option-disabled]': 'disabled',
        '(click)': '_selectViaInteraction()',
        '(keydown)': '_handleKeydown($event)',
        'class': 'mat-option mat-focus-indicator'
      },
      encapsulation: ViewEncapsulation.None,
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: "<mat-pseudo-checkbox *ngIf=\"multiple\" class=\"mat-option-pseudo-checkbox\"\n    [state]=\"selected ? 'checked' : 'unchecked'\" [disabled]=\"disabled\"></mat-pseudo-checkbox>\n\n<span class=\"mat-option-text\" #text><ng-content></ng-content></span>\n\n<!-- See a11y notes inside optgroup.ts for context behind this element. -->\n<span class=\"cdk-visually-hidden\" *ngIf=\"group && group._inert\">({{ group.label }})</span>\n\n<div class=\"mat-option-ripple\" mat-ripple\n     [matRippleTrigger]=\"_getHostElement()\"\n     [matRippleDisabled]=\"disabled || disableRipple\">\n</div>\n",
      styles: [".mat-option{white-space:nowrap;overflow:hidden;text-overflow:ellipsis;display:block;line-height:48px;height:48px;padding:0 16px;text-align:left;text-decoration:none;max-width:100%;position:relative;cursor:pointer;outline:none;display:flex;flex-direction:row;max-width:100%;box-sizing:border-box;align-items:center;-webkit-tap-highlight-color:rgba(0,0,0,0)}.mat-option[disabled]{cursor:default}[dir=rtl] .mat-option{text-align:right}.mat-option .mat-icon{margin-right:16px;vertical-align:middle}.mat-option .mat-icon svg{vertical-align:top}[dir=rtl] .mat-option .mat-icon{margin-left:16px;margin-right:0}.mat-option[aria-disabled=true]{-webkit-user-select:none;user-select:none;cursor:default}.mat-optgroup .mat-option:not(.mat-option-multiple){padding-left:32px}[dir=rtl] .mat-optgroup .mat-option:not(.mat-option-multiple){padding-left:16px;padding-right:32px}.mat-option.mat-active::before{content:\"\"}.cdk-high-contrast-active .mat-option[aria-disabled=true]{opacity:.5}.cdk-high-contrast-active .mat-option.mat-selected:not(.mat-option-multiple)::after{content:\"\";position:absolute;top:50%;right:16px;transform:translateY(-50%);width:10px;height:0;border-bottom:solid 10px;border-radius:10px}[dir=rtl] .cdk-high-contrast-active .mat-option.mat-selected:not(.mat-option-multiple)::after{right:auto;left:16px}.mat-option-text{display:inline-block;flex-grow:1;overflow:hidden;text-overflow:ellipsis}.mat-option .mat-option-ripple{top:0;left:0;right:0;bottom:0;position:absolute;pointer-events:none}.mat-option-pseudo-checkbox{margin-right:8px}[dir=rtl] .mat-option-pseudo-checkbox{margin-left:8px;margin-right:0}"]
    }]
  }],
  ctorParameters: function () {
    return [{
      type: i0.ElementRef
    }, {
      type: i0.ChangeDetectorRef
    }, {
      type: undefined,
      decorators: [{
        type: Optional
      }, {
        type: Inject,
        args: [MAT_OPTION_PARENT_COMPONENT]
      }]
    }, {
      type: MatLegacyOptgroup,
      decorators: [{
        type: Optional
      }, {
        type: Inject,
        args: [MAT_OPTGROUP]
      }]
    }];
  }
});

/**
 * @deprecated Use `MatOptionModule` from `@angular/material/core` instead. See https://material.angular.io/guide/mdc-migration for information about migrating.
 * @breaking-change 17.0.0
 */
class MatLegacyOptionModule {
  static {
    this.ɵfac = i0.ɵɵngDeclareFactory({
      minVersion: "12.0.0",
      version: "16.1.1",
      ngImport: i0,
      type: MatLegacyOptionModule,
      deps: [],
      target: i0.ɵɵFactoryTarget.NgModule
    });
  }
  static {
    this.ɵmod = i0.ɵɵngDeclareNgModule({
      minVersion: "14.0.0",
      version: "16.1.1",
      ngImport: i0,
      type: MatLegacyOptionModule,
      declarations: [MatLegacyOption, MatLegacyOptgroup],
      imports: [MatRippleModule, CommonModule, MatCommonModule, MatPseudoCheckboxModule],
      exports: [MatLegacyOption, MatLegacyOptgroup]
    });
  }
  static {
    this.ɵinj = i0.ɵɵngDeclareInjector({
      minVersion: "12.0.0",
      version: "16.1.1",
      ngImport: i0,
      type: MatLegacyOptionModule,
      imports: [MatRippleModule, CommonModule, MatCommonModule, MatPseudoCheckboxModule]
    });
  }
}
i0.ɵɵngDeclareClassMetadata({
  minVersion: "12.0.0",
  version: "16.1.1",
  ngImport: i0,
  type: MatLegacyOptionModule,
  decorators: [{
    type: NgModule,
    args: [{
      imports: [MatRippleModule, CommonModule, MatCommonModule, MatPseudoCheckboxModule],
      exports: [MatLegacyOption, MatLegacyOptgroup],
      declarations: [MatLegacyOption, MatLegacyOptgroup]
    }]
  }]
});

/**
 * Generated bundle index. Do not edit.
 */

export { MatLegacyOptgroup, MatLegacyOption, MatLegacyOptionModule };
