import { Component, Input, EventEmitter, Output } from '@angular/core';
import { AbstractValueAccessor, MakeProvider } from '../abstract-value-accessor/abstract-value-accessor';
@Component({
  selector: 'price-input',
  templateUrl: './price-input.component.html',
  providers: [
    MakeProvider(PriceInputComponent),
  ],
  styleUrls: ['./price-input.component.css']
})
export class PriceInputComponent extends AbstractValueAccessor {
  @Input() placeholderText: string = '';
  @Output() parsed = new EventEmitter();

  hasError: boolean = false;

  decimalFormatter = new Intl.NumberFormat('en-US', {
    style: 'decimal',
    useGrouping: false,
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  });

  constructor() {
    super()
  }

  get price() {
    return this.decimalFormatter.format(this._value);
  }

  onBlur(value) {
    const pattern = /^\d*\.{0,1}\d+$/g;
    if (pattern.test(value)) {
      this._value = parseFloat(value);
      this.hasError = false;
      this.parsed.emit(this._value);
    } else {
      this.hasError = true;
    }
  }
}
