<div class="content-section">

  <div class="row">
    <mat-form-field class="w100">
      <mat-label>Search by Organization ID / Name / Publisher Code *</mat-label>
      <input matInput [matAutocomplete]="auto" [formControl]="search" id="input-search">
      <mat-autocomplete #auto="matAutocomplete" (optionSelected)="onVendorSelect($event)">
        <mat-option *ngFor="let vendor of filteredVendors$ | async" [value]="vendorTransform(vendor)">
          {{ vendorTransform(vendor) }}
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>
  </div>

  <div class="row between">
    <mat-form-field>
      <mat-label>Organization ID</mat-label>
      <input matInput [(ngModel)]="organizationId" [readonly]="true" id="input-org_id">
    </mat-form-field>
    <mat-form-field class="org-name">
      <mat-label>Organization Name</mat-label>
      <input matInput [(ngModel)]="organizationName" [readonly]="true" id="input-org_name">
    </mat-form-field>
    <mat-form-field>
      <mat-label>Publisher Code</mat-label>
      <input matInput [(ngModel)]="publisherCode" [readonly]="true" id="input-pub_code">
    </mat-form-field>
  </div>

</div>
