import { Component } from '@angular/core';

@Component({
  selector: 'retired-css-invoice-remit-info',
  template: `
    <span>ACH or Wire Transfer:</span>
    <hr>
    <span>
      Account Name: Techstreet LLC<br>Bank Account #: 4251617<br>ABA/Routing#: 071000288<br>SWIFT: HATRUS44<br>IBAN#: N/A<br>Tax ID#: 86-1260328<br>Bank Name: BMO Harris Bank N.A.<br>Bank Address: 111 West Monroe Street<br>Chicago, Illinois 60603<br>Bank Phone: 877-895-3278<br>Currency: USD
    </span>
    <br/><br/>
  `,
})
export class RetiredCssInvoiceRemitComponent {}
