import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-title',
  templateUrl: './title.component.html',
  styleUrls: ['./title.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TitleComponent {

  @Input() title: string = null;
  @Input() smallTitle: string = null;
  @Input() buttonCaption: string;
  @Input() disableCreateButton = false;
  @Input() disabledButtonTooltip = null;
  @Output() create = new EventEmitter<void>();

  onCreate() {
    this.create.emit();
  }

  get btnId(): string {
    return `btn-${this.buttonCaption.toLowerCase().replace(/ /g, '_')}`;
  }

}
