import { Injectable } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { Action, State, StateContext } from '@ngxs/store';
import {
  ISubVendor,
  IZipCodeDetails,
  VendorListItem,
  INote,
  IRestriction,
  IPriceCode,
  IMultiUserPricing,
  IMultiUserException,
  IProductSearchItem,
  IVendor,
} from 'app/shared/interfaces';
import { MContact } from 'app/shared/models';
import { catchError, tap } from 'rxjs/operators';
import { AlertService, ErrorHandlerService, NavigationService, VendorsService } from '../services';
import { HTTP_MESSAGES } from 'app/shared/constants';
import { combineLatest, of } from 'rxjs';
import * as actions from './action-namespaces/vendors';

export interface VendorsState {
  vendors: VendorListItem[];
  myUnpublishedCount: number;
  unpublishedCount: number;
  vendor: IVendor;
  contacts: MContact[];
  zipCodeDetails: IZipCodeDetails,
  subVendors: ISubVendor[];
  notes: INote[];
  restrictions: IRestriction[];
  priceCodes: IPriceCode[];
  multiUserPricings: IMultiUserPricing[];
  multiUserExceptions: IMultiUserException[];
  productSearch: IProductSearchItem;
  loading: boolean;
  disableCreateButton: boolean;
}

@State<VendorsState>({
  name: 'vendors',
  defaults: {
    vendors: [],
    unpublishedCount: null,
    myUnpublishedCount: null,
    vendor: null,
    contacts: [],
    zipCodeDetails: null,
    subVendors: [],
    notes: [],
    restrictions: [],
    priceCodes: [],
    multiUserPricings: [],
    multiUserExceptions: [],
    productSearch: null,
    loading: false,
    disableCreateButton: false,
  }
})
@Injectable()
export class VendorsStore {
  constructor(
    private readonly vendorsService: VendorsService,
    private readonly alertService: AlertService,
    private readonly navigationService: NavigationService,
    private readonly dialog: MatDialog,
    private readonly errorHandlerService: ErrorHandlerService,
  ) { }

  @Action(actions.Vendor.GetAll)
  getVendors(ctx: StateContext<VendorsState>) {
    ctx.patchState({ loading: true });
    return this.vendorsService.getVendors().pipe(
      tap(response => ctx.patchState({
        vendors: response.organizations,
        myUnpublishedCount: response.my_unpublished_count,
        unpublishedCount: response.unpublished_count,
        loading: false,
      })),
      catchError(e => this.errorHandlerService.handleError(HTTP_MESSAGES.generalError, e, ctx)),
    );
  }

  @Action(actions.Vendor.Create)
  createVendor(ctx: StateContext<VendorsState>, action: actions.Vendor.Create) {
    ctx.patchState({ loading: true });
    return this.vendorsService.createVendor(action.payload).pipe(
      tap(id => {
        this.dialog.closeAll();
        this.alertService.showAlert({
          message: 'Vendor successfully created',
          type: 'SUCCESS',
        });
        ctx.patchState({ loading: false });
        this.navigationService.navigate([`vendors/${id}`]);
      }),
      catchError(e => this.errorHandlerService.handleError('Unable to create new Vendor', e, ctx)),
    );
  }

  @Action(actions.Vendor.GetOne)
  getVendor(ctx: StateContext<VendorsState>, action: actions.Vendor.GetOne) {
    ctx.patchState({ loading: true });
    return this.vendorsService.getVendor(action.payload).pipe(
      tap(vendor => ctx.patchState({ vendor, loading: false })),
      catchError(e => this.errorHandlerService.handleError(HTTP_MESSAGES.generalError, e, ctx)),
    );
  }

  @Action(actions.Vendor.Update)
  updateVendor(ctx: StateContext<VendorsState>, action: actions.Vendor.Update) {
    ctx.patchState({ loading: true });
    return this.vendorsService.updateVendor(action.payload).pipe(
      tap(vendor => {
        ctx.patchState({ vendor, loading: false });
        this.alertService.showAlert({
          message: HTTP_MESSAGES.updateSuccess,
          type: 'SUCCESS',
        });
      }),
      catchError(e => this.errorHandlerService.handleError(HTTP_MESSAGES.updateFail, e, ctx)),
    );
  }

  @Action(actions.Vendor.DisplayRestrict)
  displayRestrict(ctx: StateContext<VendorsState>, action: actions.Vendor.DisplayRestrict) {
    ctx.patchState({ loading: true });
    return this.vendorsService.displayRestrict(action.payload).pipe(
      tap(() => {
        ctx.patchState({ loading: false });
        this.alertService.showAlert({
          message: HTTP_MESSAGES.generalSuccess,
          type: 'SUCCESS',
        });
      }),
      catchError(e => this.errorHandlerService.handleError(HTTP_MESSAGES.updateFail, e, ctx)),
    );
  }

  @Action(actions.Vendor.Publish)
  publishProduct(ctx: StateContext<VendorsState>, action: actions.Vendor.Publish) {
    const { type, id, refetchVendors } = action.payload;
    const vendor = ctx.getState().vendor;
    return this.vendorsService.publishVendors(type, id).pipe(
      tap(message => {
        this.dialog.closeAll();
        this.alertService.showAlert({ message, type: message === 'Success' ? 'SUCCESS' : 'ERROR' });
        if (message === 'Success' && id) {
          ctx.patchState({
            vendor: vendor ? { ...vendor, needs_sync: false } : null,
          })
        }
        if (refetchVendors) ctx.dispatch(new actions.Vendor.GetAll);
      }),
      catchError(e => this.errorHandlerService.handleError(HTTP_MESSAGES.generalError, e, ctx)),
    );
  };

  @Action(actions.Contact.GetAll)
  getContacts(ctx: StateContext<VendorsState>, action: actions.Contact.GetAll) {
    ctx.patchState({ loading: true, contacts: [] });
    return this.vendorsService.getContacts(action.payload).pipe(
      tap(contacts => ctx.patchState({ contacts, loading: false })),
      catchError(e => this.errorHandlerService.handleError(HTTP_MESSAGES.generalError, e, ctx)),
    );
  }

  @Action(actions.Contact.Create)
  createContact(ctx: StateContext<VendorsState>, action: actions.Contact.Create) {
    ctx.patchState({ loading: true });
    const vendorId = ctx.getState().vendor.vendor_id;
    return this.vendorsService.createContact(vendorId, action.payload).pipe(
      tap(() => {
        this.dialog.closeAll();
        this.alertService.showAlert({
          message: 'Contact successfully created',
          type: 'SUCCESS',
        });
        return ctx.dispatch(new actions.Contact.GetAll(vendorId));
      }),
      catchError(e => this.errorHandlerService.handleError('Unable to create new Contact', e, ctx)),
    );
  }

  @Action(actions.Contact.Update)
  updateContact(ctx: StateContext<VendorsState>, action: actions.Contact.Update) {
    ctx.patchState({ loading: true });
    const vendorId = ctx.getState().vendor.vendor_id;
    return this.vendorsService.updateContact(vendorId, action.payload).pipe(
      tap(() => {
        this.dialog.closeAll();
        this.alertService.showAlert({
          message: HTTP_MESSAGES.updateSuccess,
          type: 'SUCCESS',
        });
        return ctx.dispatch(new actions.Contact.GetAll(vendorId));
      }),
      catchError(e => this.errorHandlerService.handleError(HTTP_MESSAGES.updateFail, e, ctx)),
    );
  }

  @Action(actions.GeneralPurpose.GetZipCodeDetails)
  getZipCodeDetails(ctx: StateContext<VendorsState>, action: actions.GeneralPurpose.GetZipCodeDetails) {
    ctx.patchState({ loading: true });
    return this.vendorsService.getDataFromZipCode(action.payload).pipe(
      tap(data => ctx.patchState({ zipCodeDetails: data, loading: false })),
      catchError(e => this.errorHandlerService.handleError('Zip Code not found', e, ctx)),
    );
  }

  @Action(actions.GeneralPurpose.ResetVendorsField)
  resetField(ctx: StateContext<VendorsState>, action: actions.GeneralPurpose.ResetVendorsField) {
    const { name, value } = action.payload;
    ctx.patchState({ [name]: value });
  }

  @Action(actions.SubVendor.GetAll)
  getSubVendors(ctx: StateContext<VendorsState>, action: actions.SubVendor.GetAll) {
    ctx.patchState({ loading: true });
    return this.vendorsService.getSubVendors(action.payload).pipe(
      tap(subVendors => ctx.patchState({ subVendors, loading: false })),
      catchError(e => this.errorHandlerService.handleError(HTTP_MESSAGES.generalError, e, ctx)),
    );
  }

  @Action(actions.SubVendor.Create)
  createSubVendor(ctx: StateContext<VendorsState>, action: actions.SubVendor.Create) {
    ctx.patchState({ loading: true });
    const vendorId = ctx.getState().vendor.vendor_id;
    return this.vendorsService.createSubVendor(vendorId, action.payload).pipe(
      tap(() => {
        this.dialog.closeAll();
        this.alertService.showAlert({
          message: HTTP_MESSAGES.createSuccess,
          type: 'SUCCESS',
        });
        return ctx.dispatch(new actions.SubVendor.GetAll(vendorId));
      }),
      catchError(e => this.errorHandlerService.handleError(HTTP_MESSAGES.createFail, e, ctx)),
    );
  }

  @Action(actions.SubVendor.AddExisting)
  addExistingSubVendor(ctx: StateContext<VendorsState>, action: actions.SubVendor.AddExisting) {
    const vendorId = ctx.getState().vendor.vendor_id;
    return this.vendorsService.addExistingSubVendor(vendorId, action.payload).pipe(
      tap(() => {
        this.dialog.closeAll();
        this.alertService.showAlert({
          message: HTTP_MESSAGES.createSuccess,
          type: 'SUCCESS',
        });
        return ctx.dispatch(new actions.SubVendor.GetAll(vendorId));
      }),
      catchError(e => this.errorHandlerService.handleError(HTTP_MESSAGES.createFail, e, ctx)),
    );
  }

  @Action(actions.SubVendor.Update)
  updateSubVendor(ctx: StateContext<VendorsState>, action: actions.SubVendor.Update) {
    ctx.patchState({ loading: true });
    const vendorId = ctx.getState().vendor.vendor_id;
    return this.vendorsService.updateSubVendor(vendorId, action.payload).pipe(
      tap(() => {
        this.dialog.closeAll();
        this.alertService.showAlert({
          message: HTTP_MESSAGES.updateSuccess,
          type: 'SUCCESS',
        });
        return ctx.dispatch(new actions.SubVendor.GetAll(vendorId));
      }),
      catchError(e => this.errorHandlerService.handleError(HTTP_MESSAGES.updateFail, e, ctx)),
    );
  }

  @Action(actions.SubVendor.Delete)
  deleteSubVendor(ctx: StateContext<VendorsState>, action: actions.SubVendor.Delete) {
    ctx.patchState({ loading: true });
    const vendorId = ctx.getState().vendor.vendor_id;
    return this.vendorsService.deleteSubVendor(vendorId, action.payload).pipe(
      tap(() => {
        this.dialog.closeAll();
        this.alertService.showAlert({
          message: HTTP_MESSAGES.deleteSuccess,
          type: 'SUCCESS',
        });
        return ctx.dispatch(new actions.SubVendor.GetAll(vendorId));
      }),
      catchError(e => this.errorHandlerService.handleError(HTTP_MESSAGES.deleteFail, e, ctx)),
    );
  }

  @Action(actions.Restriction.GetAll)
  getRestrictions(ctx: StateContext<VendorsState>) {
    ctx.patchState({ loading: true, restrictions: [], disableCreateButton: false });
    const vendorId = ctx.getState().vendor.vendor_id;
    return this.vendorsService.getRestrictions(vendorId).pipe(
      tap(restrictions => ctx.patchState({ restrictions, loading: false })),
      catchError(e => {
        if (e?.error?.message === 'no_content') {
          ctx.patchState({ loading: false, disableCreateButton: true });
          return of(true);
        }
        return this.errorHandlerService.handleError(HTTP_MESSAGES.generalError, e, ctx);
      }),
    );
  }

  @Action(actions.Restriction.Create)
  createRestriction(ctx: StateContext<VendorsState>, action: actions.Restriction.Create) {
    ctx.patchState({ loading: true });
    const vendorId = ctx.getState().vendor.vendor_id;
    return this.vendorsService.createRestriction(vendorId, action.payload).pipe(
      tap(() => {
        this.dialog.closeAll();
        this.alertService.showAlert({
          message: HTTP_MESSAGES.createSuccess,
          type: 'SUCCESS',
        });
        return ctx.dispatch(new actions.Restriction.GetAll());
      }),
      catchError(e => this.errorHandlerService.handleError(HTTP_MESSAGES.createFail, e, ctx)),
    );
  }

  @Action(actions.Restriction.Update)
  updateRestriction(ctx: StateContext<VendorsState>, action: actions.Restriction.Update) {
    ctx.patchState({ loading: true });
    const vendorId = ctx.getState().vendor.vendor_id;
    return this.vendorsService.updateRestriction(vendorId, action.payload).pipe(
      tap(() => {
        this.dialog.closeAll();
        this.alertService.showAlert({
          message: HTTP_MESSAGES.updateSuccess,
          type: 'SUCCESS',
        });
        return ctx.dispatch(new actions.Restriction.GetAll());
      }),
      catchError(e => this.errorHandlerService.handleError(HTTP_MESSAGES.updateFail, e, ctx)),
    );
  }

  @Action(actions.Restriction.Delete)
  deleteRestriction(ctx: StateContext<VendorsState>, action: actions.Restriction.Delete) {
    ctx.patchState({ loading: true });
    const vendorId = ctx.getState().vendor.vendor_id;
    return this.vendorsService.deleteRestriction(vendorId, action.payload).pipe(
      tap(() => {
        this.dialog.closeAll();
        this.alertService.showAlert({
          message: HTTP_MESSAGES.deleteSuccess,
          type: 'SUCCESS',
        });
        return ctx.dispatch(new actions.Restriction.GetAll());
      }),
      catchError(e => this.errorHandlerService.handleError(HTTP_MESSAGES.deleteFail, e, ctx)),
    );
  }

  @Action(actions.PriceCode.GetAll)
  getPriceCodes(ctx: StateContext<VendorsState>) {
    ctx.patchState({ loading: true, priceCodes: [], disableCreateButton: false });
    const vendorId = ctx.getState().vendor.vendor_id;
    return this.vendorsService.getPriceCodes(vendorId).pipe(
      tap(priceCodes => ctx.patchState({ priceCodes, loading: false })),
      catchError(e => {
        if (e?.error?.message === 'no_content') {
          ctx.patchState({ loading: false, disableCreateButton: true });
          return of(true);
        }
        return this.errorHandlerService.handleError(HTTP_MESSAGES.generalError, e, ctx);
      }),
    );
  }

  @Action(actions.PriceCode.Create)
  createPriceCode(ctx: StateContext<VendorsState>, action: actions.PriceCode.Create) {
    ctx.patchState({ loading: true });
    const vendorId = ctx.getState().vendor.vendor_id;
    return this.vendorsService.createPriceCode(vendorId, action.payload).pipe(
      tap(() => {
        this.dialog.closeAll();
        this.alertService.showAlert({
          message: HTTP_MESSAGES.createSuccess,
          type: 'SUCCESS',
        });
        return ctx.dispatch(new actions.PriceCode.GetAll());
      }),
      catchError(e => this.errorHandlerService.handleError(HTTP_MESSAGES.createFail, e, ctx)),
    );
  }

  @Action(actions.PriceCode.Update)
  updatePriceCode(ctx: StateContext<VendorsState>, action: actions.PriceCode.Update) {
    ctx.patchState({ loading: true });
    const vendorId = ctx.getState().vendor.vendor_id;
    return this.vendorsService.updatePriceCode(vendorId, action.payload).pipe(
      tap(() => {
        this.dialog.closeAll();
        this.alertService.showAlert({
          message: HTTP_MESSAGES.updateSuccess,
          type: 'SUCCESS',
        });
        return ctx.dispatch(new actions.PriceCode.GetAll());
      }),
      catchError(e => this.errorHandlerService.handleError(HTTP_MESSAGES.updateFail, e, ctx)),
    );
  }

  @Action(actions.PriceCode.Delete)
  deletePriceCode(ctx: StateContext<VendorsState>, action: actions.PriceCode.Delete) {
    ctx.patchState({ loading: true });
    const vendorId = ctx.getState().vendor.vendor_id;
    return this.vendorsService.deletePriceCode(vendorId, action.payload).pipe(
      tap(() => {
        this.dialog.closeAll();
        this.alertService.showAlert({
          message: HTTP_MESSAGES.deleteSuccess,
          type: 'SUCCESS',
        });
        return ctx.dispatch(new actions.PriceCode.GetAll());
      }),
      catchError(e => this.errorHandlerService.handleError(HTTP_MESSAGES.deleteFail, e, ctx)),
    );
  }

  @Action(actions.MultiUser.GetDetails)
  getMultiUserDetails(ctx: StateContext<VendorsState>) {
    ctx.patchState({
      loading: true,
      disableCreateButton: false,
      multiUserPricings: [],
      multiUserExceptions: [],
    });
    const vendorId = ctx.getState().vendor.vendor_id;
    return combineLatest([
      this.vendorsService.getMultiUserPricings(vendorId),
      this.vendorsService.getMultiUserExceptions(vendorId),
    ]).pipe(
      tap(([multiUserPricings, multiUserExceptions]) => ctx.patchState({ multiUserPricings, multiUserExceptions, loading: false })),
      catchError(e => {
        if (e?.error?.message === 'no_content') {
          ctx.patchState({ loading: false, disableCreateButton: true });
          return of(true);
        }
        return this.errorHandlerService.handleError(HTTP_MESSAGES.generalError, e, ctx)
      }),
    );
  }

  @Action(actions.MultiUser.CreatePricing)
  createMultiUserPricing(ctx: StateContext<VendorsState>, action: actions.MultiUser.CreatePricing) {
    ctx.patchState({ loading: true });
    const vendorId = ctx.getState().vendor.vendor_id;
    return this.vendorsService.createMultiUserPricing(vendorId, action.payload).pipe(
      tap(() => {
        this.dialog.closeAll();
        this.alertService.showAlert({
          message: HTTP_MESSAGES.createSuccess,
          type: 'SUCCESS',
        });
        return ctx.dispatch(new actions.MultiUser.GetDetails());
      }),
      catchError(e => {
        let preparedError = e;
        if (e?.error?.message[0]) {
          preparedError = {
            error: {
              message: (e?.error?.message[0] as string).replace(`can't be blank`, 'ID not found'),
            },
          };
        }
        return this.errorHandlerService.handleError(HTTP_MESSAGES.createFail, preparedError, ctx);
      }),
    );
  }

  @Action(actions.MultiUser.UpdatePricing)
  updateMultiUserPricing(ctx: StateContext<VendorsState>, action: actions.MultiUser.UpdatePricing) {
    ctx.patchState({ loading: true });
    const vendorId = ctx.getState().vendor.vendor_id;
    return this.vendorsService.updateMultiUserPricing(vendorId, action.payload).pipe(
      tap(() => {
        this.dialog.closeAll();
        this.alertService.showAlert({
          message: HTTP_MESSAGES.updateSuccess,
          type: 'SUCCESS',
        });
        return ctx.dispatch(new actions.MultiUser.GetDetails());
      }),
      catchError(e => this.errorHandlerService.handleError(HTTP_MESSAGES.updateFail, e, ctx)),
    );
  }

  @Action(actions.MultiUser.DeletePricing)
  deleteMultiUserPricing(ctx: StateContext<VendorsState>, action: actions.MultiUser.DeletePricing) {
    ctx.patchState({ loading: true });
    const vendorId = ctx.getState().vendor.vendor_id;
    return this.vendorsService.deleteMultiUserPricing(vendorId, action.payload).pipe(
      tap(() => {
        this.dialog.closeAll();
        this.alertService.showAlert({
          message: HTTP_MESSAGES.deleteSuccess,
          type: 'SUCCESS',
        });
        return ctx.dispatch(new actions.MultiUser.GetDetails());
      }),
      catchError(e => this.errorHandlerService.handleError(HTTP_MESSAGES.deleteFail, e, ctx)),
    );
  }

  @Action(actions.MultiUser.CreateException)
  createMultiUserException(ctx: StateContext<VendorsState>, action: actions.MultiUser.CreateException) {
    ctx.patchState({ loading: true });
    const vendorId = ctx.getState().vendor.vendor_id;
    return this.vendorsService.createMultiUserException(vendorId, action.payload).pipe(
      tap(() => {
        this.dialog.closeAll();
        this.alertService.showAlert({
          message: HTTP_MESSAGES.createSuccess,
          type: 'SUCCESS',
        });
        return ctx.dispatch(new actions.MultiUser.GetDetails());
      }),
      catchError(e => this.errorHandlerService.handleError(HTTP_MESSAGES.createFail, e, ctx)),
    );
  }

  @Action(actions.MultiUser.UpdateException)
  updateMultiUserException(ctx: StateContext<VendorsState>, action: actions.MultiUser.UpdateException) {
    ctx.patchState({ loading: true });
    const vendorId = ctx.getState().vendor.vendor_id;
    return this.vendorsService.updateMultiUserException(vendorId, action.payload).pipe(
      tap(() => {
        this.dialog.closeAll();
        this.alertService.showAlert({
          message: HTTP_MESSAGES.updateSuccess,
          type: 'SUCCESS',
        });
        return ctx.dispatch(new actions.MultiUser.GetDetails());
      }),
      catchError(e => this.errorHandlerService.handleError(HTTP_MESSAGES.updateFail, e, ctx)),
    );
  }

  @Action(actions.MultiUser.DeleteException)
  deleteMultiUserException(ctx: StateContext<VendorsState>, action: actions.MultiUser.DeleteException) {
    ctx.patchState({ loading: true });
    const vendorId = ctx.getState().vendor.vendor_id;
    return this.vendorsService.deleteMultiUserException(vendorId, action.payload).pipe(
      tap(() => {
        this.dialog.closeAll();
        this.alertService.showAlert({
          message: HTTP_MESSAGES.deleteSuccess,
          type: 'SUCCESS',
        });
        return ctx.dispatch(new actions.MultiUser.GetDetails());
      }),
      catchError(e => this.errorHandlerService.handleError(HTTP_MESSAGES.deleteFail, e, ctx)),
    );
  }

  @Action(actions.GeneralPurpose.FindProduct)
  findProduct(ctx: StateContext<VendorsState>, action: actions.GeneralPurpose.FindProduct) {
    ctx.patchState({ productSearch: null });
    const vendorId = ctx.getState().vendor.vendor_id;
    return this.vendorsService.findProduct(vendorId, action.payload).pipe(
      tap(productSearch => ctx.patchState({ productSearch })),
      catchError(e => this.errorHandlerService.handleError(HTTP_MESSAGES.generalError, e, ctx)),
    );
  }

}
