import { NoteTypes } from "app/shared/enums";
import { INote } from "app/shared/interfaces";

export namespace Notes {
  export class GetAll {
    static readonly type = '[Notes] Get Notes';
    constructor(public payload: { noteType: NoteTypes, id: string }) { }
  }

  export class Create {
    static readonly type = '[Notes] Create Note';
    constructor(public payload: INote) { }
  }
}
