<mat-card class='multiadd_card_width'>
  <h4 class='align_center'>Address Verification</h4>
  <p>Please review the verified address and select "Save" to continue or "Edit Address" to update the submitted address.
  </p>
  
  <div class='address_container'>
    <div class="left_container">
      <h4>Entered Address</h4>
      <p>{{entered_address['line_1']}}</p>
      <p>{{entered_address['line_2']}}</p>
      <p>{{entered_address['line_3']}}</p>
      <p>{{entered_address['city']}}</p>
      <p>{{entered_address['state_province']}} {{entered_address['postal_code']}} <span *ngIf="entered_address['zip_code_extension']">- {{entered_address['zip_code_extension']}}</span></p>
      <p>{{entered_address['country_code']}} </p>
    </div>

    <div class='right_container'>
      <h4>Verified Address</h4>
      <p>{{valid_addresses[0]['line_1']}}</p>
      <p>{{valid_addresses[0]['line_2']}}</p>
      <p>{{valid_addresses[0]['line_3']}}</p>
      <p>{{valid_addresses[0]['city']}}</p>
      <p>{{valid_addresses[0]['state_province']}} {{valid_addresses[0]['postal_code']}} <span *ngIf="valid_addresses[0]['zip_code_extension']"> - {{valid_addresses[0]['zip_code_extension']}}</span></p>
      <p>{{valid_addresses[0]['country_code']}} </p>
    </div>
  </div>
  <div class='align_center'>
    <button (click)='cancel()' type="button" mat-raised-button color="secondary">Edit Address</button>
    <button (click)='saveValidatedAddress()' type="button" mat-raised-button color="primary">Save Validated Address</button>
    <button (click)='saveOriginalAddress()' type="button" mat-raised-button color="accent">Save Original Address</button>
  </div>
</mat-card>
