<div *ngIf="showUnpublishedWarning()" class="unpublished-warning">
  <mat-icon class="material-symbols-outlined">info</mat-icon>
  <span class="message">
    The 'Add to Cart' button has been disabled due to a required publish action. Once item has been published, the 'Add to Cart' button will enable.
  </span>
</div>

<h2>
  {{ product.to_string}}
</h2>
<p *ngIf="product.to_string !== product.title">{{ product.title }}</p>
<mat-tab-group>
  <mat-tab *ngFor="let l of product.languages">
    <ng-template mat-tab-label>{{l}}</ng-template>
    <form novalidate [formGroup]="addToCartForm"
          (ngSubmit)="onSubmit($event) ">
      <table>
        <thead>
          <tr>
            <th>Available Formats</th>
            <th><span class="no-wrap">PRDD ID</span></th>
            <th>Quantity</th>
            <th>Availability</th>
            <th>Price <span class="no-wrap">(in {{shopping_currency()}})</span></th>
            <th>&nbsp;</th>

          </tr>
        </thead>
        <tbody>
        <tr *ngFor="let pi of product.items_by_language(l)">

          <td matTooltip="{{pi.format}}" Position="left">{{ pi.format_description() }}<span *ngIf="pi.redline"> (Redline)</span></td>
          <td>{{ pi.id }}</td>
          <td>
            <span *ngIf="pi.downloadable === false">
              <mat-form-field>
                <input formControlName="quantity_{{pi.id}}" matInput placeholder="Quantity"/>
                <mat-error *ngIf="addToCartForm.controls['quantity_'+pi.id].hasError('pattern')">
                  This field must be a number
                </mat-error>
                <mat-error *ngIf="addToCartForm.controls['quantity_'+pi.id].hasError('required')">
                  {{ null | required_errormsg }}
                </mat-error>
                <mat-error *ngIf="addToCartForm.controls['quantity_'+pi.id].hasError('min')">
                  {{ null | min_errormsg }}
                </mat-error>
              </mat-form-field>
            </span>
            <span *ngIf="pi.format_code === 'PLUS' || (pi.downloadable === true && pi.multi_user_quantities < 2)">
              <mat-form-field>
                <input matInput formControlName="quantity_{{pi.id}}" placeholder="Quantity"/>
              </mat-form-field>
            </span>
            <span *ngIf="pi.downloadable === true && pi.multi_user_quantities.length > 1">
              <mat-form-field>
                <mat-select formControlName="quantity_{{pi.id}}">
                  <mat-option *ngFor="let q of pi.multi_user_quantities" value="{{q}}">
                    {{q | number}} at {{orderModel.getCurrency(price_for_quantity(pi, q)*q ,shoppingService)}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </span>
          </td>
          <td>{{ pi.availability |capitalize }}</td>
          <td>{{orderModel.getCurrency(pi.price,shoppingService)}}</td>
          <td>
              <button
                *ngIf="canAddItem(pi)"
                (click)="submitted(pi.id)"
                [disabled]="addToCartForm.controls['quantity_'+pi.id].invalid || pi.needs_sync"
                mat-raised-button id={{pi.id}}
                color='primary'
                type="submit"
              >Add to {{addToDestination()}}</button>
          </td>
        </tr>
        </tbody>
      </table>
    </form>

  </mat-tab>
</mat-tab-group>
