import { IOrdersOnHoldRequest } from '../interfaces';

export const INITIAL_ORDERS_ON_HOLD_REQUEST: IOrdersOnHoldRequest = {
  items_per_page: 10,
  page: 1,
  query: '',
  sort_direction: 'desc',
  sort_key: 'order_date',
  order_hold_statuses: [],
};
