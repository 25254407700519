import { Mappable } from './mappable';
import { Product } from './product';
import { EntitlementContent } from './entitlement_content';

export class Entitlement extends Mappable {
  id: number = 0;
  assignee_id: number;
  purchaser_id: number;
  order_id: number;
  invoice_id: number;
  status: string;
  requires_security: boolean;
  accepted_eula: boolean
  contents: EntitlementContent[];
  product: Product;
  purchaser: string;
  assignee: string;
  format: string;
  downloadable: boolean;
  assignee_email: string; // 1/2/2017
  constructor() { super() }
}
