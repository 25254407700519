import { Injectable, NgZone } from '@angular/core';
import { from, Observable } from 'rxjs';
import { NavigationExtras, Router } from '@angular/router';

@Injectable({ providedIn: 'root' })
export class NavigationService {

  constructor(
    private readonly router: Router,
    private zone: NgZone,
  ) { }

  navigate(commands: any[], extras?: NavigationExtras): Observable<boolean> {
    return from(this.zone.run(() => this.router.navigate(commands, extras)));
  }
}
