import { Component, Input } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { EntitlementService } from 'app/_services/entitlement.service';
import { EventEmitter } from '@angular/core';
import { Entitlement } from '../../_models/entitlement';
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';

@Component({
  selector: 'app-reset-assignee',
  templateUrl: './reset-assignee.component.html',
  styleUrls: ['./reset-assignee.component.css']
})
export class ResetAssigneeComponent {
  @Input() dialogRef: MatDialogRef<any>;
  @Input() assignee_email: string;
  @Input() entitlement_id: number ;

  onReassigned = new EventEmitter<Entitlement>();

  resetAssigneeForm: UntypedFormGroup;

  constructor(private entitlementService: EntitlementService, private fb: UntypedFormBuilder) {
    this.resetAssigneeForm = this.fb.group({ // create form
      assignee_email: ['', [Validators.required, Validators.email]], // The assignee_email field will Never be pre-populated
    });
  }

  save() {
    if (this.resetAssigneeForm.valid) {
      const newEmail = this.resetAssigneeForm.controls['assignee_email'].value;
      this.entitlementService.updateAssigneeEmail(this.entitlement_id, this.assignee_email).subscribe(
        (success: Entitlement) => {
          this.onReassigned.emit(success);
        },
        error => {
          alert('Error on save: ' + error);
        }
      );
    }
  }

}
