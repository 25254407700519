import { ISSOIssuesRequest, ISubscriptionParams } from '../interfaces';

export const SUBSCRIPTION_PARAMS_PLACEHOLDER: ISubscriptionParams = {
  login_attempt_time: false,
  xml_response: false,
  email: false,
  first_name: false,
  last_name: false,
  custom: false,
  identity_provider_id: false,
  org_identifier: false,
  subscription: false,
  user: false,
}

export const SSO_ISSUE_REQUEST_PLACEHOLDER: ISSOIssuesRequest = {
  subscription: null,
  email: null,
  message_type: null,
  message: null,
  status: null,
  location: null,
  pagination: {
    page: 1,
    items_per_page: 10,
    sort_direction: 'desc',
    sort_key: 'attempt_time',
  }
}
