import { Mappable } from './mappable';
import { ProductItem } from './product_item';

export class Product extends Mappable {
  id: number = 0;
  doc_no: string;
  title: string;
  keywords: string;
  withdrawn: boolean;
  type: string;
  publisher_code: string;
  publisher_id: number;
  to_string: string;
  amendment: boolean;
  ansi_approved: boolean;
  author: string;
  isbn: string;
  isbn13: string;
  edition: string;
  edition_date: Date;
  repapproval_date: Date;
  image_file_url: string;
  description: string;
  scope: string;
  purpose: string;
  preview_pdf: string;
  preorder: string;

  items: ProductItem[] = [];
  languages: string[] = [];
  items_by_language(language) {
    return this.items
      .filter( (i) => i.language === language && i.format_code !== 'RPDF' )
      .sort((a, b) => {
        if (a.format_description() === b.format_description()) {
          return a.price < b.price ? -1 : 1;
        } else {
          return a.format_description() < b.format_description() ? -1 :1;
        };
      });
  }

  constructor() { super() };
}
