<div>
  <p>Payment for this order is done by Wire Transfer.</p>
  <p>Please confirm if the Wire Transfer amount has been received.</p>
  <p>OR</p>
  <p>To keep the order in Quote status click "Cancel" button.</p>
</div>

<div style='text-align: center;margin-top:15%'>
  <button mat-raised-button color="accent" [mat-dialog-close]="false">Cancel</button>
  <button mat-raised-button color="primary" (click)='confirmSaveOrder()'>Confirm</button>
</div>
