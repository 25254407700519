import { Component } from '@angular/core';

@Component({
  selector: 'retired-invoice-css-check-info',
  template: `
    <span>Check:</span>
    <hr>
    <span>
      Techstreet LLC<br>P.O. Box 95715<br>Chicago, IL 60694-5715<br>Currency: USD
    </span>
    <br/><br/>
  `,
})

export class RetiredInvoiceCssCheckComponent {}
