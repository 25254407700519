import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ISSOComment, ISSOIssue, ISSOIssuesRequest, ISSOIssuesList, ISubscription, ISubscriptionSettings } from 'app/shared/interfaces';
import { environment } from 'environments/environment';
import { Observable, of } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class SSOIssuesService {

  readonly baseUrl = environment.neptune_api;
  readonly url = `${this.baseUrl}/apis/v1/private`;

  constructor(
    private http: HttpClient,
  ) { }

  getSubscriptions(): Observable<ISubscription[]> {
    return this.http.get<ISubscription[]>(`${this.url}/sso_logs/subscriptions_list`);
  }

  getIssues(data: ISSOIssuesRequest): Observable<ISSOIssuesList> {
    return this.http.post<ISSOIssuesList>(`${this.url}/sso_logs`, { sso_log: data });
  }

  updateStatus(data: Partial<ISSOIssue>): Observable<Partial<ISSOIssue>> {
    return this.http.put<Partial<ISSOIssue>>(`${this.url}/sso_logs/update_status`, { sso_log: data });
  }

  addComment(data: Partial<ISSOComment>): Observable<ISSOComment> {
    return this.http.post<ISSOComment>(`${this.url}/sso_log_comments`, { sso_log_comment: data });
  }

  updateSubscriptionSettings(data: ISubscriptionSettings): Observable<ISubscriptionSettings> {
    return this.http.post<ISubscriptionSettings>(`${this.url}/subscription_settings`, { sub_setting: data });
  }

  getSubscriptionSettings(id: number): Observable<ISubscriptionSettings> {
    return this.http.post<ISubscriptionSettings>(`${this.url}/parse_subscription_settings`, { sub_id: id });
  }

}
