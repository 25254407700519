import { IBundle, IProductGateway, IProductEvent, IProductItemPDF, IProductNote, IRelation } from '../interfaces';

export const PRODUCT_NOTE_PLACEHOLDER: IProductNote = {
  id: null,
  note: null,
  type: null,
};

export const BUNDLE_PLACEHOLDER: IBundle = {
  id: null,
  electronic_prdd_id: null,
  plus_prdd_id: null,
  print_prdd_id: null,
};

export const EVENT_PLACEHOLDER: IProductEvent = {
  id: null,
  date: null,
  note: null,
  type: null,
};

export const RELATION_PLACEHOLDER: IRelation = {
  name: null,
  prd_id: null,
  rp_id: null,
  manufacturer_pub_code: null,
  relation_type: null,
  id: null,
  vendor: null,
  type: null,
};

export const PRODUCT_GATEWAY_PLACEHOLDER: IProductGateway = {
  prdd_id: null,
  cost: null,
  gateway_code: null,
  id: null,
  member_cost: null,
  member_price: null,
  price: null,
  product_name: null,
};

export const PRODUCT_ITEM_PDF_PLACEHOLDER: IProductItemPDF = {
  copyright_code: null,
  pdf_file_name: null,
  pdf_id: null,
  pdf_open_path: null,
  prdd_id: null,
  pdf_watermark: true,
  pdf_page_count: null,
}
