import { IDiscount, IResetFieldPayload } from 'app/shared/interfaces';
import { DiscountsState } from 'app/store';

export namespace Discount {
  export class GetAll {
    static readonly type = '[Discount] Get Discounts';
  }
  
  export class Update {
    static readonly type = '[Discount] Update Discount';
    constructor(public payload: IDiscount) { }
  }

  export class Create {
    static readonly type = '[Discount] Create Discount';
    constructor(public payload: IDiscount) { }
  }

  export class Delete {
    static readonly type = '[Discount] Delete Discount';
    constructor(public payload: number) { }
  }
}

export namespace GeneralPurpose {
  export class ResetDiscountsField {
    static readonly type = '[Discount] Reset Field';
    constructor(public payload: IResetFieldPayload<DiscountsState>) { }
  }

  export class FindVendor {
    static readonly type = '[Discount] Find Vendor';
    constructor(public payload: string) { }
  }

  export class FindManufacturer {
    static readonly type = '[Discount] Find Manufacturer';
    constructor(public payload: string) { }
  }
}
