import { Pipe, PipeTransform } from '@angular/core';

@Pipe({name: 'price'})

export class PricePipe implements PipeTransform {
  decimalFormatter = new Intl.NumberFormat('en-US', {
    style: 'decimal',
    useGrouping: false,
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  });

  transform(value: string = ''): string {
    const numberValue: number = parseFloat(value);
    return this.decimalFormatter.format(numberValue);
  }
}
