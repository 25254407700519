
import {throwError as observableThrowError,  Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';

import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Address } from '../../_models/address';

@Injectable()
export class AddressService {
  private baseUrl = `${environment.api_host}/apis/forms/v1/persons`;

  constructor(private _client: HttpClient) { }

  getShippingAddresses(person_id): Observable<Address[]> {
    return this._client.get<Address[]>(this.baseUrl + '/' + person_id + '/shipping_addresses')
      .pipe(map(success => success.map( (v) => new Address().fromJSON(v))),
      catchError((error: HttpErrorResponse) => observableThrowError(error.error || 'Server error')));
  }

  getBillingAddresses(person_id): Observable<Address[]> {
    return this._client.get<Address[]>(this.baseUrl + '/' + person_id + '/billing_addresses')
      .pipe(map(success => success.map( (v) => new Address().fromJSON(v))),
      catchError((error: HttpErrorResponse) => observableThrowError(error.error || 'Server error')));
  }

  create(address, type): Observable<Address> {
    return this._client.post<Address>(this.baseUrl + '/' + address.person_id + '/' + type + '_addresses', address)
    .pipe(map( success => new Address().fromJSON(success)),
    catchError((error: HttpErrorResponse) => observableThrowError(error.error || 'Server error')));
  }

  valildateAddress(address, type): Observable<Address> {
    return this._client.post<any>(this.baseUrl + '/' + address.person_id + '/' + type + '_addresses/validate', address)
    .pipe(map( success => success),
    catchError((error: HttpErrorResponse) => observableThrowError(error.error || 'Server error')));
  }

  validateNewPersonAddress(address):Observable<Address>{
    return this._client.post<any>(this.baseUrl + '/validate_address', address)
    .pipe(map( success => success),
    catchError((error: HttpErrorResponse) => observableThrowError(error.error || 'Server error')));

  }

  update(address, type): Observable<Address> {
    return this._client.put<Address>(this.baseUrl + '/' + address.person_id + '/' + type + '_addresses/' + address.id, address)
    .pipe(map( success => new Address().fromJSON(success)),
    catchError((error: HttpErrorResponse) => observableThrowError(error.error || 'Server error')));
  }

  delete(address, type): Observable<any> {
    return this._client.delete(this.baseUrl + '/' + address.person_id + '/' + type + '_addresses/' + address.id)
    .pipe(map( success => ''),
    catchError((error: HttpErrorResponse) => observableThrowError(error.error || 'Server error')));
  }

}
