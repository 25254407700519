/* src/app/shared/components/vendor-search/vendor-search.component.scss */
.content-section {
  display: flex;
  flex-direction: column;
}
.org-name {
  width: 100% !important;
  margin: 0 20px;
}
.between {
  display: flex;
  justify-content: space-between;
}
.content {
  overflow-y: auto;
}
/*# sourceMappingURL=vendor-search.component.css.map */
