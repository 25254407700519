import { Component, Input, OnInit } from '@angular/core';
import { AppDataService } from "../../../_services/app_data.service";

@Component({
  selector: 'invoice-css-check-info',
  templateUrl: './css-check.html'
})

export class InvoiceCssCheckComponent implements OnInit{
  constructor(
    public appDataService: AppDataService
  ) { }
  ngOnInit() { }
}
