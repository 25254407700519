import * as i0 from '@angular/core';
import { Component, ViewEncapsulation, ChangeDetectionStrategy, ContentChildren, forwardRef, Directive, NgModule } from '@angular/core';
import { MAT_LEGACY_OPTION_PARENT_COMPONENT, MAT_LEGACY_OPTGROUP, MatLegacyOption, MatLegacyOptionModule } from '@angular/material/legacy-core';
import { _MatAutocompleteBase, _MatAutocompleteTriggerBase, _MatAutocompleteOriginBase, MAT_AUTOCOMPLETE_SCROLL_STRATEGY_FACTORY_PROVIDER } from '@angular/material/autocomplete';
export { MAT_AUTOCOMPLETE_DEFAULT_OPTIONS as MAT_LEGACY_AUTOCOMPLETE_DEFAULT_OPTIONS, MAT_AUTOCOMPLETE_DEFAULT_OPTIONS_FACTORY as MAT_LEGACY_AUTOCOMPLETE_DEFAULT_OPTIONS_FACTORY, MAT_AUTOCOMPLETE_SCROLL_STRATEGY as MAT_LEGACY_AUTOCOMPLETE_SCROLL_STRATEGY, MAT_AUTOCOMPLETE_SCROLL_STRATEGY_FACTORY as MAT_LEGACY_AUTOCOMPLETE_SCROLL_STRATEGY_FACTORY, MAT_AUTOCOMPLETE_SCROLL_STRATEGY_FACTORY_PROVIDER as MAT_LEGACY_AUTOCOMPLETE_SCROLL_STRATEGY_FACTORY_PROVIDER, MatAutocompleteSelectedEvent as MatLegacyAutocompleteSelectedEvent, getMatAutocompleteMissingPanelError as getMatLegacyAutocompleteMissingPanelError } from '@angular/material/autocomplete';
import * as i1 from '@angular/common';
import { CommonModule } from '@angular/common';
import { OverlayModule } from '@angular/cdk/overlay';
import { MatCommonModule } from '@angular/material/core';
import { CdkScrollableModule } from '@angular/cdk/scrolling';
import { NG_VALUE_ACCESSOR } from '@angular/forms';

/**
 * @deprecated Use `MatAutocomplete` from `@angular/material/autocomplete` instead. See https://material.angular.io/guide/mdc-migration for information about migrating.
 * @breaking-change 17.0.0
 */
class MatLegacyAutocomplete extends _MatAutocompleteBase {
  constructor() {
    super(...arguments);
    this._visibleClass = 'mat-autocomplete-visible';
    this._hiddenClass = 'mat-autocomplete-hidden';
    this._animationDone = null;
  }
  static {
    this.ɵfac = i0.ɵɵngDeclareFactory({
      minVersion: "12.0.0",
      version: "16.1.1",
      ngImport: i0,
      type: MatLegacyAutocomplete,
      deps: null,
      target: i0.ɵɵFactoryTarget.Component
    });
  }
  static {
    this.ɵcmp = i0.ɵɵngDeclareComponent({
      minVersion: "14.0.0",
      version: "16.1.1",
      type: MatLegacyAutocomplete,
      selector: "mat-autocomplete",
      inputs: {
        disableRipple: "disableRipple"
      },
      host: {
        attributes: {
          "ngSkipHydration": ""
        },
        classAttribute: "mat-autocomplete"
      },
      providers: [{
        provide: MAT_LEGACY_OPTION_PARENT_COMPONENT,
        useExisting: MatLegacyAutocomplete
      }],
      queries: [{
        propertyName: "optionGroups",
        predicate: MAT_LEGACY_OPTGROUP,
        descendants: true
      }, {
        propertyName: "options",
        predicate: MatLegacyOption,
        descendants: true
      }],
      exportAs: ["matAutocomplete"],
      usesInheritance: true,
      ngImport: i0,
      template: "<ng-template let-formFieldId=\"id\">\n  <div class=\"mat-autocomplete-panel\"\n       role=\"listbox\"\n       [id]=\"id\"\n       [attr.aria-label]=\"ariaLabel || null\"\n       [attr.aria-labelledby]=\"_getPanelAriaLabelledby(formFieldId)\"\n       [ngClass]=\"_classList\"\n       #panel>\n    <ng-content></ng-content>\n  </div>\n</ng-template>\n",
      styles: [".mat-autocomplete-panel{min-width:112px;max-width:280px;overflow:auto;-webkit-overflow-scrolling:touch;visibility:hidden;max-width:none;max-height:256px;position:relative;width:100%;border-bottom-left-radius:4px;border-bottom-right-radius:4px}.mat-autocomplete-panel.mat-autocomplete-visible{visibility:visible}.mat-autocomplete-panel.mat-autocomplete-hidden{visibility:hidden}.mat-autocomplete-panel-above .mat-autocomplete-panel{border-radius:0;border-top-left-radius:4px;border-top-right-radius:4px}.mat-autocomplete-panel .mat-divider-horizontal{margin-top:-1px}.cdk-high-contrast-active .mat-autocomplete-panel{outline:solid 1px}mat-autocomplete{display:none}"],
      dependencies: [{
        kind: "directive",
        type: i1.NgClass,
        selector: "[ngClass]",
        inputs: ["class", "ngClass"]
      }],
      changeDetection: i0.ChangeDetectionStrategy.OnPush,
      encapsulation: i0.ViewEncapsulation.None
    });
  }
}
i0.ɵɵngDeclareClassMetadata({
  minVersion: "12.0.0",
  version: "16.1.1",
  ngImport: i0,
  type: MatLegacyAutocomplete,
  decorators: [{
    type: Component,
    args: [{
      selector: 'mat-autocomplete',
      encapsulation: ViewEncapsulation.None,
      changeDetection: ChangeDetectionStrategy.OnPush,
      exportAs: 'matAutocomplete',
      inputs: ['disableRipple'],
      host: {
        'class': 'mat-autocomplete',
        'ngSkipHydration': ''
      },
      providers: [{
        provide: MAT_LEGACY_OPTION_PARENT_COMPONENT,
        useExisting: MatLegacyAutocomplete
      }],
      template: "<ng-template let-formFieldId=\"id\">\n  <div class=\"mat-autocomplete-panel\"\n       role=\"listbox\"\n       [id]=\"id\"\n       [attr.aria-label]=\"ariaLabel || null\"\n       [attr.aria-labelledby]=\"_getPanelAriaLabelledby(formFieldId)\"\n       [ngClass]=\"_classList\"\n       #panel>\n    <ng-content></ng-content>\n  </div>\n</ng-template>\n",
      styles: [".mat-autocomplete-panel{min-width:112px;max-width:280px;overflow:auto;-webkit-overflow-scrolling:touch;visibility:hidden;max-width:none;max-height:256px;position:relative;width:100%;border-bottom-left-radius:4px;border-bottom-right-radius:4px}.mat-autocomplete-panel.mat-autocomplete-visible{visibility:visible}.mat-autocomplete-panel.mat-autocomplete-hidden{visibility:hidden}.mat-autocomplete-panel-above .mat-autocomplete-panel{border-radius:0;border-top-left-radius:4px;border-top-right-radius:4px}.mat-autocomplete-panel .mat-divider-horizontal{margin-top:-1px}.cdk-high-contrast-active .mat-autocomplete-panel{outline:solid 1px}mat-autocomplete{display:none}"]
    }]
  }],
  propDecorators: {
    optionGroups: [{
      type: ContentChildren,
      args: [MAT_LEGACY_OPTGROUP, {
        descendants: true
      }]
    }],
    options: [{
      type: ContentChildren,
      args: [MatLegacyOption, {
        descendants: true
      }]
    }]
  }
});

/**
 * Provider that allows the autocomplete to register as a ControlValueAccessor.
 * @docs-private
 * @deprecated Use `MAT_AUTOCOMPLETE_VALUE_ACCESSOR` from `@angular/material/autocomplete` instead. See https://material.angular.io/guide/mdc-migration for information about migrating.
 * @breaking-change 17.0.0
 */
const MAT_LEGACY_AUTOCOMPLETE_VALUE_ACCESSOR = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => MatLegacyAutocompleteTrigger),
  multi: true
};
/**
 * @deprecated Use `MatAutocompleteTrigger` from `@angular/material/autocomplete` instead. See https://material.angular.io/guide/mdc-migration for information about migrating.
 * @breaking-change 17.0.0
 */
class MatLegacyAutocompleteTrigger extends _MatAutocompleteTriggerBase {
  constructor() {
    super(...arguments);
    this._aboveClass = 'mat-autocomplete-panel-above';
  }
  static {
    this.ɵfac = i0.ɵɵngDeclareFactory({
      minVersion: "12.0.0",
      version: "16.1.1",
      ngImport: i0,
      type: MatLegacyAutocompleteTrigger,
      deps: null,
      target: i0.ɵɵFactoryTarget.Directive
    });
  }
  static {
    this.ɵdir = i0.ɵɵngDeclareDirective({
      minVersion: "14.0.0",
      version: "16.1.1",
      type: MatLegacyAutocompleteTrigger,
      selector: "input[matAutocomplete], textarea[matAutocomplete]",
      host: {
        listeners: {
          "focusin": "_handleFocus()",
          "blur": "_onTouched()",
          "input": "_handleInput($event)",
          "keydown": "_handleKeydown($event)",
          "click": "_handleClick()"
        },
        properties: {
          "attr.autocomplete": "autocompleteAttribute",
          "attr.role": "autocompleteDisabled ? null : \"combobox\"",
          "attr.aria-autocomplete": "autocompleteDisabled ? null : \"list\"",
          "attr.aria-activedescendant": "(panelOpen && activeOption) ? activeOption.id : null",
          "attr.aria-expanded": "autocompleteDisabled ? null : panelOpen.toString()",
          "attr.aria-owns": "(autocompleteDisabled || !panelOpen) ? null : autocomplete?.id",
          "attr.aria-haspopup": "autocompleteDisabled ? null : \"listbox\""
        },
        classAttribute: "mat-autocomplete-trigger"
      },
      providers: [MAT_LEGACY_AUTOCOMPLETE_VALUE_ACCESSOR],
      exportAs: ["matAutocompleteTrigger"],
      usesInheritance: true,
      ngImport: i0
    });
  }
}
i0.ɵɵngDeclareClassMetadata({
  minVersion: "12.0.0",
  version: "16.1.1",
  ngImport: i0,
  type: MatLegacyAutocompleteTrigger,
  decorators: [{
    type: Directive,
    args: [{
      selector: `input[matAutocomplete], textarea[matAutocomplete]`,
      host: {
        'class': 'mat-autocomplete-trigger',
        '[attr.autocomplete]': 'autocompleteAttribute',
        '[attr.role]': 'autocompleteDisabled ? null : "combobox"',
        '[attr.aria-autocomplete]': 'autocompleteDisabled ? null : "list"',
        '[attr.aria-activedescendant]': '(panelOpen && activeOption) ? activeOption.id : null',
        '[attr.aria-expanded]': 'autocompleteDisabled ? null : panelOpen.toString()',
        '[attr.aria-owns]': '(autocompleteDisabled || !panelOpen) ? null : autocomplete?.id',
        '[attr.aria-haspopup]': 'autocompleteDisabled ? null : "listbox"',
        // Note: we use `focusin`, as opposed to `focus`, in order to open the panel
        // a little earlier. This avoids issues where IE delays the focusing of the input.
        '(focusin)': '_handleFocus()',
        '(blur)': '_onTouched()',
        '(input)': '_handleInput($event)',
        '(keydown)': '_handleKeydown($event)',
        '(click)': '_handleClick()'
      },
      exportAs: 'matAutocompleteTrigger',
      providers: [MAT_LEGACY_AUTOCOMPLETE_VALUE_ACCESSOR]
    }]
  }]
});

/**
 * Directive applied to an element to make it usable
 * as a connection point for an autocomplete panel.
 * @deprecated Use `MatAutocompleteOrigin` from `@angular/material/autocomplete` instead. See https://material.angular.io/guide/mdc-migration for information about migrating.
 * @breaking-change 17.0.0
 */
class MatLegacyAutocompleteOrigin extends _MatAutocompleteOriginBase {
  static {
    this.ɵfac = i0.ɵɵngDeclareFactory({
      minVersion: "12.0.0",
      version: "16.1.1",
      ngImport: i0,
      type: MatLegacyAutocompleteOrigin,
      deps: null,
      target: i0.ɵɵFactoryTarget.Directive
    });
  }
  static {
    this.ɵdir = i0.ɵɵngDeclareDirective({
      minVersion: "14.0.0",
      version: "16.1.1",
      type: MatLegacyAutocompleteOrigin,
      selector: "[matAutocompleteOrigin]",
      exportAs: ["matAutocompleteOrigin"],
      usesInheritance: true,
      ngImport: i0
    });
  }
}
i0.ɵɵngDeclareClassMetadata({
  minVersion: "12.0.0",
  version: "16.1.1",
  ngImport: i0,
  type: MatLegacyAutocompleteOrigin,
  decorators: [{
    type: Directive,
    args: [{
      selector: '[matAutocompleteOrigin]',
      exportAs: 'matAutocompleteOrigin'
    }]
  }]
});

/**
 * @deprecated Use `MatAutocompleteModule` from `@angular/material/autocomplete` instead. See https://material.angular.io/guide/mdc-migration for information about migrating.
 * @breaking-change 17.0.0
 */
class MatLegacyAutocompleteModule {
  static {
    this.ɵfac = i0.ɵɵngDeclareFactory({
      minVersion: "12.0.0",
      version: "16.1.1",
      ngImport: i0,
      type: MatLegacyAutocompleteModule,
      deps: [],
      target: i0.ɵɵFactoryTarget.NgModule
    });
  }
  static {
    this.ɵmod = i0.ɵɵngDeclareNgModule({
      minVersion: "14.0.0",
      version: "16.1.1",
      ngImport: i0,
      type: MatLegacyAutocompleteModule,
      declarations: [MatLegacyAutocomplete, MatLegacyAutocompleteTrigger, MatLegacyAutocompleteOrigin],
      imports: [OverlayModule, MatLegacyOptionModule, MatCommonModule, CommonModule],
      exports: [MatLegacyAutocomplete, MatLegacyAutocompleteTrigger, MatLegacyAutocompleteOrigin, CdkScrollableModule, MatLegacyOptionModule, MatCommonModule]
    });
  }
  static {
    this.ɵinj = i0.ɵɵngDeclareInjector({
      minVersion: "12.0.0",
      version: "16.1.1",
      ngImport: i0,
      type: MatLegacyAutocompleteModule,
      providers: [MAT_AUTOCOMPLETE_SCROLL_STRATEGY_FACTORY_PROVIDER],
      imports: [OverlayModule, MatLegacyOptionModule, MatCommonModule, CommonModule, CdkScrollableModule, MatLegacyOptionModule, MatCommonModule]
    });
  }
}
i0.ɵɵngDeclareClassMetadata({
  minVersion: "12.0.0",
  version: "16.1.1",
  ngImport: i0,
  type: MatLegacyAutocompleteModule,
  decorators: [{
    type: NgModule,
    args: [{
      imports: [OverlayModule, MatLegacyOptionModule, MatCommonModule, CommonModule],
      exports: [MatLegacyAutocomplete, MatLegacyAutocompleteTrigger, MatLegacyAutocompleteOrigin, CdkScrollableModule, MatLegacyOptionModule, MatCommonModule],
      declarations: [MatLegacyAutocomplete, MatLegacyAutocompleteTrigger, MatLegacyAutocompleteOrigin],
      providers: [MAT_AUTOCOMPLETE_SCROLL_STRATEGY_FACTORY_PROVIDER]
    }]
  }]
});

/**
 * Generated bundle index. Do not edit.
 */

export { MAT_LEGACY_AUTOCOMPLETE_VALUE_ACCESSOR, MatLegacyAutocomplete, MatLegacyAutocompleteModule, MatLegacyAutocompleteOrigin, MatLegacyAutocompleteTrigger };
