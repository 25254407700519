<div class="modal single-table">
    <h3>Content Log</h3>
    <form [formGroup]="form" novalidate>
      <mat-form-field class="v-explicit-width">
        <input matInput formControlName="fileName" placeholder="PDF File Name" readonly>
      </mat-form-field>
      <mat-form-field>
        <input matInput formControlName="availablePrints" placeholder="Available Prints" readonly>
      </mat-form-field>
      <mat-form-field>
        <input matInput formControlName="downloadCount" placeholder="Download Count" readonly>
      </mat-form-field>
      <mat-form-field>
        <input matInput formControlName="openCount" placeholder="Open Count" readonly>
      </mat-form-field>
      <mat-form-field>
        <input matInput formControlName="printCount" placeholder="Print Count" readonly>
      </mat-form-field>
      <mat-form-field>
        <input matInput formControlName="transferCount" placeholder="Transfer Count" readonly>
      </mat-form-field>
    </form>
    <table  #dataTable matSort (matSortChange)="sortData($event)" cellspacing="0" cellpadding="0">
      <thead>
      <tr>
        <th mat-sort-header="Operation">Operation</th>
        <th mat-sort-header="created_by">Added by</th>
        <th mat-sort-header="created_at">  Date &amp; Time</th>
        <th mat-sort-header="description"> Description</th>
      </tr>
      </thead>
      <tbody>
      <tr *ngFor="let l of sortedData">
        <td>{{l.operation}}</td>
        <td>{{l.created_by}}</td>
        <td>{{l.created_at | date:'M/d/yyyy, h:mm a'}}</td>
        <td>{{l.description}}</td>
      </tr>
      </tbody>
    </table>
  </div>
  
  