
import {throwError as observableThrowError,  ReplaySubject ,  Observable, EMPTY } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Order } from '../_models/order';
import { LineItem } from '../_models/line_item';
import { Invoice } from '../_models/invoice';
import { Address } from '../_models/address';
import { PhoneNumber } from '../_models/phone_number';
import { EmailAddress } from '../_models/email_address';
import { environment } from '../../environments/environment';
import { map, catchError } from 'rxjs/operators';



@Injectable()
export class OrderService {
  private subsUrl = `${environment.api_host}/apis/forms/v1/subscription_orders`;
  private retailUrl = `${environment.api_host}/apis/forms/v1/orders`;
  _order: Order = null;
  public orderSub: ReplaySubject<any> = new ReplaySubject(1);
  constructor(
    private http: HttpClient,
  ) {}

  get(id: number): Observable<Order> {
    return this.http.get(this.baseUrl + `/${id}`)
      .pipe(map(success => {
        const o = new Order().fromJSON(success);
        o.line_items = o.line_items.map((i) => new LineItem().fromJSON(i));
        o.invoices = o.invoices.map((i) => new Invoice().fromJSON(i));
        o.invoices.forEach( (i) => i.line_items = i.line_items.map((li) => new LineItem().fromJSON(li)));
        return o;
      }),
      catchError(error => observableThrowError(error.json().error || 'Server Error')));
  }

  get order(): Order {
    return this._order;
  }

  get baseUrl(): string {
    if (!this._order) {
      return this.retailUrl;
    } else if (this._order.type === 'Subscription Order') {
      return this.subsUrl;
    } else {
      return this.retailUrl;
    }
  }

  instantiateOrder(order: Order) {
    this._order = new Order().fromJSON(order);
    this.order.line_items = this.order.line_items.map((i) => new LineItem().fromJSON(i));
    this.order._unshipped_line_items = this.order._unshipped_line_items.map((i) => new LineItem().fromJSON(i));

    if (this.order.billing_address) {
      this.order.billing_address = new Address().fromJSON(this.order.billing_address);
    }
    if (this.order.shipping_address) {
      this.order.shipping_address = new Address().fromJSON(this.order.shipping_address);
    }
    if (this.order.shipping_phone) {
      this.order.shipping_phone =  new PhoneNumber().fromJSON(this.order.shipping_phone);
    }
    if (this.order.billing_phone) {
      this.order.billing_phone = new PhoneNumber().fromJSON(this.order.billing_phone);
    }
    if (this.order.shipping_email_address) {
      this.order.shipping_email_address = new EmailAddress().fromJSON(this.order.shipping_email_address);
    }
    if (this.order.billing_email_addresses) {
      this.order.billing_email_addresses = this.order.billing_email_addresses.map((a) => new EmailAddress().fromJSON(a));
    }
    this.orderSub.next(1);

  }

  load(id: number) {
    return this.http.get<Order>(this.baseUrl + `/${id}`).subscribe(
      success => {
        this.instantiateOrder(success);
      }
    );
  }

  updateItem(line_item_id: number, price: number, quantity: number, discount: number) {
    return this.http.put<Order>(this.baseUrl + `/${this.order.id}/line_items/${line_item_id}`,
      { price: price, quantity: quantity, discount: discount }).pipe(map(
        success => this.instantiateOrder(success)
    ));
  }

  addItem(order_id: number = this.order.id, line_item: LineItem) {
    return this.http.post<Order>( this.baseUrl + `/${order_id}/line_items`, line_item).pipe(map(
      success => this.instantiateOrder(success)
    ));
  }

  deleteItem(order_id, line_item_id, vi_override) {
    return this.http.delete<Order>( this.baseUrl + `/${order_id}/line_items/${line_item_id}?override_vendor_item_delete=${vi_override}`).pipe(map(
      success => this.instantiateOrder(success)
    ),
    catchError(error => observableThrowError(error)));
  }

  updateOrder(order, data) {
    return this.http.put<Order>(this.baseUrl + `/${order.id}`, data).pipe(map(
      success => this.instantiateOrder(success)
    ));
  }

  updateContactInformation(order_id, data) {
    return this.http.put<Order>(this.baseUrl + `/${order_id}/update_contact_information`, data).pipe(map(
      success => this.instantiateOrder(success)
    ));
  }

  validateCouponCode(coupon_code, order_id) {
    if ((order_id === 0) || (coupon_code === '' || coupon_code === undefined || coupon_code === null) ) { return EMPTY; }
    return this.http.get(`${this.baseUrl}/${order_id}/coupons/${coupon_code}`);
  }

  openOrder(order) {
    return this.http.post<Order>(`${this.baseUrl.replace('/v1/', '/v2/')}/${order.id}/open_order`, order).pipe(map(
      success => this.instantiateOrder(success)
    ));
  }

// Depreceated //
  sendProforma(order) {
    return this.http.post<Order>( this.baseUrl + `/${order.id}/send_pro_forma`, order).pipe(map(
      success => this.instantiateOrder(success)
    ));
  }
// Depreceated //

  cancelOrder(order){
    return this.http.put(this.retailUrl + `/${order.id}/cancel_order`, order).pipe(map(
      result => result
    ),
    catchError(error => observableThrowError('Error in Cancelling order')));
  }

  completeOrder(order){
    return this.http.put(this.retailUrl + `/${order.id}/complete_order`, order).pipe(map(
      result => result
    ),
    catchError(error => observableThrowError('Error in Completing order')));
  }

}
