export const HTTP_MESSAGES = {
  generalSuccess: 'Action successful',
  generalError: 'Something went wrong',
  createSuccess: 'Created successfully',
  createFail: 'Unable to create',
  updateSuccess: 'Your edits have been saved',
  updateFail: 'Unable to save your edits',
  deleteSuccess: 'Deleted successfully',
  deleteFail: 'Unable to delete',
  repairFail: 'An error occurred when repairing the PDF',
  repairSuccess: 'PDF repaired'
}
