<div class="section-title">
  <h2 *ngIf="title">{{ title }}</h2>
  <h3 *ngIf="smallTitle">{{ smallTitle }}</h3>
  <button
    *ngIf="buttonCaption"
    mat-raised-button
    color="primary"
    (click)="onCreate()"
    [id]="btnId"
    [disabled]="disableCreateButton"
    [matTooltip]="disabledButtonTooltip"
  >{{ buttonCaption }}</button>
</div>
