import { Injectable } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { Store } from '@ngxs/store';
import { IChangesHistory, IChangesHistoryPayload } from 'app/shared/interfaces';
import { Observable } from 'rxjs';
import { GetHistory } from '../actions';

@Injectable({ providedIn: 'root' })
export class ChangesHistoryFacade {
  
  loading$: Observable<boolean>;
  history$: Observable<IChangesHistory[]>;

  constructor(
    private readonly store: Store,
    private readonly dialog: MatDialog,
  ) {
    this.loading$ = this.store.select(state => state.changesHistory.loading);
    this.history$ = this.store.select(state => state.changesHistory.history);
  }

  showHistory(data: IChangesHistoryPayload, component: any) {
    this.store.dispatch(new GetHistory(data));
    this.dialog.open(component, {
      autoFocus: false,
      width: '1200px',
    });
  }
  
}