import { Component } from '@angular/core';

@Component({
  selector: 'retired-invoice-vats',
  template: `
    <ul>
      <li>Techstreet US Federal ID: 86-1260328</li>
    </ul>

    <ul>
      <li>Techstreet Australian Business Number (ABN): 31 649 479 078</li>
    </ul>
  `,
})
export class RetiredInvoiceVatsComponent {}
