
import {throwError as observableThrowError,  Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Entitlement } from '../_models/entitlement';
import { EntitlementContent } from '../_models/entitlement_content';
import { Product } from '../_models/product';
import { map, catchError } from 'rxjs/operators';
import { environment } from '../../environments/environment';



@Injectable()
export class EntitlementService {

  private orderUrl = `${environment.api_host}/apis/forms/v1/orders`;
  private personUrl = `${environment.api_host}/apis/forms/v1/persons`;

  constructor( private http: HttpClient,) { }

  getByOrderId(id: number) {
    const url = this.orderUrl + `/${id}/entitlements`;
    return this.fetch(url).pipe(map(e => {
      let entitlementsWithDownloadableContent: Entitlement[] = [];
      return e.filter(ewdc => ewdc.contents && ewdc.contents.length > 0)
    }));
  }

  getByPersonId(id: number): Observable<Entitlement[]> {
    const url = this.personUrl + `/${id}/entitlements`;
    return this.fetch(url);
  }

  fetch(url: string): Observable<Entitlement[]> {
    return this.http.get<Entitlement[]>(url)
      .pipe(map(success => {
        return success.map( (json) => {
          return this.instantiateEntitlement(json);
        });
      }),
      catchError(error => observableThrowError(error || 'Server Error')));
  }

  instantiateEntitlement(json) {
    const e = new Entitlement().fromJSON(json);
    e.contents = e.contents.map((c) => new EntitlementContent().fromJSON(c));
    e.product = new Product().fromJSON(e.product);
    return e;
  }

  updateAssigneeEmail(entitlement_id: number, email: string) {
    const url = `${environment.api_host}/apis/forms/v1/entitlements/${entitlement_id}/reassign`;
    return this.http.put<Entitlement>(url,{ email: email });
  }

  addPrint(id: number, count: number = 1) : Observable<EntitlementContent> {
    const url = `${environment.api_host}/apis/forms/v1/entitlement_contents/${id}/addprint`;
    return this.http.put<EntitlementContent>(url,{count: count});
  }

  unlockContent(id: number) {
    const url = `${environment.api_host}/apis/forms/v1/entitlement_contents/${id}/unlock`;
    return this.http.put<EntitlementContent>(url,{});
  }

}
