import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'required_errormsg' })
export class RequiredErrorMessagePipe implements PipeTransform {
  transform(value: string = null): string {
    return `${value || 'This field'} is required`;
  }
}

@Pipe({ name: 'invalid_errormsg' })
export class InvalidErrorMessagePipe implements PipeTransform {
  transform(value: string = null): string {
    return `${value || 'This value'} is invalid`;
  }
}

@Pipe({ name: 'duplicate_errormsg' })
export class DuplicateErrorMessagePipe implements PipeTransform {
  transform(value: string = null): string {
    return `${value || 'This value'} already exists`;
  }
}

@Pipe({ name: 'maxlength_errormsg' })
export class MaxlengthErrorMessagePipe implements PipeTransform {
  transform(value: string = null): string {
    return `${value || 'This value'} is too long`;
  }
}

@Pipe({ name: 'min_errormsg' })
export class MinErrorMessagePipe implements PipeTransform {
  transform(value: string = null): string {
    return `${value || 'This number'} is too small`;
  }
}

@Pipe({ name: 'max_errormsg' })
export class MaxErrorMessagePipe implements PipeTransform {
  transform(value: string = null): string {
    return `${value || 'This number'} is too large`;
  }
}

@Pipe({ name: 'email_errormsg' })
export class EmailErrorMessagePipe implements EmailErrorMessagePipe {
  transform(value: string = null): string {
    return `${value || 'This value'} must be a valid email address`;
  }
}
