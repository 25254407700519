import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { PublishTypes } from 'app/shared/enums';
import {
  IBundle,
  IBundleFormats,
  IProductGateway,
  IInfoUpdate,
  IOrgAndSubGroupsUpdate,
  IProduct,
  IProductCreatePayload,
  IProductEvent,
  IProductItem,
  IProductItemPDF,
  IProductItemPricecode,
  IProductListItem,
  IProductNote,
  IProductSearchByPrddIdResult,
  IProductSearchResult,
  IRelation,
  IProductSubGroup,
  ISubGroup,
  IProductOrganizationSearchResult,
  IEditPDFPayload,
  IRecentProducts,
  IRelationsTree,
  IPaginationPayload,
} from 'app/shared/interfaces';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { AlertService } from './alert.service';

@Injectable({ providedIn: 'root' })
export class ProductsService {

  readonly baseUrl = environment.api_host;
  readonly url = `${this.baseUrl}/apis/forms/v1/products`;

  constructor(
    private http: HttpClient,
    private alertService: AlertService,
  ) { }

  getUnpublishedProducts(): Observable<IProductListItem[]> {
    return this.http.get<IProductListItem[]>(`${this.url}/unpublished_products`);
  }

  getRecentProducts(payload: IPaginationPayload): Observable<IRecentProducts> {
    return this.http.get<IRecentProducts>(
      `${this.url}`,
      {
        params: {
          page: String(payload.page),
          limit: String(payload.limit),
        },
      },
    );
  }

  getPreorderedProducts(): Observable<IProductListItem[]> {
    return this.http.get<IProductListItem[]>(`${this.url}/preordered_products`);
  }

  publishProducts(type: PublishTypes, id?: string): Observable<string> {
    const payload = { sync_type: type };
    if (id) Object.assign(payload, { product_id: id });
    return this.http.post<{ message: string }>(`${this.url}/publish_products`, payload).pipe(
      map(response => response.message),
    );
  }

  getSubGroups(id: string): Observable<IProductSubGroup[]> {
    return this.http.get<IProductSubGroup[]>(`${this.url}/${id}/product_subgroups`);
  }

  addSubGroup(subGroupId: string, productId: string): Observable<IProductSubGroup> {
    return this.http.post<IProductSubGroup>(`${this.url}/${productId}/product_subgroups`, { subgroup_id: subGroupId });
  }

  deleteSubGroup(subGroupId: string, productId: string): Observable<IProductSubGroup> {
    return this.http.delete<IProductSubGroup>(`${this.url}/${productId}/product_subgroups/${subGroupId}`);
  }

  getSubGroup(id: string): Observable<IProductSubGroup> {
    return this.http.get<IProductSubGroup>(`${this.url}/subgroup_validation/${id}`).pipe(
      map(subGroup => ({ ...subGroup, subgroup_id: subGroup.subgroup_id.toString() })),
    );
  }

  createProductSubGroup(id: string, data: ISubGroup): Observable<IProductSubGroup> {
    return this.http.post<IProductSubGroup>(`${this.url}/${id}/subgroups`, { subgroup: data });
  }

  updateProductSubGroup(productId: string, data: ISubGroup): Observable<IProductSubGroup> {
    return this.http.patch<IProductSubGroup>(`${this.url}/${productId}/subgroups/${data.subgroup_id}`, { subgroup: data });
  }

  searchOrganization(query: string): Observable<IProductOrganizationSearchResult> {
    return this.http.get<IProductOrganizationSearchResult>(`${this.baseUrl}/apis/forms/v1/subgroups/find_by_org_id/${query}`);
  }

  searchSubGroup(query: string): Observable<ISubGroup> {
    return this.http.get<ISubGroup>(`${this.baseUrl}/apis/forms/v1/subgroups/find_by_parent_sgrp_id/${query}`);
  }

  createProduct(data: IProductCreatePayload): Observable<string> {
    return this.http.post<{ product_id: number }>(`${this.url}`, { product: data }).pipe(
      map(resp => resp.product_id.toString()),
    );
  }

  deleteProduct(id: string): Observable<string> {
    return this.http.delete<{ id: string }>(`${this.url}/${id}`).pipe(
      map(resp => resp.id),
    );
  }

  copyProduct(id: string): Observable<string> {
    return this.http.post<{ product_id: string }>(`${this.url}/copy_product/${id}`, { }).pipe(
      map(resp => resp.product_id),
    );
  }

  getProduct(id: string): Observable<IProduct> {
    return this.http.get<IProduct>(`${this.url}/${id}/edit`);
  }

  updateOrgAndSubGroups(id: string, product: IOrgAndSubGroupsUpdate): Observable<IProduct> {
    return this.http.patch<IProduct>(`${this.url}/${id}/update_vendor`, { product })
  }

  updateInfo(id: string, product: IInfoUpdate): Observable<IProduct> {
    const formData: FormData = new FormData();
    const coverArtFileName = (product.cover_art as File)?.name;
    const previewFileName = (product.preview as File)?.name;
    if (coverArtFileName) {
      console.log('coverArtFileName', coverArtFileName)
      formData.append('cover_art', product.cover_art as Blob, coverArtFileName);
    } else {
      formData.append('cover_art', null);
    }
    if (previewFileName) {
      formData.append('preview', product.preview as Blob, previewFileName);
    } else {
      formData.append('preview', null);
    }
    formData.append('product', JSON.stringify({ product }))
    return this.http.patch<IProduct>(`${this.url}/${id}/update_product`, formData)
  }

  getProductNotes(id: string): Observable<IProductNote[]> {
    return this.http.get<IProductNote[]>(`${this.url}/${id}/standard_notes`);
  }

  updateProductNote(id: string, data: IProductNote): Observable<IProductNote> {
    return this.http.patch<IProductNote>(`${this.url}/${id}/standard_notes/${data.id}`, { standard_note: data });
  }

  createProductNote(id: string, data: IProductNote): Observable<IProductNote> {
    return this.http.post<IProductNote>(`${this.url}/${id}/standard_notes`, { standard_note: data });
  }

  deleteProductNote(id: string, noteId: string): Observable<IProductNote> {
    return this.http.delete<IProductNote>(`${this.url}/${id}/standard_notes/${noteId}`);
  }

  getBundles(id: string): Observable<IBundle[]> {
    return this.http.get<IBundle[]>(`${this.url}/${id}/print_plus_electronic_bundles`);
  }

  updateBundle(id: string, data: IBundle): Observable<IBundle> {
    return this.http.patch<IBundle>(`${this.url}/${id}/print_plus_electronic_bundles/${data.id}`, { bundle: data });
  }

  createBundle(id: string, data: IBundle): Observable<IBundle> {
    return this.http.post<IBundle>(`${this.url}/${id}/print_plus_electronic_bundles`, { bundle: data });
  }

  deleteBundle(id: string, bundleId: string): Observable<IBundle> {
    return this.http.delete<IBundle>(`${this.url}/${id}/print_plus_electronic_bundles/${bundleId}`);
  }

  getBundleFormats(id: string): Observable<IBundleFormats> {
    return this.http.get<IBundleFormats>(`${this.url}/${id}/print_plus_electronic_bundles/available_plus_details`);
  }

  getEvents(id: string): Observable<IProductEvent[]> {
    return this.http.get<IProductEvent[]>(`${this.url}/${id}/product_events`);
  }

  updateEvent(id: string, data: IProductEvent): Observable<IProductEvent> {
    return this.http.patch<IProductEvent>(`${this.url}/${id}/product_events/${data.id}`, { event: data });
  }

  createEvent(id: string, data: IProductEvent): Observable<IProductEvent> {
    return this.http.post<IProductEvent>(`${this.url}/${id}/product_events`, { event: data });
  }

  deleteEvent(id: string, bundleId: string): Observable<IProductEvent> {
    return this.http.delete<IProductEvent>(`${this.url}/${id}/product_events/${bundleId}`);
  }

  getRelations(id: string): Observable<{ converse: IRelation[], other: IRelation[] }> {
    return this.http.get<{ converse: IRelation[], other: IRelation[] }>(`${this.url}/${id}/related_products`);
  }

  updateRelation(id: string, data: IRelation): Observable<IRelation> {
    return this.http.patch<IRelation>(`${this.url}/${id}/related_products/${data.prd_id}`, { relation: data });
  }

  createRelation(id: string, data: IRelation): Observable<IRelation> {
    return this.http.post<IRelation>(`${this.url}/${id}/related_products`, { relation: data });
  }

  deleteRelation(id: string, relationId: string): Observable<string> {
    return this.http.delete<string>(`${this.url}/${id}/related_products/${relationId}`);
  }

  getRelationsTree(id: string): Observable<IRelationsTree> {
    return this.http.get<IRelationsTree>(`${this.url}/${id}/related_products/all_relations`);
  }

  productSearch(id: string, searchId: string): Observable<IProductSearchResult> {
    return this.http.get<IProductSearchResult>(`${this.url}/${id}/related_products/find_by_product_id/${searchId}`);
  }

  getItems(id: string): Observable<IProductItem[]> {
    return this.http.get<IProductItem[]>(`${this.url}/${id}/product_item_details`);
  }

  updateItem(id: string, data: Partial<IProductItem>): Observable<IProductItem> {
    return this.http.patch<IProductItem>(`${this.url}/${id}/product_item_details/${data.prdd_id}`, { details: data });
  }

  createItem(id: string, data: Partial<IProductItem>): Observable<IProductItem> {
    return this.http.post<IProductItem>(`${this.url}/${id}/product_item_details`, { details: data });
  }

  deleteItem(id: string, itemId: string): Observable<string> {
    return this.http.delete<{ id: string }>(`${this.url}/${id}/product_item_details/${itemId}`).pipe(
      map(resp => resp.id),
    );
  }

  getPriceCodes(id: string, vendorOrgId: string): Observable<IProductItemPricecode[]> {
    return this.http.get<IProductItemPricecode[]>(`${this.url}/${id}/product_item_details/list_price/${vendorOrgId}`);
  }

  addPdf(data: IEditPDFPayload): Observable<IProductItemPDF> {
    const formData: FormData = new FormData();
    formData.append('file', data.file)
    formData.append('params', JSON.stringify(data));
    return this.http.post<IProductItemPDF>(`${this.url}/${data.id}/product_item_details/${data.prdd_id}/pdfs`, formData);
  }

  repairPdf(id: string, itemId: string, pdfId: string): Observable<IProductItemPDF> {
    const formData: FormData = new FormData();
    formData.append('params', id);
    return this.http.patch<IProductItemPDF>(`${this.url}/${id}/product_item_details/${itemId}/pdfs/${pdfId}/repair`, formData);
  }

  deletePdf(id: string, itemId: string, pdfId: string): Observable<string> {
    return this.http.delete<{ id: string }>(`${this.url}/${id}/product_item_details/${itemId}/pdfs/${pdfId}`).pipe(
      map(resp => resp.id),
    );
  }

  updatePdf(data: IEditPDFPayload): Observable<IProductItemPDF> {
    const formData: FormData = new FormData();
    formData.append('file', data.file);
    formData.append('params', JSON.stringify(data));
    return this.http.patch<IProductItemPDF>(`${this.url}/${data.id}/product_item_details/${data.prdd_id}/pdfs/${data.pdf_id}`, formData);
  }

  getGateways(id: string): Observable<IProductGateway[]> {
    return this.http.get<IProductGateway[]>(`${this.url}/${id}/product_gateways`);
  }

  updateGateway(id: string, data: IProductGateway): Observable<IProductGateway> {
    return this.http.patch<IProductGateway>(`${this.url}/${id}/product_gateways/${data.id}`, { gateway: data });
  }

  createGateway(id: string, data: IProductGateway): Observable<IProductGateway> {
    return this.http.post<IProductGateway>(`${this.url}/${id}/product_gateways`, { gateway: data });
  }

  deleteGateway(id: string, dataId: string): Observable<string> {
    return this.http.delete<string>(`${this.url}/${id}/product_gateways/${dataId}`);
  }

  productSearchByPrddId(id: string, prddId: string): Observable<IProductSearchByPrddIdResult> {
    return this.http.get<IProductSearchByPrddIdResult>(`${this.url}/${id}/product_gateways/find_by_prdd_id/${prddId}`);
  }

  doNotShow(id: string): Observable<string> {
    return this.http.post<{ product_id: string }>(`${this.url}/do_not_show/${id}`, { }).pipe(
      map(resp => resp.product_id),
    );
  }

  exportCSV(pubCode: string) {
    return this.http.get(`${this.url}/export_csv?pub_code=${pubCode}`, { responseType: 'blob' }).pipe(
      tap(response => {
        let binaryData = [];
        binaryData.push(response);
        let downloadLink = document.createElement('a');
        downloadLink.href = window.URL.createObjectURL(new Blob(binaryData, {type: 'xlsx'}));
        downloadLink.setAttribute('download', `${pubCode}_products.xlsx`);
        document.body.appendChild(downloadLink);
        downloadLink.click();
      })
    );
  }

  importCSV(file: File): Observable<void> {
    const formData: FormData = new FormData();
    formData.append('file', file);
    return this.http.post<void>(`${this.url}/import_csv`, formData)
  }

  docVaultSearch(query: string): Observable<{ [key: string]: string }> {
    return this.http.get<{ [key: string]: string }>(`${this.baseUrl}/apis/forms/v1/docvault_connects/${query}`);
  }

}
