export enum DashboardTypes {
  Open,
  Inventory,
  POD,
  PSSC,
  PBD,
};

export enum InventoryTypes {
  New = 'new_orders',
  Placed = 'placed_orders',
  Preordered = 'preordered_orders',
  Received = 'received_orders',
  Shipped = 'shipped_orders',
}
