import { IMissingPDFRequest } from 'app/shared/interfaces';

export namespace PDFError {
  export class GetAll {
    static readonly type = '[PDFErrors] Get PDF Errors';
  }
}

export namespace MissingPDF {
  export class GetAll {
    static readonly type = '[PDFErrors] Get Missing PDFs';
    constructor(public payload: IMissingPDFRequest) {}
  }

  export class Reset {
    static readonly type = '[PDFErrors] Reset Missing PDFs';
  }
}
