export enum VendorFields {
  vendorID = 'vendor_id',
  publisherCode = 'publisher_code',
  vendorName = 'vendor_name',
  comment = 'comment',
  accountID = 'account_id',
  payMethod = 'pay_method',
  orderingProcedureNote = 'ordering_procedure_note',
  description = 'description',
  bottomPromoTile = 'bottom_promo_tile',
  topPromoTile = 'top_promo_tile',
  tapeBindColor = 'tape_bind_color',
  availableTime = 'available_time',
  numberOfPrintsAllowed = 'number_of_prints_allowed',
  mostRecentFlag = 'most_recent_flag',
  subscribable = 'subscribable',
  royaltyRate = 'royalty_rate',
  displayYear = 'display_year',
  numberOfPrintsLifetime = 'number_of_prints_lifetime',
  fileopenPermission = 'fileopen_permission',
  saddleStitch = 'saddle_stitch',
  punch = 'punch',
  watermark = 'watermark',
  coverartMetadata = 'coverart_metadata',
  logo = 'logo',
  displayInPublisherList = 'display_in_publisher_list',
  trackable = 'trackable',
  infoUrl = 'info_url',
  last_saved_user = 'last_saved_user',
};

export enum ContactFields {
  contactId = 'contact_id',
  firstName = 'first_name',
  lastName = 'last_name',
  attention = 'attention',
  countryCode = 'country_code',
  streetAddress = 'street_address',
  apartment = 'apartment',
  zipCode = 'zip_code',
  zipCodeExt = 'zip_code_ext',
  postalCode = 'postal_code',
  stateProvince = 'state_province',
  city = 'city',
  default = 'default',
  company = 'company',
  phones = 'phones',
  emailIds = 'email_ids',
};

export enum ContactPhoneFields {
  P = 'P',
  AP1 = 'AP1',
  AP2 = 'AP2',
};

export enum ContactEmailFields {
  E = 'E',
  BE = 'BE',
  SE = 'SE',
};

export enum ContactListItemFields {
  contactId = 'contactId',
  default = 'default',
  fullName = 'fullName',
  company = 'company',
  fullAddress = 'fullAddress',
  phone = 'phone',
  emailId = 'emailId',
};

export enum SubVendorFields {
  id = 'id',
  publisherCode = 'publisher_code',
  trackable = 'trackable',
  display = 'display',
  numberOfItems = 'number_of_items',
  name = 'name',
}

export enum NoteFields {
  id = 'id',
  date = 'date',
  log = 'log',
  loggable_id = 'loggableId',
  loggable_type = 'loggableType',
  userName = 'username',
}

export enum RestrictionFields {
  id = 'id',
  countryCode = 'country_code',
  format = 'format',
  publisherCode = 'publisher_code',
}

export enum PriceCodeFields {
  id = 'id',
  priceCode = 'price_code',
  description = 'description',
  currencyCode = 'currency_code',
  listPrice = 'list_price',
  costNoStock = 'cost_no_stock',
  costInStock = 'cost_in_stock',
  priceNoStock = 'price_no_stock',
  priceInStock = 'price_in_stock',
  status = 'status',
  statusDate = 'status_date',
  exchangeRate = 'exchange_rate',
}

export enum MultiUserPricingFields {
  id = 'id',
  format = 'format',
  quantity = 'quantity',
  roundingArgument = 'rounding_argument',
  roundOrTrunc = 'round_or_trunc',
  priceMultiplier = 'price_multiplier',
  royaltyMultiplier = 'royalty_multiplier',
  vendorId = 'org_id_vnd',
  manufacturerId = 'org_id_mnfr',
}

export enum MultiUserExceptionFields {
  id = 'id',
  productId = 'product_id',
  publisherCode = 'publisher_code',
  vendorNumber = 'vendor_number',
}
