import { SearchType } from 'app/shared/enums';
import { ICoupon } from 'app/shared/interfaces';

export namespace Coupons {
  export class GetAll {
    static readonly type = '[Coupons] Get Coupons';
  }
  
  export class Update {
    static readonly type = '[Coupons] Update Coupon';
    constructor(public payload: ICoupon) { }
  }

  export class Create {
    static readonly type = '[Coupons] Create Coupon';
    constructor(public payload: ICoupon) { }
  }

  export class Delete {
    static readonly type = '[Coupons] Delete Coupon';
    constructor(public payload: number) { }
  }

  export class Find {
    static readonly type = '[Coupons] Find Coupons';
    constructor(public payload: string) { }
  }
}

export namespace GeneralPurpose {
  export class FindName {
    static readonly type = '[Coupons] Find Name';
    constructor(public payload: { type: SearchType, data: string }) { }
  }

  export class ResetSearch {
    static readonly type = '[Coupons] Reset Search';
  }
}
