import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NoteTypes } from 'app/shared/enums';
import { INote } from 'app/shared/interfaces';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class NotesService {

  readonly baseUrl = environment.api_host;

  constructor(
    private http: HttpClient,
  ) { }

  getNotes(noteType: NoteTypes, id: string): Observable<INote[]> {
    return this.http.get<INote[]>(`${this.baseUrl}/apis/forms/v1/activity_log_entries/${noteType}/${id}`);
  }

  createNote(data: INote): Observable<INote> {
    return this.http.post<INote>(`${this.baseUrl}/apis/forms/v1/activity_log_entries`, data);
  }

}
