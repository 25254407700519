<div>
  <span class='lefthead'><strong> Account Name: </strong>{{payment.person_first_name}} {{payment.person_last_name}}</span>&nbsp;&nbsp;
  <span class='lefthead'><strong> Account ID: </strong>{{payment.person_id}}</span>
  <a class="print_button" mat-raised-button color="primary"  routerLink="/printables/account-history/{{payment.id}}" target="_blank" type="button">Payment Report</a>
  
</div>
<br>
<br>

<div>
  <span class='lefthead'><strong> Payment ID : </strong>{{payment.id}}</span>
  <span class='righthead'><strong>Payment Type : </strong>{{ _validPaymentTypes[payment.method]}}</span>
</div>
<hr>
<mat-form-field>
  <input matInput [ngModel]="payment.status" placeholder="Status" [readonly]="true" disabled>

</mat-form-field>
<mat-form-field>
  <input matInput [ngModel]="payment.amount_paid | currency:'USD':'symbol'" placeholder="Amount Paid (USD)" [readonly]="true" disabled>

</mat-form-field>
<mat-form-field>
  <input matInput [ngModel]="payment.reference_date | date:'M/d/yyyy'"  placeholder="Reference Date" [readonly]="true" disabled>

</mat-form-field>
<mat-form-field>
  <input matInput [ngModel]="payment.payment_date | date:'M/d/yyyy'"  placeholder="Payment Date" [readonly]="true" disabled>

</mat-form-field><br>
<mat-form-field class="description">
  <input matInput [ngModel]="payment.description"   placeholder="Description" [readonly]="true" disabled>

</mat-form-field>
<h4>INVOICES PAID FOR</h4>
<table class="invoices" cellspacing="0" cellpadding="0">
  <thead>
    <tr>
      <th>Order ID</th>
      <th>Invoice ID</th>
      <th>Status</th>
      <th>Due Date</th>
      <th>Tax</th>
      <th>Ship Charge</th>
      <th>Total</th>
      <th>Payment Amount Applied</th>
      <th>Invoice Balance Amount</th>
    </tr>
  </thead>
  <tbody>
      <tr *ngFor="let inv of invoice "   >
          <td>{{inv.order.id}}</td>
          <td>{{inv.id}}</td>
          <td>{{inv.status}}</td>
          <td>{{inv.due_date | date:'M/d/yyyy, h:mm a'}}</td>
          <td>{{inv.tax | currency:'USD':'symbol'}}</td>
          <td>{{inv.ship_charge | currency:'USD':'symbol'}}</td>
          <td>{{inv.total | currency:'USD':'symbol'}}</td>
          <td>{{inv.applied_amount | currency:'USD':'symbol'}}</td>
          <td>{{inv.balance_amount | currency:'USD':'symbol'}}</td>
          </tr>

  </tbody>
</table><br>
<span class='lefthead'><strong>Currency: </strong>USD</span>
<h4>MISCELLANEOUS TRANSACTIONS (DEBITS)</h4>
<table class="invoices" cellspacing="0" cellpadding="0">
  <thead>
    <tr>
      <th>ID</th>
      <th>Status</th>
      <th>Type</th>
      <th>Description</th>
      <th>Date</th>
      <th>Total</th>
      <th>Payment Amount Applied</th>
      <th>Misc Debit Balance Amount</th>
    </tr>
  </thead>
  <tbody>
      <tr *ngFor="let misc of miscDetails " >
          <td>{{misc.id}}</td>
          <td>{{misc.status}}</td>
          <td>{{misc.type}}</td>
          <td>{{misc.description}}</td>
          <td>{{misc.date | date:'M/d/yyyy, h:mm a'}}</td>
          <td>{{misc.amount | currency:'USD':'symbol'}}</td>
          <td>{{misc.applied_amount | currency:'USD':'symbol'}}</td>
          <td>{{misc.balance | currency:'USD':'symbol'}}</td>
</tr>
  </tbody>
</table><br>
<span class='lefthead'><strong>Currency: </strong>USD</span>
