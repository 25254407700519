import * as i1$2 from '@angular/cdk/overlay';
import { OverlayModule } from '@angular/cdk/overlay';
import * as i1$1 from '@angular/cdk/portal';
import { PortalModule } from '@angular/cdk/portal';
import * as i2 from '@angular/common';
import { CommonModule } from '@angular/common';
import * as i0 from '@angular/core';
import { Component, ViewEncapsulation, ChangeDetectionStrategy, Inject, NgModule, Injectable, Optional, SkipSelf } from '@angular/core';
import { MatCommonModule } from '@angular/material/core';
import * as i3 from '@angular/material/legacy-button';
import { MatLegacyButtonModule } from '@angular/material/legacy-button';
import * as i1 from '@angular/material/snack-bar';
import { MAT_SNACK_BAR_DATA, _MatSnackBarContainerBase, matSnackBarAnimations, _MatSnackBarBase, MAT_SNACK_BAR_DEFAULT_OPTIONS } from '@angular/material/snack-bar';
export { MAT_SNACK_BAR_DATA as MAT_LEGACY_SNACK_BAR_DATA, MAT_SNACK_BAR_DEFAULT_OPTIONS as MAT_LEGACY_SNACK_BAR_DEFAULT_OPTIONS, MAT_SNACK_BAR_DEFAULT_OPTIONS_FACTORY as MAT_LEGACY_SNACK_BAR_DEFAULT_OPTIONS_FACTORY, MatSnackBarConfig as MatLegacySnackBarConfig, MatSnackBarRef as MatLegacySnackBarRef, _MatSnackBarBase as _MatLegacySnackBarBase, _MatSnackBarContainerBase as _MatLegacySnackBarContainerBase, matSnackBarAnimations as matLegacySnackBarAnimations } from '@angular/material/snack-bar';
import * as i2$1 from '@angular/cdk/a11y';
import * as i3$1 from '@angular/cdk/layout';

/**
 * A component used to open as the default snack bar, matching material spec.
 * This should only be used internally by the snack bar service.
 * @deprecated Use `SimpleSnackBar` from `@angular/material/snack-bar` instead. See https://material.angular.io/guide/mdc-migration for information about migrating.
 * @breaking-change 17.0.0
 */
class LegacySimpleSnackBar {
  constructor(snackBarRef, data) {
    this.snackBarRef = snackBarRef;
    this.data = data;
  }
  /** Performs the action on the snack bar. */
  action() {
    this.snackBarRef.dismissWithAction();
  }
  /** If the action button should be shown. */
  get hasAction() {
    return !!this.data.action;
  }
  static {
    this.ɵfac = i0.ɵɵngDeclareFactory({
      minVersion: "12.0.0",
      version: "16.1.1",
      ngImport: i0,
      type: LegacySimpleSnackBar,
      deps: [{
        token: i1.MatSnackBarRef
      }, {
        token: MAT_SNACK_BAR_DATA
      }],
      target: i0.ɵɵFactoryTarget.Component
    });
  }
  static {
    this.ɵcmp = i0.ɵɵngDeclareComponent({
      minVersion: "14.0.0",
      version: "16.1.1",
      type: LegacySimpleSnackBar,
      selector: "simple-snack-bar",
      host: {
        classAttribute: "mat-simple-snackbar"
      },
      ngImport: i0,
      template: "<span class=\"mat-simple-snack-bar-content\">{{data.message}}</span>\n<div class=\"mat-simple-snackbar-action\"  *ngIf=\"hasAction\">\n  <button mat-button (click)=\"action()\">{{data.action}}</button>\n</div>\n",
      styles: [".mat-simple-snackbar{display:flex;justify-content:space-between;align-items:center;line-height:20px;opacity:1}.mat-simple-snackbar-action{flex-shrink:0;margin:-8px -8px -8px 8px}.mat-simple-snackbar-action button{max-height:36px;min-width:0}[dir=rtl] .mat-simple-snackbar-action{margin-left:-8px;margin-right:8px}.mat-simple-snack-bar-content{overflow:hidden;text-overflow:ellipsis}"],
      dependencies: [{
        kind: "directive",
        type: i2.NgIf,
        selector: "[ngIf]",
        inputs: ["ngIf", "ngIfThen", "ngIfElse"]
      }, {
        kind: "component",
        type: i3.MatLegacyButton,
        selector: "button[mat-button], button[mat-raised-button], button[mat-icon-button],             button[mat-fab], button[mat-mini-fab], button[mat-stroked-button],             button[mat-flat-button]",
        inputs: ["disabled", "disableRipple", "color"],
        exportAs: ["matButton"]
      }],
      changeDetection: i0.ChangeDetectionStrategy.OnPush,
      encapsulation: i0.ViewEncapsulation.None
    });
  }
}
i0.ɵɵngDeclareClassMetadata({
  minVersion: "12.0.0",
  version: "16.1.1",
  ngImport: i0,
  type: LegacySimpleSnackBar,
  decorators: [{
    type: Component,
    args: [{
      selector: 'simple-snack-bar',
      encapsulation: ViewEncapsulation.None,
      changeDetection: ChangeDetectionStrategy.OnPush,
      host: {
        'class': 'mat-simple-snackbar'
      },
      template: "<span class=\"mat-simple-snack-bar-content\">{{data.message}}</span>\n<div class=\"mat-simple-snackbar-action\"  *ngIf=\"hasAction\">\n  <button mat-button (click)=\"action()\">{{data.action}}</button>\n</div>\n",
      styles: [".mat-simple-snackbar{display:flex;justify-content:space-between;align-items:center;line-height:20px;opacity:1}.mat-simple-snackbar-action{flex-shrink:0;margin:-8px -8px -8px 8px}.mat-simple-snackbar-action button{max-height:36px;min-width:0}[dir=rtl] .mat-simple-snackbar-action{margin-left:-8px;margin-right:8px}.mat-simple-snack-bar-content{overflow:hidden;text-overflow:ellipsis}"]
    }]
  }],
  ctorParameters: function () {
    return [{
      type: i1.MatSnackBarRef
    }, {
      type: undefined,
      decorators: [{
        type: Inject,
        args: [MAT_SNACK_BAR_DATA]
      }]
    }];
  }
});

/**
 * Internal component that wraps user-provided snack bar content.
 * @docs-private
 * @deprecated Use `MatSnackBarContainer` from `@angular/material/snack-bar` instead. See https://material.angular.io/guide/mdc-migration for information about migrating.
 * @breaking-change 17.0.0
 */
class MatLegacySnackBarContainer extends _MatSnackBarContainerBase {
  _afterPortalAttached() {
    super._afterPortalAttached();
    if (this.snackBarConfig.horizontalPosition === 'center') {
      this._elementRef.nativeElement.classList.add('mat-snack-bar-center');
    }
    if (this.snackBarConfig.verticalPosition === 'top') {
      this._elementRef.nativeElement.classList.add('mat-snack-bar-top');
    }
  }
  static {
    this.ɵfac = i0.ɵɵngDeclareFactory({
      minVersion: "12.0.0",
      version: "16.1.1",
      ngImport: i0,
      type: MatLegacySnackBarContainer,
      deps: null,
      target: i0.ɵɵFactoryTarget.Component
    });
  }
  static {
    this.ɵcmp = i0.ɵɵngDeclareComponent({
      minVersion: "14.0.0",
      version: "16.1.1",
      type: MatLegacySnackBarContainer,
      selector: "snack-bar-container",
      host: {
        listeners: {
          "@state.done": "onAnimationEnd($event)"
        },
        properties: {
          "@state": "_animationState"
        },
        classAttribute: "mat-snack-bar-container"
      },
      usesInheritance: true,
      ngImport: i0,
      template: "<!-- Initially holds the snack bar content, will be empty after announcing to screen readers. -->\n<div aria-hidden=\"true\">\n  <ng-template cdkPortalOutlet></ng-template>\n</div>\n\n<!-- Will receive the snack bar content from the non-live div, move will happen a short delay after opening -->\n<div [attr.aria-live]=\"_live\" [attr.role]=\"_role\" [attr.id]=\"_liveElementId\"></div>\n",
      styles: [".mat-snack-bar-container{border-radius:4px;box-sizing:border-box;display:block;margin:24px;max-width:33vw;min-width:344px;padding:14px 16px;min-height:48px;transform-origin:center}.cdk-high-contrast-active .mat-snack-bar-container{border:solid 1px}.mat-snack-bar-handset{width:100%}.mat-snack-bar-handset .mat-snack-bar-container{margin:8px;max-width:100%;min-width:0;width:100%}"],
      dependencies: [{
        kind: "directive",
        type: i1$1.CdkPortalOutlet,
        selector: "[cdkPortalOutlet]",
        inputs: ["cdkPortalOutlet"],
        outputs: ["attached"],
        exportAs: ["cdkPortalOutlet"]
      }],
      animations: [matSnackBarAnimations.snackBarState],
      changeDetection: i0.ChangeDetectionStrategy.Default,
      encapsulation: i0.ViewEncapsulation.None
    });
  }
}
i0.ɵɵngDeclareClassMetadata({
  minVersion: "12.0.0",
  version: "16.1.1",
  ngImport: i0,
  type: MatLegacySnackBarContainer,
  decorators: [{
    type: Component,
    args: [{
      selector: 'snack-bar-container',
      changeDetection: ChangeDetectionStrategy.Default,
      encapsulation: ViewEncapsulation.None,
      animations: [matSnackBarAnimations.snackBarState],
      host: {
        'class': 'mat-snack-bar-container',
        '[@state]': '_animationState',
        '(@state.done)': 'onAnimationEnd($event)'
      },
      template: "<!-- Initially holds the snack bar content, will be empty after announcing to screen readers. -->\n<div aria-hidden=\"true\">\n  <ng-template cdkPortalOutlet></ng-template>\n</div>\n\n<!-- Will receive the snack bar content from the non-live div, move will happen a short delay after opening -->\n<div [attr.aria-live]=\"_live\" [attr.role]=\"_role\" [attr.id]=\"_liveElementId\"></div>\n",
      styles: [".mat-snack-bar-container{border-radius:4px;box-sizing:border-box;display:block;margin:24px;max-width:33vw;min-width:344px;padding:14px 16px;min-height:48px;transform-origin:center}.cdk-high-contrast-active .mat-snack-bar-container{border:solid 1px}.mat-snack-bar-handset{width:100%}.mat-snack-bar-handset .mat-snack-bar-container{margin:8px;max-width:100%;min-width:0;width:100%}"]
    }]
  }]
});

/**
 * @deprecated Use `MatSnackBarModule` from `@angular/material/snack-bar` instead. See https://material.angular.io/guide/mdc-migration for information about migrating.
 * @breaking-change 17.0.0
 */
class MatLegacySnackBarModule {
  static {
    this.ɵfac = i0.ɵɵngDeclareFactory({
      minVersion: "12.0.0",
      version: "16.1.1",
      ngImport: i0,
      type: MatLegacySnackBarModule,
      deps: [],
      target: i0.ɵɵFactoryTarget.NgModule
    });
  }
  static {
    this.ɵmod = i0.ɵɵngDeclareNgModule({
      minVersion: "14.0.0",
      version: "16.1.1",
      ngImport: i0,
      type: MatLegacySnackBarModule,
      declarations: [MatLegacySnackBarContainer, LegacySimpleSnackBar],
      imports: [OverlayModule, PortalModule, CommonModule, MatLegacyButtonModule, MatCommonModule],
      exports: [MatLegacySnackBarContainer, MatCommonModule]
    });
  }
  static {
    this.ɵinj = i0.ɵɵngDeclareInjector({
      minVersion: "12.0.0",
      version: "16.1.1",
      ngImport: i0,
      type: MatLegacySnackBarModule,
      imports: [OverlayModule, PortalModule, CommonModule, MatLegacyButtonModule, MatCommonModule, MatCommonModule]
    });
  }
}
i0.ɵɵngDeclareClassMetadata({
  minVersion: "12.0.0",
  version: "16.1.1",
  ngImport: i0,
  type: MatLegacySnackBarModule,
  decorators: [{
    type: NgModule,
    args: [{
      imports: [OverlayModule, PortalModule, CommonModule, MatLegacyButtonModule, MatCommonModule],
      exports: [MatLegacySnackBarContainer, MatCommonModule],
      declarations: [MatLegacySnackBarContainer, LegacySimpleSnackBar]
    }]
  }]
});

/**
 * Service to dispatch Material Design snack bar messages.
 * @deprecated Use `MatSnackBar` from `@angular/material/snack-bar` instead. See https://material.angular.io/guide/mdc-migration for information about migrating.
 * @breaking-change 17.0.0
 */
class MatLegacySnackBar extends _MatSnackBarBase {
  constructor(overlay, live, injector, breakpointObserver, parentSnackBar, defaultConfig) {
    super(overlay, live, injector, breakpointObserver, parentSnackBar, defaultConfig);
    this.simpleSnackBarComponent = LegacySimpleSnackBar;
    this.snackBarContainerComponent = MatLegacySnackBarContainer;
    this.handsetCssClass = 'mat-snack-bar-handset';
  }
  static {
    this.ɵfac = i0.ɵɵngDeclareFactory({
      minVersion: "12.0.0",
      version: "16.1.1",
      ngImport: i0,
      type: MatLegacySnackBar,
      deps: [{
        token: i1$2.Overlay
      }, {
        token: i2$1.LiveAnnouncer
      }, {
        token: i0.Injector
      }, {
        token: i3$1.BreakpointObserver
      }, {
        token: MatLegacySnackBar,
        optional: true,
        skipSelf: true
      }, {
        token: MAT_SNACK_BAR_DEFAULT_OPTIONS
      }],
      target: i0.ɵɵFactoryTarget.Injectable
    });
  }
  static {
    this.ɵprov = i0.ɵɵngDeclareInjectable({
      minVersion: "12.0.0",
      version: "16.1.1",
      ngImport: i0,
      type: MatLegacySnackBar,
      providedIn: MatLegacySnackBarModule
    });
  }
}
i0.ɵɵngDeclareClassMetadata({
  minVersion: "12.0.0",
  version: "16.1.1",
  ngImport: i0,
  type: MatLegacySnackBar,
  decorators: [{
    type: Injectable,
    args: [{
      providedIn: MatLegacySnackBarModule
    }]
  }],
  ctorParameters: function () {
    return [{
      type: i1$2.Overlay
    }, {
      type: i2$1.LiveAnnouncer
    }, {
      type: i0.Injector
    }, {
      type: i3$1.BreakpointObserver
    }, {
      type: MatLegacySnackBar,
      decorators: [{
        type: Optional
      }, {
        type: SkipSelf
      }]
    }, {
      type: i1.MatSnackBarConfig,
      decorators: [{
        type: Inject,
        args: [MAT_SNACK_BAR_DEFAULT_OPTIONS]
      }]
    }];
  }
});

/**
 * Generated bundle index. Do not edit.
 */

export { LegacySimpleSnackBar, MatLegacySnackBar, MatLegacySnackBarContainer, MatLegacySnackBarModule };
