import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SearchType } from 'app/shared/enums';
import { IDiscount } from 'app/shared/interfaces';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class DiscountsService {

  readonly baseUrl = environment.api_host;
  readonly url = `${this.baseUrl}/apis/forms/v1/discounts`;

  constructor(
    private http: HttpClient,
  ) { }

  getDiscounts(): Observable<IDiscount[]> {
    return this.http.get<IDiscount[]>(`${this.url}`);
  }

  createDiscount(data: IDiscount): Observable<IDiscount> {
    return this.http.post<IDiscount>(`${this.url}`, { discount: data });
  }

  updateDiscount(data: IDiscount): Observable<IDiscount> {
    return this.http.patch<IDiscount>(`${this.url}/${data.id}`, { discount: data });
  }

  deleteDiscount(id: number): Observable<number> {
    return this.http.delete<{ id: string }>(`${this.url}/${id}`).pipe(
      map(resp => Number(resp.id)),
    );
  }

  findVendor(data: string): Observable<string> {
    return this.http.get<{ name: string }>(`${this.baseUrl}/apis/forms/v1/data_management/coupons/find_by/${SearchType.Vendor}/${data}`).pipe(
      map(response => response.name),
    );
  }

  findManufacturer(data: string): Observable<string> {
    return this.http.get<{ name: string }>(`${this.baseUrl}/apis/forms/v1/data_management/coupons/find_by/${SearchType.Manufacturer}/${data}`).pipe(
      map(response => response.name),
    );
  }

}
