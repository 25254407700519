export enum ProductFields {
  subscribable,
  product_id = 'product_id',
  publisher_code = 'publisher_code',
  vendor = 'vendor',
  subscription_price = 'subscription_price',
  product_name = 'product_name',
  edition_date = 'edition_date',
  ship_note = 'ship_note',
  org_id = 'org_id',
  vendor_id = 'vendor_id',
  subgroup_ids = 'subgroup_ids',
  org_name = 'org_name',
  product_code = 'product_code',
  withdraw = 'withdraw',
  multi_user_exception = 'multi_user_exception',
  description_file = 'description_file',
  ansi_flag = 'ansi_flag',
  publication_units = 'publication_units',
  author = 'author',
  issn = 'issn',
  isbn = 'isbn',
  keywords = 'keywords',
  edition_name = 'edition_name',
  delete = 'delete',
  publ_edition_date = 'publ_edition_date',
  cover_art = 'cover_art',
  preview = 'preview',
  do_not_show = 'do_not_show',
  needs_sync = 'needs_sync',
  last_saved_user = 'last_saved_user',
  subscription_currency = 'subscription_currency',
  mysql_prd_id = 'mysql_prd_id',
  gid = 'gid',
  rev_id = 'rev_id',
  fam_id = 'fam_id',
  is_most_recent = 'is_most_recent',
  is_subscribable = 'subscribable',
  is_retail = 'retail',
  is_base = 'is_base',
  datasync_failed = 'datasync_failed',
}

export enum PublishTypes {
  My = 'my',
  All = 'all',
  Single = 'single',
}

export enum RelationTypes {
  Converse = 'converse',
  Other = 'other',
}
