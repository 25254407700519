import { Injectable } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { Action, State, StateContext } from '@ngxs/store';
import { append, patch, removeItem, updateItem } from '@ngxs/store/operators';
import { HTTP_MESSAGES } from 'app/shared/constants';
import {
  IBundle,
  IBundleFormats,
  IProductGateway,
  IProduct,
  IProductEvent,
  IProductItem,
  IProductItemPricecode,
  IProductListItem,
  IProductNote,
  IProductSearchByPrddIdResult,
  IProductSearchResult,
  IRelation,
  IProductSubGroup,
  IProductOrganizationSearchResult,
  ISubGroup,
  IRelationsTree,
  IListWithPagination,
} from 'app/shared/interfaces';
import { combineLatest, of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { AlertService, ErrorHandlerService, NavigationService, ProductsService } from '../services';
import * as actions from './action-namespaces/products';

export interface ProductsState {
  loading: boolean;
  unpublishedProducts: IProductListItem[];
  recentProducts: IListWithPagination<IProductListItem>;
  recentProductsLoading: boolean;
  unpublishedCount: number;
  myUnpublishedCount: number;
  preorderedProducts: IProductListItem[];
  subGroups: IProductSubGroup[];
  subGroup: IProductSubGroup;
  product: IProduct;
  productNotes: IProductNote[];
  productNotesLoading: boolean;
  bundles: IBundle[];
  bundlesLoading: boolean;
  formats: IBundleFormats;
  events: IProductEvent[];
  eventsLoading: boolean;
  relationsConverse: IRelation[];
  relationsOther: IRelation[];
  relationsTree: IRelationsTree;
  productSearch: IProductSearchResult;
  items: IProductItem[];
  gateways: IProductGateway[];
  productSearchByPrddId: IProductSearchByPrddIdResult;
  priceCodes: IProductItemPricecode[];
  searchOrganization: IProductOrganizationSearchResult;
  searchSubGroup: ISubGroup;
  docVaultSearchResult: { [key: string]: string };
}

@State<ProductsState>({
  name: 'products',
  defaults: {
    loading: false,
    unpublishedProducts: [],
    recentProducts: { data: [], total_count: 0 },
    recentProductsLoading: false,
    unpublishedCount: null,
    myUnpublishedCount: null,
    preorderedProducts: [],
    subGroups: [],
    subGroup: null,
    product: null,
    productNotes: [],
    productNotesLoading: false,
    bundles: [],
    bundlesLoading: false,
    formats: null,
    events: [],
    eventsLoading: false,
    relationsConverse: [],
    relationsOther: [],
    relationsTree: null,
    productSearch: null,
    items: [],
    gateways: [],
    productSearchByPrddId: null,
    priceCodes: [],
    searchOrganization: null,
    searchSubGroup: null,
    docVaultSearchResult: null,
  }
})
@Injectable()
export class ProductsStore {
  constructor(
    private readonly productsService: ProductsService,
    private readonly alertService: AlertService,
    private readonly dialog: MatDialog,
    private readonly errorHandlerService: ErrorHandlerService,
    private readonly navigationService: NavigationService,
  ) { }

  @Action(actions.Product.GetLandingPageList)
  getLandingPageList(ctx: StateContext<ProductsState>) {
    return combineLatest([
      this.productsService.getUnpublishedProducts(),
      this.productsService.getPreorderedProducts(),
    ]).pipe(
      tap(([
        unpublishedProducts,
        preorderedProducts,
      ]) => ctx.patchState({
        unpublishedProducts,
        preorderedProducts,
      })),
      catchError(e => this.errorHandlerService.handleError(HTTP_MESSAGES.generalError, e, ctx)),
    );
  };

  @Action(actions.Product.GetRecentProducts)
  getRecentProducts(ctx: StateContext<ProductsState>, action: actions.Product.GetRecentProducts) {
    ctx.patchState({ recentProductsLoading: true });
    return this.productsService.getRecentProducts(action.payload).pipe(
      tap(response => ctx.patchState({
        recentProducts: response,
        recentProductsLoading: false,
        unpublishedCount: response.unpublished_count,
        myUnpublishedCount: response.my_unpublished_count,
      })),
      catchError(e => this.errorHandlerService.handleError(HTTP_MESSAGES.generalError, e, ctx)),
    );
  };

  @Action(actions.Product.ResetProductLists)
  resetProductLists(ctx: StateContext<ProductsState>) {
    ctx.patchState({
      recentProducts: { data: [], total_count: 0 },
      preorderedProducts: [],
      unpublishedProducts: [],
    })
  };

  @Action(actions.SubGroup.SearchOrganization)
  searchOrganization(ctx: StateContext<ProductsState>, action: actions.SubGroup.SearchOrganization) {
    ctx.patchState({ searchOrganization: null });
    return this.productsService.searchOrganization(action.payload).pipe(
      tap(searchOrganization => ctx.patchState({ searchOrganization })),
      catchError(e => this.errorHandlerService.handleError(HTTP_MESSAGES.generalError, e, ctx)),
    );
  };

  @Action(actions.SubGroup.SearchSubGroup)
  searchSubGroup(ctx: StateContext<ProductsState>, action: actions.SubGroup.SearchSubGroup) {
    ctx.patchState({ searchSubGroup: null });
    return this.productsService.searchSubGroup(action.payload).pipe(
      tap(searchSubGroup => ctx.patchState({ searchSubGroup })),
      catchError(e => this.errorHandlerService.handleError(HTTP_MESSAGES.generalError, e, ctx)),
    );
  };

  @Action(actions.SubGroup.GetSubGroups)
  getSubGroups(ctx: StateContext<ProductsState>) {
    ctx.patchState({ subGroups: [] });
    const productId = ctx.getState().product.product_id;
    return this.productsService.getSubGroups(productId).pipe(
      tap(subGroups => ctx.patchState({ subGroups })),
      catchError(e => this.errorHandlerService.handleError(HTTP_MESSAGES.generalError, e, ctx)),
    );
  };

  @Action(actions.SubGroup.AddSubGroup)
  addSubGroup(ctx: StateContext<ProductsState>, action: actions.SubGroup.AddSubGroup) {
    const product = ctx.getState().product;
    return this.productsService.addSubGroup(action.payload, product.product_id).pipe(
      tap(subGroup => {
        this.alertService.showAlert({
          message: HTTP_MESSAGES.generalSuccess,
          type: 'SUCCESS',
        });
        ctx.patchState({
          subGroups: [ ...ctx.getState().subGroups, subGroup ],
          product: {
            ...product,
            needs_sync: true,
          },
        });
      }),
      catchError(e => this.errorHandlerService.handleError(HTTP_MESSAGES.generalError, e, ctx)),
    );
  };

  @Action(actions.SubGroup.DeleteSubGroup)
  deleteSubGroup(ctx: StateContext<ProductsState>, action: actions.SubGroup.DeleteSubGroup) {
    const product = ctx.getState().product;
    return this.productsService.deleteSubGroup(action.payload, product.product_id).pipe(
      tap(() => {
        this.alertService.showAlert({
          message: HTTP_MESSAGES.deleteSuccess,
          type: 'SUCCESS',
        });
        ctx.patchState({
          subGroups: ctx.getState().subGroups.filter(s => s.subgroup_id !== action.payload),
          product: {
            ...product,
            needs_sync: true,
          },
        });
      }),
      catchError(e => this.errorHandlerService.handleError(HTTP_MESSAGES.generalError, e, ctx)),
    );
  };

  @Action(actions.SubGroup.CreateSubGroup)
  createSubGroup(ctx: StateContext<ProductsState>, action: actions.SubGroup.CreateSubGroup) {
    const product = ctx.getState().product;
    return this.productsService.createProductSubGroup(product.product_id, action.payload).pipe(
      tap(subGroup => {
        this.dialog.closeAll();
        this.alertService.showAlert({ message: HTTP_MESSAGES.createSuccess, type: 'SUCCESS' });
        return ctx.patchState({
          subGroups: [ ...ctx.getState().subGroups, subGroup ],
          product: {
            ...product,
            needs_sync: true,
          },
        });
      }),
      catchError(e => this.errorHandlerService.handleError(HTTP_MESSAGES.createFail, e, ctx)),
    );
  };

  @Action(actions.SubGroup.UpdateSubGroup)
  updateSubGroup(ctx: StateContext<ProductsState>, action: actions.SubGroup.UpdateSubGroup) {
    const product = ctx.getState().product;
    return this.productsService.updateProductSubGroup(product.product_id, action.payload).pipe(
      tap(subGroup => {
        this.dialog.closeAll();
        this.alertService.showAlert({ message: HTTP_MESSAGES.updateSuccess, type: 'SUCCESS' });
        return ctx.patchState({
          subGroups: ctx.getState().subGroups.map(s => s.subgroup_id == subGroup.subgroup_id ? subGroup : s),
          product: {
            ...product,
            needs_sync: true,
          },
        });
      }),
      catchError(e => this.errorHandlerService.handleError(HTTP_MESSAGES.updateFail, e, ctx)),
    );
  };

  @Action(actions.SubGroup.GetSubGroup)
  getSubGroup(ctx: StateContext<ProductsState>, action: actions.SubGroup.GetSubGroup) {
    ctx.patchState({ subGroup: null, loading: true });
    return this.productsService.getSubGroup(action.payload).pipe(
      tap(subGroup => ctx.patchState({ subGroup, loading: false })),
      catchError(e => this.errorHandlerService.handleError(HTTP_MESSAGES.generalError, e, ctx)),
    );
  };

  @Action(actions.GeneralPurpose.ResetProductsField)
  resetField(ctx: StateContext<ProductsState>, action: actions.GeneralPurpose.ResetProductsField) {
    const { name, value } = action.payload;
    ctx.patchState({ [name]: value });
  };

  @Action(actions.Product.Create)
  createProduct(ctx: StateContext<ProductsState>, action: actions.Product.Create) {
    ctx.patchState({ loading: true });
    return this.productsService.createProduct(action.payload).pipe(
      tap(id => {
        this.dialog.closeAll();
        this.alertService.showAlert({
          message: HTTP_MESSAGES.createSuccess,
          type: 'SUCCESS',
        });
        ctx.patchState({ loading: false });
        this.navigationService.navigate([`products/${id}`]);
      }),
      catchError(e => this.errorHandlerService.handleError(HTTP_MESSAGES.createFail, e, ctx)),
    );
  };

  @Action(actions.Product.Copy)
  copyProduct(ctx: StateContext<ProductsState>, action: actions.Product.Copy) {
    const productId = ctx.getState().product.product_id;
    return this.productsService.copyProduct(productId).pipe(
      tap(id => {
        this.alertService.showAlert({
          message: 'Product successfully copied',
          type: 'SUCCESS',
        });
        this.navigationService.navigate([`products/${id}`]);
      }),
      catchError(e => this.errorHandlerService.handleError(HTTP_MESSAGES.generalError, e, ctx)),
    );
  };

  @Action(actions.Product.GetOne)
  getProduct(ctx: StateContext<ProductsState>, action: actions.Product.GetOne) {
    ctx.patchState({ product: null, loading: true });
    return this.productsService.getProduct(action.payload).pipe(
      tap(product => {
        ctx.patchState({ product, loading: false });
        ctx.dispatch(new actions.Item.GetAll());
      }),
      catchError(e => this.errorHandlerService.handleError(HTTP_MESSAGES.generalError, e, ctx)),
    );
  };

  @Action(actions.Product.UpdateOrgAndSubGroups)
  updateOrgAndSubGroups(ctx: StateContext<ProductsState>, action: actions.Product.UpdateOrgAndSubGroups) {
    ctx.patchState({ loading: true });
    const product = ctx.getState().product;
    return this.productsService.updateOrgAndSubGroups(product.product_id, action.payload).pipe(
      tap(() => {
        ctx.patchState({
          product: {
            ...product,
            needs_sync: true,
          },
        })
        return this.handleUpdateSuccess(ctx, product.product_id);
      }),
      catchError(e => this.errorHandlerService.handleError(HTTP_MESSAGES.updateFail, e, ctx)),
    );
  };

  @Action(actions.Product.UpdateInfo)
  updateInfo(ctx: StateContext<ProductsState>, action: actions.Product.UpdateInfo) {
    ctx.patchState({ loading: true });
    const productId = ctx.getState().product.product_id;
    return this.productsService.updateInfo(productId, action.payload).pipe(
      tap(() => this.handleUpdateSuccess(ctx, productId)),
      catchError(e => this.errorHandlerService.handleError(HTTP_MESSAGES.updateFail, e, ctx)),
    );
  };

  @Action(actions.Product.Publish)
  publishProduct(ctx: StateContext<ProductsState>, action: actions.Product.Publish) {
    const { type, id, refetchProducts } = action.payload;
    const product = ctx.getState().product;
    return this.productsService.publishProducts(type, id).pipe(
      tap(message => {
        this.dialog.closeAll();
        this.alertService.showAlert({ message, type: message === 'Success' ? 'SUCCESS' : 'ERROR' });
        if (message === 'Success' && id) {
          ctx.patchState({
            product: product ? {
              ...product,
              needs_sync: false,
              last_published_date_time: new Date(),
            } : null,
          })
        }
        if (refetchProducts) ctx.dispatch(new actions.Product.GetLandingPageList);
      }),
      catchError(e => this.errorHandlerService.handleError(HTTP_MESSAGES.updateFail, e, ctx)),
    );
  };

  @Action(actions.ProductNotes.GetAll)
  getProductNotes(ctx: StateContext<ProductsState>) {
    ctx.patchState({ productNotes: [], loading: true });
    const productId = ctx.getState().product.product_id;
    return this.productsService.getProductNotes(productId).pipe(
      tap(productNotes => ctx.patchState({ productNotes, loading: false })),
      catchError(e => this.errorHandlerService.handleError(HTTP_MESSAGES.generalError, e, ctx)),
    );
  };

  @Action(actions.ProductNotes.Update)
  updateProductNote(ctx: StateContext<ProductsState>, action: actions.ProductNotes.Update) {
    ctx.patchState({ loading: true });
    const product = ctx.getState().product;
    return this.productsService.updateProductNote(product.product_id, action.payload).pipe(
      tap(note => {
        ctx.setState(patch({
          productNotes: updateItem<IProductNote>(n => n.id === note.id, note),
          product: {
            ...product,
            needs_sync: true,
          },
          loading: false,
        }));
        this.dialog.closeAll();
        this.alertService.showAlert({ message: HTTP_MESSAGES.updateSuccess, type: 'SUCCESS' });
      }),
      catchError(e => this.errorHandlerService.handleError(HTTP_MESSAGES.updateFail, e, ctx)),
    );
  };

  @Action(actions.ProductNotes.Create)
  createProductNote(ctx: StateContext<ProductsState>, action: actions.ProductNotes.Create) {
    ctx.patchState({ loading: true });
    const product = ctx.getState().product;
    return this.productsService.createProductNote(product.product_id, action.payload).pipe(
      tap(note => {
        ctx.setState(patch({
          productNotes: append([note]),
          product: {
            ...product,
            needs_sync: true,
          },
          loading: false,
        }));
        this.dialog.closeAll();
        this.alertService.showAlert({ message: HTTP_MESSAGES.createSuccess, type: 'SUCCESS' });
      }),
      catchError(e => this.errorHandlerService.handleError(HTTP_MESSAGES.createFail, e, ctx)),
    );
  };

  @Action(actions.ProductNotes.Delete)
  deleteProductNote(ctx: StateContext<ProductsState>, action: actions.ProductNotes.Delete) {
    ctx.patchState({ loading: true });
    const product = ctx.getState().product;
    return this.productsService.deleteProductNote(product.product_id, action.payload).pipe(
      tap(() => {
        ctx.setState(patch({
          productNotes: removeItem<IProductNote>(n => n.id === action.payload),
          product: {
            ...product,
            needs_sync: true,
          },
          loading: false,
        }));
        this.dialog.closeAll();
        this.alertService.showAlert({ message: HTTP_MESSAGES.deleteSuccess, type: 'SUCCESS' });
        ctx.dispatch(new actions.Product.GetOne(product.product_id))
      }),
      catchError(e => this.errorHandlerService.handleError(HTTP_MESSAGES.deleteFail, e, ctx)),
    );
  };

  @Action(actions.Bundle.GetAll)
  getBundles(ctx: StateContext<ProductsState>) {
    ctx.patchState({ bundles: [], bundlesLoading: true });
    const productId = ctx.getState().product.product_id;
    return this.productsService.getBundles(productId).pipe(
      tap(bundles => ctx.patchState({ bundles, bundlesLoading: false })),
      catchError(e => this.errorHandlerService.handleError(HTTP_MESSAGES.generalError, e, ctx)),
    );
  };

  @Action(actions.Bundle.Update)
  updateBundle(ctx: StateContext<ProductsState>, action: actions.Bundle.Update) {
    ctx.patchState({ bundlesLoading: true });
    const product = ctx.getState().product;
    return this.productsService.updateBundle(product.product_id, action.payload).pipe(
      tap(bundle => {
        ctx.setState(patch({
          bundles: updateItem<IBundle>(n => n.id === bundle.id, bundle),
          product: {
            ...product,
            needs_sync: true,
          },
          bundlesLoading: false,
        }));
        this.dialog.closeAll();
        this.alertService.showAlert({ message: HTTP_MESSAGES.updateSuccess, type: 'SUCCESS' });
      }),
      catchError(e => this.errorHandlerService.handleError(HTTP_MESSAGES.updateFail, e, ctx)),
    );
  };

  @Action(actions.Bundle.Create)
  createBundle(ctx: StateContext<ProductsState>, action: actions.Bundle.Create) {
    ctx.patchState({ bundlesLoading: true });
    const product = ctx.getState().product;
    return this.productsService.createBundle(product.product_id, action.payload).pipe(
      tap(bundle => {
        ctx.setState(patch({
          bundles: append([bundle]),
          product: {
            ...product,
            needs_sync: true,
          },
          bundlesLoading: false,
        }));
        this.dialog.closeAll();
        this.alertService.showAlert({ message: HTTP_MESSAGES.createSuccess, type: 'SUCCESS' });
      }),
      catchError(e => this.errorHandlerService.handleError(HTTP_MESSAGES.createFail, e, ctx)),
    );
  };

  @Action(actions.Bundle.Delete)
  deleteBundle(ctx: StateContext<ProductsState>, action: actions.Bundle.Delete) {
    ctx.patchState({ bundlesLoading: true });
    const product = ctx.getState().product;
    return this.productsService.deleteBundle(product.product_id, action.payload).pipe(
      tap(() => {
        ctx.setState(patch({
          bundles: removeItem<IBundle>(n => n.id === action.payload),
          product: {
            ...product,
            needs_sync: true,
          },
          bundlesLoading: false,
        }));
        this.dialog.closeAll();
        this.alertService.showAlert({ message: HTTP_MESSAGES.deleteSuccess, type: 'SUCCESS' });
      }),
      catchError(e => this.errorHandlerService.handleError(HTTP_MESSAGES.deleteFail, e, ctx)),
    );
  };

  @Action(actions.Bundle.GetFormats)
  getFormats(ctx: StateContext<ProductsState>) {
    const productId = ctx.getState().product.product_id;
    return this.productsService.getBundleFormats(productId).pipe(
      tap(formats => ctx.patchState({ formats })),
      catchError(e => this.errorHandlerService.handleError(HTTP_MESSAGES.generalError, e, ctx)),
    );
  };

  @Action(actions.Event.GetAll)
  getEvents(ctx: StateContext<ProductsState>) {
    ctx.patchState({ events: [], eventsLoading: true });
    const productId = ctx.getState().product.product_id;
    return this.productsService.getEvents(productId).pipe(
      tap(events => ctx.patchState({ events, eventsLoading: false })),
      catchError(e => this.errorHandlerService.handleError(HTTP_MESSAGES.generalError, e, ctx)),
    );
  };

  @Action(actions.Event.Update)
  updateEvent(ctx: StateContext<ProductsState>, action: actions.Event.Update) {
    ctx.patchState({ eventsLoading: true });
    const product = ctx.getState().product;
    return this.productsService.updateEvent(product.product_id, action.payload).pipe(
      tap(event => {
        ctx.setState(patch({
          events: updateItem<IProductEvent>(n => n.id === event.id, event),
          product: {
            ...product,
            needs_sync: true,
          },
          eventsLoading: false,
        }));
        this.dialog.closeAll();
        this.alertService.showAlert({ message: HTTP_MESSAGES.updateSuccess, type: 'SUCCESS' });
      }),
      catchError(e => this.errorHandlerService.handleError(HTTP_MESSAGES.updateFail, e, ctx)),
    );
  };

  @Action(actions.Event.Create)
  createEvent(ctx: StateContext<ProductsState>, action: actions.Event.Create) {
    ctx.patchState({ eventsLoading: true });
    const product = ctx.getState().product;
    return this.productsService.createEvent(product.product_id, action.payload).pipe(
      tap(event => {
        ctx.setState(patch({
          events: append([event]),
          product: {
            ...product,
            needs_sync: true,
          },
          eventsLoading: false,
        }));
        this.dialog.closeAll();
        this.alertService.showAlert({ message: HTTP_MESSAGES.createSuccess, type: 'SUCCESS' });
      }),
      catchError(e => this.errorHandlerService.handleError(HTTP_MESSAGES.createFail, e, ctx)),
    );
  };

  @Action(actions.Event.Delete)
  deleteEvent(ctx: StateContext<ProductsState>, action: actions.Event.Delete) {
    ctx.patchState({ eventsLoading: true });
    const product = ctx.getState().product;
    return this.productsService.deleteEvent(product.product_id, action.payload).pipe(
      tap(() => {
        ctx.setState(patch({
          events: removeItem<IProductEvent>(n => n.id === action.payload),
          product: {
            ...product,
            needs_sync: true,
          },
          eventsLoading: false,
        }));
        this.dialog.closeAll();
        this.alertService.showAlert({ message: HTTP_MESSAGES.deleteSuccess, type: 'SUCCESS' });
      }),
      catchError(e => this.errorHandlerService.handleError(HTTP_MESSAGES.deleteFail, e, ctx)),
    );
  };

  @Action(actions.Relation.GetAll)
  getRelations(ctx: StateContext<ProductsState>, action: actions.Relation.GetAll) {
    ctx.patchState({ relationsConverse: [], relationsOther: [], loading: true });
    return this.productsService.getRelations(action.payload).pipe(
      tap(resp => ctx.patchState({
        relationsConverse: resp.converse,
        relationsOther: resp.other,
        loading: false,
      })),
      catchError(e => this.errorHandlerService.handleError(HTTP_MESSAGES.generalError, e, ctx)),
    );
  };

  @Action(actions.Relation.Update)
  updateRelation(ctx: StateContext<ProductsState>, action: actions.Relation.Update) {
    ctx.patchState({ loading: true });
    const product = ctx.getState().product;
    return this.productsService.updateRelation(product.product_id, action.payload).pipe(
      tap(() => {
        ctx.patchState({
          product: {
            ...product,
            needs_sync: true,
          },
        });
        this.dialog.closeAll();
        this.alertService.showAlert({ message: HTTP_MESSAGES.updateSuccess, type: 'SUCCESS' });
        ctx.dispatch(new actions.Product.GetOne(product.product_id));
        return ctx.dispatch(new actions.Relation.GetAll(product.product_id));
      }),
      catchError(e => this.errorHandlerService.handleError(HTTP_MESSAGES.updateFail, e, ctx)),
    );
  };

  @Action(actions.Relation.Create)
  createRelation(ctx: StateContext<ProductsState>, action: actions.Relation.Create) {
    ctx.patchState({ loading: true });
    const product = ctx.getState().product;
    return this.productsService.createRelation(product.product_id, action.payload).pipe(
      tap(() => {
        ctx.patchState({
          product: {
            ...product,
            needs_sync: true,
          },
        });
        this.dialog.closeAll();
        this.alertService.showAlert({ message: HTTP_MESSAGES.createSuccess, type: 'SUCCESS' });
        ctx.dispatch(new actions.Product.GetOne(product.product_id));
        return ctx.dispatch(new actions.Relation.GetAll(product.product_id));
      }),
      catchError(e => this.errorHandlerService.handleError(HTTP_MESSAGES.createFail, e, ctx)),
    );
  };

  @Action(actions.Relation.Delete)
  deleteRelation(ctx: StateContext<ProductsState>, action: actions.Relation.Delete) {
    ctx.patchState({ loading: true });
    const product = ctx.getState().product;
    return this.productsService.deleteRelation(product.product_id, action.payload).pipe(
      tap(() => {
        ctx.patchState({
          product: {
            ...product,
            needs_sync: true,
          },
        });
        this.dialog.closeAll();
        this.alertService.showAlert({ message: HTTP_MESSAGES.deleteSuccess, type: 'SUCCESS' });
        ctx.dispatch(new actions.Product.GetOne(product.product_id));
        return ctx.dispatch(new actions.Relation.GetAll(product.product_id));
      }),
      catchError(e => this.errorHandlerService.handleError(HTTP_MESSAGES.deleteFail, e, ctx)),
    );
  };

  @Action(actions.Relation.GetRelationsTree)
  getRelationsTree(ctx: StateContext<ProductsState>) {
    ctx.patchState({ relationsTree: null });
    const productId = ctx.getState().product.product_id;
    return this.productsService.getRelationsTree(productId).pipe(
      tap(relationsTree => ctx.patchState({ relationsTree })),
      catchError(e => this.errorHandlerService.handleError(HTTP_MESSAGES.generalError, e, ctx)),
    );
  };

  @Action(actions.GeneralPurpose.Search)
  productSearch(ctx: StateContext<ProductsState>, action: actions.GeneralPurpose.Search) {
    ctx.patchState({ productSearch: null });
    const productId = ctx.getState().product.product_id;
    return this.productsService.productSearch(productId, action.payload).pipe(
      tap(productSearch => ctx.patchState({ productSearch })),
      catchError(e => this.errorHandlerService.handleError(HTTP_MESSAGES.generalError, e, ctx)),
    );
  };

  @Action(actions.Item.GetAll)
  getProductItems(ctx: StateContext<ProductsState>) {
    ctx.patchState({ items: [], loading: true });
    const productId = ctx.getState().product.product_id;
    return this.productsService.getItems(productId).pipe(
      tap(items => ctx.patchState({
        items: items.map(i => ({ ...i, subscribable: i.subscribable ? i.subscribable + ' ' : i.subscribable })),
        loading: false,
      })),
      catchError(e => this.errorHandlerService.handleError(HTTP_MESSAGES.generalError, e, ctx)),
    );
  };

  @Action(actions.Item.Update)
  updateProductItem(ctx: StateContext<ProductsState>, action: actions.Item.Update) {
    ctx.patchState({ loading: true });
    const product = ctx.getState().product;
    return this.productsService.updateItem(product.product_id, action.payload).pipe(
      tap(() => {
        ctx.patchState({
          product: {
            ...product,
            needs_sync: true,
          },
        })
        this.alertService.showAlert({ message: HTTP_MESSAGES.updateSuccess, type: 'SUCCESS' });
        return ctx.dispatch(new actions.Item.GetAll());
      }),
      catchError(e => this.errorHandlerService.handleError(HTTP_MESSAGES.updateFail, e, ctx)),
    );
  };

  @Action(actions.Item.Create)
  createProductItem(ctx: StateContext<ProductsState>, action: actions.Item.Create) {
    ctx.patchState({ loading: true });
    const product = ctx.getState().product;
    return this.productsService.createItem(product.product_id, action.payload).pipe(
      tap(item => {
        ctx.setState(patch({
          items: append([{ ...item, subscribable: item.subscribable ? item.subscribable + ' ' : item.subscribable }]),
          product: {
            ...product,
            needs_sync: true,
          },
          loading: false,
        }));
        this.dialog.closeAll();
        this.alertService.showAlert({ message: HTTP_MESSAGES.createSuccess, type: 'SUCCESS' });
      }),
      catchError(e => this.errorHandlerService.handleError(HTTP_MESSAGES.createFail, e, ctx)),
    );
  };

  @Action(actions.Item.Delete)
  deleteProductItem(ctx: StateContext<ProductsState>, action: actions.Item.Delete) {
    ctx.patchState({ loading: true });
    const product = ctx.getState().product;
    return this.productsService.deleteItem(product.product_id, action.payload).pipe(
      tap(() => {
        this.dialog.closeAll();
        this.alertService.showAlert({ message: HTTP_MESSAGES.deleteSuccess, type: 'SUCCESS' });
        ctx.setState(patch({
          items: removeItem<IProductItem>(i => i.prdd_id === action.payload),
          product: {
            ...product,
            needs_sync: true,
          },
          loading: false,
        }));
      }),
      catchError(e => this.errorHandlerService.handleError(HTTP_MESSAGES.deleteFail, e, ctx)),
    );
  };

  @Action(actions.Item.GetPriceCodes)
  getPriceCodes(ctx: StateContext<ProductsState>, action: actions.Item.GetPriceCodes) {
    const productId = ctx.getState().product.product_id;
    ctx.patchState({ priceCodes: [] });
    return this.productsService.getPriceCodes(productId, action.payload).pipe(
      tap(priceCodes => {
        ctx.patchState({ priceCodes });
        if (!priceCodes.length) this.alertService.showAlert({ message: 'No Price Codes found', type: 'ERROR' });
      }),
      catchError(e => this.errorHandlerService.handleError(HTTP_MESSAGES.generalError, e, ctx)),
    );
  };

  @Action(actions.Item.AddPdf)
  addPdf(ctx: StateContext<ProductsState>, action: actions.Item.AddPdf) {
    const product = ctx.getState().product;
    return this.productsService.addPdf({ ...action.payload, id: product.product_id }).pipe(
      tap(() => {
        ctx.patchState({
          product: {
            ...product,
            needs_sync: true,
          }
        });
        this.dialog.closeAll();
        this.alertService.showAlert({ message: HTTP_MESSAGES.createSuccess, type: 'SUCCESS' });
        return ctx.dispatch(new actions.Item.GetAll());
      }),
      catchError(e => this.errorHandlerService.handleError(HTTP_MESSAGES.generalError, e, ctx)),
    );
  };

  @Action(actions.Item.DeletePdf)
  deletePdf(ctx: StateContext<ProductsState>, action: actions.Item.DeletePdf) {
    const product = ctx.getState().product;
    const { itemId, pdfId } = action.payload;
    return this.productsService.deletePdf(product.product_id, itemId, pdfId).pipe(
      tap(id => {
        ctx.patchState({
          product: {
            ...product,
            needs_sync: true,
          }
        });
        this.dialog.closeAll();
        this.alertService.showAlert({ message: HTTP_MESSAGES.deleteSuccess, type: 'SUCCESS' });
        return ctx.patchState({
          items: ctx.getState().items.map(i => i.prdd_id !== itemId ? i : { ...i, pdfs: i.pdfs.filter(p => p.pdf_id != id) }),
        });
      }),
      catchError(e => this.errorHandlerService.handleError(HTTP_MESSAGES.deleteFail, e, ctx)),
    );
  };

  @Action(actions.Item.RepairPdf)
  repairPdf(ctx: StateContext<ProductsState>, action: actions.Item.RepairPdf) {
    const product = ctx.getState().product;
    const { itemId, pdfId } = action.payload;
    return this.productsService.repairPdf(product.product_id, itemId, pdfId).pipe(
      tap(id => {
        ctx.patchState({
          product: {
            ...product,
            needs_sync: true,
          }
        });
        this.dialog.closeAll();
        this.alertService.showAlert({ message: HTTP_MESSAGES.repairSuccess, type: 'SUCCESS' });
        return ctx.patchState({
          items: ctx.getState().items.map(i => i.prdd_id !== itemId ? i : { ...i, pdfs: i.pdfs }),
        });
      }),
      catchError(e => this.errorHandlerService.handleError(HTTP_MESSAGES.repairFail, e, ctx)),
    );
  };

  @Action(actions.Item.UpdatePdf)
  updatePdf(ctx: StateContext<ProductsState>, action: actions.Item.UpdatePdf) {
    const product = ctx.getState().product;
    return this.productsService.updatePdf({ ...action.payload, id: product.product_id }).pipe(
      tap(() => {
        ctx.patchState({
          product: {
            ...product,
            needs_sync: true,
          }
        });
        this.dialog.closeAll();
        this.alertService.showAlert({ message: HTTP_MESSAGES.updateSuccess, type: 'SUCCESS' });
        return ctx.dispatch(new actions.Item.GetAll());
      }),
      catchError(e => this.errorHandlerService.handleError(HTTP_MESSAGES.updateFail, e, ctx)),
    );
  };

  @Action(actions.Gateway.GetAll)
  getGateways(ctx: StateContext<ProductsState>) {
    ctx.patchState({ gateways: [], loading: true });
    const productId = ctx.getState().product.product_id;
    return this.productsService.getGateways(productId).pipe(
      tap(gateways => ctx.patchState({ gateways, loading: false })),
      catchError(e => this.errorHandlerService.handleError(HTTP_MESSAGES.generalError, e, ctx)),
    );
  };

  @Action(actions.Gateway.Update)
  updateGateway(ctx: StateContext<ProductsState>, action: actions.Gateway.Update) {
    ctx.patchState({ loading: true });
    const product = ctx.getState().product;
    return this.productsService.updateGateway(product.product_id, action.payload).pipe(
      tap(() => {
        ctx.patchState({
          product: {
            ...product,
            needs_sync: true,
          },
        });
        this.dialog.closeAll();
        this.alertService.showAlert({ message: HTTP_MESSAGES.updateSuccess, type: 'SUCCESS' });
        return ctx.dispatch(new actions.Gateway.GetAll());
      }),
      catchError(e => this.errorHandlerService.handleError(HTTP_MESSAGES.updateFail, e, ctx)),
    );
  };

  @Action(actions.Gateway.Create)
  createGateway(ctx: StateContext<ProductsState>, action: actions.Gateway.Create) {
    ctx.patchState({ loading: true });
    const product = ctx.getState().product;
    return this.productsService.createGateway(product.product_id, action.payload).pipe(
      tap(() => {
        ctx.patchState({
          product: {
            ...product,
            needs_sync: true,
          },
        });
        this.dialog.closeAll();
        this.alertService.showAlert({ message: HTTP_MESSAGES.createSuccess, type: 'SUCCESS' });
        return ctx.dispatch(new actions.Gateway.GetAll());
      }),
      catchError(e => this.errorHandlerService.handleError(HTTP_MESSAGES.createFail, e, ctx)),
    );
  };

  @Action(actions.Gateway.Delete)
  deleteGateway(ctx: StateContext<ProductsState>, action: actions.Gateway.Delete) {
    ctx.patchState({ loading: true });
    const product = ctx.getState().product;
    return this.productsService.deleteGateway(product.product_id, action.payload).pipe(
      tap(() => {
        ctx.patchState({
          product: {
            ...product,
            needs_sync: true,
          },
        });
        this.dialog.closeAll();
        this.alertService.showAlert({ message: HTTP_MESSAGES.deleteSuccess, type: 'SUCCESS' });
        return ctx.dispatch(new actions.Gateway.GetAll());
      }),
      catchError(e => this.errorHandlerService.handleError(HTTP_MESSAGES.deleteFail, e, ctx)),
    );
  };

  @Action(actions.GeneralPurpose.SearchByPrddId)
  productSearchByPrddId(ctx: StateContext<ProductsState>, action: actions.GeneralPurpose.SearchByPrddId) {
    ctx.patchState({ productSearchByPrddId: null });
    const productId = ctx.getState().product.product_id;
    return this.productsService.productSearchByPrddId(productId, action.payload).pipe(
      tap(productSearchByPrddId => ctx.patchState({ productSearchByPrddId })),
      catchError(e => this.errorHandlerService.handleError(HTTP_MESSAGES.generalError, e, ctx)),
    );
  };

  @Action(actions.Product.DoNotShow)
  doNotShow(ctx: StateContext<ProductsState>, action: actions.Product.DoNotShow) {
    const id = action.payload ? action.payload : ctx.getState().product.product_id;
    return this.productsService.doNotShow(id).pipe(
      tap(id => {
        if (action.payload) {
          ctx.dispatch(new actions.Product.GetLandingPageList());
        } else {
          ctx.dispatch(new actions.Product.GetOne(id));
        }
        this.alertService.showAlert({ message: HTTP_MESSAGES.generalSuccess, type: 'SUCCESS' });
      }),
      catchError(e => this.errorHandlerService.handleError(HTTP_MESSAGES.generalError, e, ctx)),
    );
  };

  @Action(actions.Product.Delete)
  deleteProduct(ctx: StateContext<ProductsState>, action: actions.Product.Delete) {
    const id = action.payload ? action.payload : ctx.getState().product.product_id;
    return this.productsService.deleteProduct(id).pipe(
      tap(id => {
        if (action.payload) {
          ctx.setState(patch({
            unpublishedProducts: removeItem<IProductListItem>(p => p.product_id == id),
            preorderedProducts: removeItem<IProductListItem>(p => p.product_id == id),
          }));
          const state = ctx.getState();
          ctx.patchState({
            ...state,
            recentProducts: {
              data: state.recentProducts.data.filter(p => p.product_id == id),
              total_count: state.recentProducts.total_count - 1,
            },
          })
        } else {
          this.navigationService.navigate(['/products']);
        }
        this.alertService.showAlert({ message: HTTP_MESSAGES.deleteSuccess, type: 'SUCCESS' });
      }),
      catchError(e => this.errorHandlerService.handleError(HTTP_MESSAGES.deleteFail, e, ctx)),
    );
  };

  @Action(actions.CSV.Import)
  importCSV(ctx: StateContext<ProductsState>, action: actions.CSV.Import) {
    return this.productsService.importCSV(action.payload).pipe(
      tap(() => this.alertService.showAlert({ message: 'Bulk Update Succeeded', type: 'SUCCESS' })),
      catchError(e => this.errorHandlerService.handleError(HTTP_MESSAGES.generalError, e, ctx)),
    );
  };

  @Action(actions.CSV.Export)
  exportCSV(ctx: StateContext<ProductsState>, action: actions.CSV.Export) {
    return this.productsService.exportCSV(action.payload).pipe(
      catchError(e => {
        if (e?.error?.constructor?.name === 'Blob') {
          e.error.text().then(text => {
            this.alertService.showAlert({
              message: JSON.parse(text || {})['message'],
              type: 'ERROR',
            });
          });
          ctx.patchState({ loading: false });
          return of(true);
        }
        return this.errorHandlerService.handleError(HTTP_MESSAGES.generalError, e, ctx);
      }),
    );
  };

  handleUpdateSuccess = (ctx: StateContext<ProductsState>, productId: string) => {
    this.dialog.closeAll();
    this.alertService.showAlert({
      message: HTTP_MESSAGES.updateSuccess,
      type: 'SUCCESS',
    });
    return ctx.dispatch(new actions.Product.GetOne(productId));
  };

  @Action(actions.GeneralPurpose.DocVaultSearch)
  docVaultSearch(ctx: StateContext<ProductsState>, action: actions.GeneralPurpose.DocVaultSearch) {
    return this.productsService.docVaultSearch(action.payload).pipe(
      tap(docVaultSearchResult => {
        ctx.patchState({ docVaultSearchResult });
      }),
      catchError(e => {
        ctx.patchState({ docVaultSearchResult: null });
        return this.errorHandlerService.handleError(HTTP_MESSAGES.generalError, e, ctx);
      }),
    );
  };

  @Action(actions.GeneralPurpose.ResetDocVaultSearch)
  resetDocVaultSearch(ctx: StateContext<ProductsState>, action: actions.GeneralPurpose.ResetDocVaultSearch) {
    return ctx.patchState({ docVaultSearchResult: null });
  };

}
