import { AbstractControl, ValidatorFn } from "@angular/forms";

export const numberValidator = (type: 'integer' | 'decimal', e: KeyboardEvent, maxLength?: number) => {
  const numbers = '01234546789';
  const decimal = numbers.concat('.');
  if ((e.target as any)?.value?.length === maxLength) {
    e.preventDefault();
  }
  switch (type) {
    case 'integer': {
      if (!numbers.includes(e.key)) {
        e.preventDefault();
        return;
      }
      return;
    }
    case 'decimal': {
      if (e.key === '.' && (e.target as any)?.value.includes('.')) {
        e.preventDefault();
        return;
      }
      if (!decimal.includes(e.key)) {
        e.preventDefault();
        return;
      }
      return;
    }
  }
}

export function ISBNValidator(): ValidatorFn {
  return (control: AbstractControl): {[key: string]: any} | null => {
    if (!control.value) {
      return null;
    }
    const valid = control.value.length === 10 || control.value.length === 13;
    return valid ? null : { invalid: {value: control.value} };
  };
}
