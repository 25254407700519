import { Mappable } from './mappable';
import { Product } from './product';
import { Pdf } from './pdf';
import { EntitledContentLog } from './entitled-content-log';

export class EntitlementContent extends Mappable {
  id: number = 0;
  available_prints: number;
  download_count: number;
  open_count: number;
  print_count: number;
  transfer_count: number;
  pdf_id: number;
  pdf: Pdf;
  locked: boolean;
  log_entries: EntitledContentLog[] = [];

  constructor() { super() }
}
