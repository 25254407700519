/* src/app/payment-transaction/payment-transaction.component.scss */
.righthead,
.print_button {
  float: right;
}
.mat-form-field {
  display: inline-block;
  position: relative;
  text-align: left;
  width: 23% !important;
}
.description {
  width: 93.5% !important;
}
.description input {
  overflow-y: auto;
  word-break: break-word;
}
/*# sourceMappingURL=payment-transaction.component.css.map */
